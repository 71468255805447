// eslint-disable-next-line import/no-cycle
import { saveGlobalTagsChanges } from '../../globalTagsManager';
import { Category } from '../../types';
import type { DocumentTag, GlobalTagChangeInfosById } from '../../types/tags';
import { cleanUpTagName } from '../../utils/cleanAndValidateTagName';
import type Database from '../Database';

let changeAccumulator: GlobalTagChangeInfosById = {};
let saveTagsInStateTimeout: ReturnType<typeof setTimeout> | null;
const saveTagsInStateDelay = 1000;

function saveGlobalTagsChangesIfNeeded(database: Database) {
  if (saveTagsInStateTimeout) {
    clearTimeout(saveTagsInStateTimeout);
    saveTagsInStateTimeout = null;
  }

  saveTagsInStateTimeout = setTimeout(async () => {
    const changeAccumulatorCopy = { ...changeAccumulator };
    changeAccumulator = {};
    const areThereNewValuesToSave = Object.values(changeAccumulatorCopy)
      .some((tag) => tag.totalCountDelta !== 0 || tag.hasNameChanged);

    if (areThereNewValuesToSave) {
      await saveGlobalTagsChanges(changeAccumulatorCopy, database);
    }
  }, saveTagsInStateDelay);
}

function upsertTagInAccumulator({
  database,
  delta,
  hasNameChanged,
  isFromHighlight,
  tags,
}: {
  database: Database;
  delta: number;
  hasNameChanged?: boolean;
  isFromHighlight: boolean;
  tags: DocumentTag[];
}) {
  if (!tags.length) {
    return;
  }

  for (const tag of tags) {
    const cleanTagName = cleanUpTagName(tag.name);
    const id = cleanTagName.toLowerCase();

    const doesTagAlreadyExist = id in changeAccumulator;
    if (!doesTagAlreadyExist) {
      changeAccumulator[id] = {
        firstClassDocumentCountDelta: 0,
        hasNameChanged: false,
        highlightCountDelta: 0,
        id,
        lastAssignedAt: tag.created,
        name: cleanTagName,
        totalCountDelta: 0,
      };
    }

    changeAccumulator[id].totalCountDelta += delta;

    if (isFromHighlight) {
      changeAccumulator[id].highlightCountDelta += delta;
    } else {
      changeAccumulator[id].firstClassDocumentCountDelta += delta;
    }

    if (tag.created) {
      changeAccumulator[id].lastAssignedAt = Math.max(changeAccumulator[id].lastAssignedAt, tag.created);
    }

    if (hasNameChanged) {
      changeAccumulator[id].hasNameChanged = true;
    }
  }

  saveGlobalTagsChangesIfNeeded(database);
}

export default async function saveGlobalTagsCountMiddleware(database: Database) {
  if (!database.rxDbInstance) {
    throw new Error('database.rxDbInstance is falsy');
  }

  database.rxDbInstance.collections.documents.postInsert((plainData) => {
    const tags = Object.values(plainData.tags ?? {});
    const isFromHighlight = plainData.category === Category.Highlight;
    upsertTagInAccumulator({ database, tags, isFromHighlight, delta: 1 });
  }, true);

  database.rxDbInstance.collections.documents.preSave((plainData, rxDocument) => {
    const isFromHighlight = plainData.category === Category.Highlight;

    const prevTags = rxDocument?.tags ?? {};
    const prevTagIds = Object.keys(prevTags);
    const prevTagEntries = Object.entries(prevTags);
    const nextTags = plainData.tags ?? {};
    const nextTagIds = Object.keys(nextTags);

    const newTags = Object.entries(nextTags)
      .filter(([id]) => !prevTagIds.includes(id))
      .map(([, tag]) => tag);
    const deletedTags: DocumentTag[] = [];
    const tagsWithNameUpdated: DocumentTag[] = [];

    for (const [id, tag] of prevTagEntries) {
      if (nextTagIds.includes(id)) { // Not deleted?
        if (tag.name !== nextTags[id].name) {
          tagsWithNameUpdated.push(nextTags[id]);
        }
      } else {
        deletedTags.push(tag);
      }
    }

    upsertTagInAccumulator({ database, tags: newTags, isFromHighlight, delta: 1 });
    upsertTagInAccumulator({ database, tags: deletedTags, isFromHighlight, delta: -1 });
    upsertTagInAccumulator({ database, tags: tagsWithNameUpdated, isFromHighlight, delta: 0, hasNameChanged: true });
  }, true);

  database.rxDbInstance.collections.documents.postRemove((plainData) => {
    const isFromHighlight = plainData.category === Category.Highlight;
    const tags = Object.values(plainData.tags ?? {});
    upsertTagInAccumulator({ database, tags, isFromHighlight, delta: -1 });
  }, true);
}
