export const InitFullTextSearchTableQuery = `
  -- Create document full text search table
  CREATE VIRTUAL TABLE IF NOT EXISTS search USING fts5(id unindexed, url unindexed, body, tokenize='porter', prefix='1 2');
`;
export const InitDocsTableQuery = `
  -- Create document metadata table
  CREATE TABLE IF NOT EXISTS documents (id PRIMARY KEY, url TEXT, title TEXT, author TEXT);
`;
export const CreateAuthorIndexQuery = `
  CREATE INDEX IF NOT EXISTS idx_documents_authors 
  ON documents (author);
`;
export const CreateTitleIndexQuery = `
  CREATE INDEX IF NOT EXISTS idx_documents_titles 
  ON documents (title);
`;
export const InitDocumentMetadataTableQueries = [
  InitDocsTableQuery,
  CreateAuthorIndexQuery,
  CreateTitleIndexQuery,
];
