import * as jsonpatch from 'readwise-fast-json-patch';

import removeDeletedDocsFromJsonPatch from './foreground/removeDeletedDocsFromJsonPatch';
import type { PersistentState, PersistentStateWithDocuments, PersistentUpdatePatch } from './types';

// TODO: do we even need this function any more since we updated json patch?
//  https://github.com/readwiseio/JSON-Patch/pull/1
//  If we do still need this, should we just fold this change into the jsonpatch library too?
export default (persistentState: PersistentState | PersistentStateWithDocuments, patch: PersistentUpdatePatch): void => {
  try {
    jsonpatch.applyPatch(persistentState, patch);
  } catch (e) {
    // Sometimes we will have a stale patch from a document that no longer exists in the foreground.
    // In this case, the background will soon catch up -- just skip the stale patch.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e && e.name === 'TypeError' && e.message && e.message.includes('undefined')) {
      const newPatch = removeDeletedDocsFromJsonPatch(patch, (persistentState as PersistentStateWithDocuments).documents ?? null);
      // After removing the bad patches, try again:
      jsonpatch.applyPatch(persistentState, newPatch);
    } else {
      throw e;
    }
  }
};
