import type { DatabaseCollectionCommonFindAndUpdateFunctionOptions } from '../../types/database';
import { LogLevel } from '../../types/logging';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import logger from './logger';

export default function handleWhenFindAndUpdateFunctionFoundNothing({
  errorMessageIfNothingFound,
  extraLogInfo,
  methodName,
  logLevelIfNothingFound = LogLevel.Warn,
}: {
  extraLogInfo?: {
    [name: string]: unknown;
  };
  methodName: string;
} & DatabaseCollectionCommonFindAndUpdateFunctionOptions) {
  if (logLevelIfNothingFound !== 'off') {
    logger[logLevelIfNothingFound](errorMessageIfNothingFound || `${methodName} found nothing`, {
      ...extraLogInfo ?? {},
      databaseMethodName: methodName,
    });
  }
  if (errorMessageIfNothingFound) {
    const error = new Error(errorMessageIfNothingFound);
    exceptionHandler.captureException(error, { extra: extraLogInfo });
    throw error;
  }
}
