import get from 'lodash/get';
import set from 'lodash/set';
import type { RxCollection } from 'rxdb';

import type { DatabaseUpsertTransformer } from '../../../../types/database';
import logger from '../../logger';
import lookUpSchemaSubObjectPaths from '../../lookUpSchemaSubObjectPaths';

export default function getBooleanTransformer(collection: RxCollection): DatabaseUpsertTransformer {
  const results = lookUpSchemaSubObjectPaths({
    data: collection.schema.jsonSchema.properties,
    matcher: (data) => data['x-auto-convert-value-to-and-from-boolean'],
    memoizeKey: `${collection.name}-x-auto-convert-value-to-and-from-boolean`,
  });

  logger.debug('[upsertTransformers] getBooleanTransformer', { collectionName: collection.name, results });

  return function booleanTransformer(plainData: object) {
    for (const { path } of results) {
      const currentValue = get(plainData, path);
      if (typeof currentValue !== 'boolean') {
        continue;
      }
      set(plainData, path, currentValue ? 1 : 0);
    }
  };
}
