import { DocListSwipeActionEnum, DocumentLocation, SettingsState, SwipeConfig, SwipeDocList } from '../../../shared/types';

export default function getDefaultSwipeConfig(
  documentLocations: SettingsState['documentLocations'],
): SwipeConfig {
  const libraryArchiveConfig: SwipeConfig[SwipeDocList.LibraryArchive] = {
    leftLong: DocListSwipeActionEnum.MoveToArchive,
    left: DocListSwipeActionEnum.ToggleSeen,
    right: DocListSwipeActionEnum.AllActions,
    rightLong: DocListSwipeActionEnum.MoveToLater,
  };
  const libraryInboxConfig: SwipeConfig[SwipeDocList.LibraryInbox] = { ...libraryArchiveConfig };
  const libraryLaterConfig: SwipeConfig[SwipeDocList.LibraryLater] = { ...libraryArchiveConfig };
  const libraryShortlistConfig: SwipeConfig[SwipeDocList.LibraryShortlist] = { ...libraryArchiveConfig };

  if (documentLocations.includes(DocumentLocation.New)) {
    libraryArchiveConfig.leftLong = DocListSwipeActionEnum.MoveToInbox;
    libraryLaterConfig.rightLong = DocListSwipeActionEnum.MoveToInbox;
  } else if (documentLocations.includes(DocumentLocation.Shortlist)) {
    libraryArchiveConfig.leftLong = DocListSwipeActionEnum.MoveToShortlist;
    libraryLaterConfig.rightLong = DocListSwipeActionEnum.MoveToShortlist;
  }

  return {
    feedNew: {
      leftLong: DocListSwipeActionEnum.MoveToArchive,
      left: DocListSwipeActionEnum.ToggleSeen,
      right: DocListSwipeActionEnum.AllActions,
      rightLong: DocListSwipeActionEnum.MoveToLater,
    },
    feedSeen: {
      leftLong: DocListSwipeActionEnum.MoveToArchive,
      left: DocListSwipeActionEnum.ToggleSeen,
      right: DocListSwipeActionEnum.AllActions,
      rightLong: DocListSwipeActionEnum.MoveToLater,
    },
    libraryArchive: libraryArchiveConfig,
    libraryInbox: libraryInboxConfig,
    libraryLater: libraryLaterConfig,
    libraryShortlist: libraryShortlistConfig,
  };
}
