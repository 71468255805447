import '../networkDetector.platform';

import type { UserEvent, UserEventWithDataUpdate } from '../types';
import { HandleStateUpdateSideEffectsParameter, HandleStateUpdateSideEffectsResult } from '../types/stateUpdates';
// eslint-disable-next-line import/no-cycle
import { addUserEvent, recentUserEventsWithDataUpdates } from './models';
// eslint-disable-next-line import/no-cycle
import background, { portalGate as portalGateToBackground } from './portalGates/toBackground/singleProcess';
import { createUserEvent } from './userEvents';


export default async function handleStateUpdateSideEffects({
  addUserEventToZustandState = addUserEvent,
  queueStateUpdateFromForeground = background.queueStateUpdateFromForeground,
  ...options
}: HandleStateUpdateSideEffectsParameter): HandleStateUpdateSideEffectsResult {
  const jsonPatchOperations = options.jsonPatchOperations ?? { forward: [], reverse: [] };
  const didChangePersistentState = jsonPatchOperations.forward.length > 0;
  const didChangeAnyState = didChangePersistentState || options.didChangeAnyState; // E.g. transient state

  const shouldCreateUserEvent = options.shouldCreateUserEvent !== false &&
    (Boolean(options.userInteraction) || didChangeAnyState);

  let userEvent: UserEvent | undefined;
  const eventName = options.eventName;

  // Return early (do absolutely nothing) if there are no changes
  if (!shouldCreateUserEvent && !didChangeAnyState) {
    return { userEvent };
  }
  if (shouldCreateUserEvent) {
    let dataUpdates: NonNullable<Parameters<typeof createUserEvent>[0]>['dataUpdates'];
    if (
      didChangePersistentState &&
      !options.shouldNotSendPersistentChangesToServer
    ) {
      dataUpdates = {
        forwardPatch: jsonPatchOperations.forward,
        reversePatch: jsonPatchOperations.reverse,
      };
    }

    userEvent = createUserEvent({
      correlationId: options.correlationId,
      dataUpdates,
      eventName,
      userInteraction: options.userInteraction,
    });

    if (options.onSentToServer) {
      const onSentToServer = options.onSentToServer;
      const newUserEventId = userEvent.id;
      const onBackgroundToServerUpdatesConsumed = (userEventIds: UserEvent['id'][]) => {
        if (!userEventIds.includes(newUserEventId)) {
          return;
        }
        portalGateToBackground.off('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
        onSentToServer();
      };
      portalGateToBackground.on('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
    }

    addUserEventToZustandState(userEvent as UserEvent, { correlationId: options.correlationId });
  }

  // This exists if we want to send the changes to the server or if the change is undoable; this is where we do all that
  if (userEvent?.dataUpdates) {
    const eventWithDataUpdate = userEvent as UserEventWithDataUpdate;
    // Defaults to true
    if (options.isUndoable !== false) {
      recentUserEventsWithDataUpdates.push(eventWithDataUpdate);
    }
    await queueStateUpdateFromForeground(eventWithDataUpdate);
  }

  return { userEvent };
}
