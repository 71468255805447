import type { MangoQuerySelector } from 'rxdb';

import { Category } from '../types';
import { DatabaseCollectionNamesToDocType } from '../types/database';
import mangoQueryBuilders from './mangoQueryBuilders';

export const isFirstClassDocumentQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'rxdbOnly.indexFields.triage_status_exists': 1,
};

export const isDocumentThatCanBeSharedQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = mangoQueryBuilders.$or<'documents'>([{
  category: Category.Article,
}, {
  category: Category.Email,
}, {
  category: Category.PDF,
}, {
  category: Category.RSS,
}, {
  category: Category.Tweet,
}, {
  category: Category.Video,
}]);

export const documentHasHighlightsQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'rxdbOnly.indexFields.hasHighlights': 1,
};

export const documentDoesntHaveHighlightsQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'rxdbOnly.indexFields.hasHighlights': 0,
};

export const documentHasTagsQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = {
  tags: { $exists: true, $ne: {} },
};

export const documentDoesntHaveTagsQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = mangoQueryBuilders.$or<'documents'>([
  {
    tags: { $exists: false },
  },
  {
    tags: { $eq: {} },
  },
]);

export const documentHasNotesQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = {
  notes: { $exists: true, $ne: '' },
};

export const documentDoesntHaveNotesQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = mangoQueryBuilders.$or<'documents'>([
  {
    notes: { $exists: false },
  },
  {
    notes: { $eq: '' },
  },
]);

export const documentHasAnnotationsQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = mangoQueryBuilders.$or([
  documentHasHighlightsQuery,
  documentHasTagsQuery,
  documentHasNotesQuery,
]);

export const documentDoesntHaveAnnotationsQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> = mangoQueryBuilders.$and([
  documentDoesntHaveHighlightsQuery,
  documentDoesntHaveTagsQuery,
  documentDoesntHaveNotesQuery,
]);
