import { EventEmitter2 } from 'eventemitter2';
import chunk from 'lodash/chunk';
import debounce from 'lodash/debounce';

import nowTimestamp from '../utils/dates/nowTimestamp';
import { DeferredPromise } from '../utils/DeferredPromise';
import delay from '../utils/delay';
import { isDesktopApp } from '../utils/environment';
import makeLogger from '../utils/makeLogger';
import database from './database';
import background from './portalGates/toBackground/singleProcess';
import { Search } from './Search';
import { InitFullTextSearchTableQuery } from './search.constants';
// Local import of the file, because we needed to compile it ourselves to get the FTS5 extension
// See: https://github.com/sql-js/sql.js#compiling
import { type ISqliteDatabase, type SqliteValue, newSqliteDatabase } from './sqliteDatabase';

export const logger = makeLogger(__filename);
export const eventEmitter = new EventEmitter2();

let searchDb: ISqliteDatabase;

const searchDbInitializedPromise = new DeferredPromise<void>();

// If we ever need to update the schema of the content search db, all we need to do is update this timestamp to
// "now" and all dbs created before "now" will be refreshed...
const REFRESH_SEARCH_SCHEMAS_FROM_BEFORE = 1700255544000; // Fri, 17 Nov 2023 21:12:24 GMT

// We have to use 'contentDbDump' on web browser for data continuity.
const SEARCH_DB_NAME = isDesktopApp ? 'search' : 'contentDbDump';


const saveDBToDisk = debounce(async () => {
  logger.time('contentDb.export()');
  await searchDb.save();
  logger.timeEnd('contentDb.export()');
}, 250);

export const upsertContent: Search['upsertContent'] = async (documentContents) => {
  const callMarker = Math.random().toString().slice(2);
  logger.time(`upsertContent call#${callMarker}`);
  await initDB();

  logger.time(`selecting existing URLs ${callMarker}`);
  const urls = documentContents.map(({ url }) => url);
  const placeholders = urls.map(() => '?');
  const urlsInDb = await searchDb.select<{ url: string; }>(`SELECT url FROM search WHERE url IN (${placeholders.join(', ')});`, urls);
  logger.timeEnd(`selecting existing URLs ${callMarker}`);
  const urlsInDbSet = new Set(urlsInDb.map(({ url }) => url));

  let didInsertAny = false;
  const filtered = documentContents.filter(({ url }) => !urlsInDbSet.has(url));
  if (filtered.length === 0) {
    return;
  }

  for (const documentContentGroup of chunk(filtered, 50)) {
    const placeholders = documentContentGroup.map(() => '(?, ?, ?)').join(', ');
    const query = `INSERT INTO search(id, url, body) VALUES ${placeholders};`;
    const params: SqliteValue[] = [];
    for (const { html, id, url } of documentContentGroup) {
      params.push(id);
      params.push(url);
      params.push(html ? new DOMParser().parseFromString(html, 'text/html').documentElement.textContent : '');
    }

    await delay(10);
    await searchDb.execute(query, params);
    didInsertAny = true;
    eventEmitter.emit('content-updated');
  }

  if (!didInsertAny) {
    logger.timeEnd(`upsertContent call#${callMarker}`);
    return;
  }

  await saveDBToDisk();
  logger.timeEnd(`upsertContent call#${callMarker}`);
};

export const upsertDocuments: Search['upsertDocuments'] = async (_documents) => {
  // no-op on web since it uses RxDB to search documents by metadata.
};

async function printCounts() {
  const [{ count: metadataCount }] = await searchDb.select<{count: number;}>('SELECT COUNT(*) as count FROM documents');
  const [{ count: contentCount }] = await searchDb.select<{count: number;}>('SELECT COUNT(*) as count FROM search');
  logger.debug('Items in search', { metadataCount, contentCount });
}

export const initDB = async (): Promise<void> => {
  if (searchDb) {
    // Database is already in the process of being initialized.
    await searchDbInitializedPromise;
    return;
  }
  searchDb = newSqliteDatabase();

  logger.time('initDB');
  const lastSearchSchemaUpdateAt: number | null = await background.getCacheItem('lastSearchSchemaUpdateAt');

  if (lastSearchSchemaUpdateAt && lastSearchSchemaUpdateAt > REFRESH_SEARCH_SCHEMAS_FROM_BEFORE) {
    logger.time('load from contentDbDump');
    await searchDb.init(SEARCH_DB_NAME, false);
    logger.timeEnd('load from contentDbDump');
  } else {
    logger.debug('Creating a fresh sqlite table for content search...');
    await searchDb.init(SEARCH_DB_NAME, true);
    await background.setCacheItem('lastSearchSchemaUpdateAt', nowTimestamp());
  }
  await searchDb.execute(InitFullTextSearchTableQuery);
  await saveDBToDisk();
  if (logger.shouldLog) {
    printCounts();
  }

  logger.timeEnd('initDB');
  searchDbInitializedPromise.resolve();
};


export async function searchDocuments(
  query: string,
  callback: (docs: DocumentSearchResult[]) => void,
): Promise<void> {
  if (query.length < 2) {
    callback([]);
    return;
  }
  logger.time('searchDocuments');
  const queryRegexMatcher = {
    $regex: `.*${query}.*`,
    $options: 'i', // case-insensitive
  };
  const foundDocuments = await database.collections.documents.find({
    selector: {
      $or: [
        { author: queryRegexMatcher },
        { title: queryRegexMatcher },
      ],
    },
    limit: 10,
  });
  const searchResults: DocumentSearchResult[] = foundDocuments
    .map(({ id, url, author, title }) => ({
      id,
      url: url ?? null,
      author: author ?? null,
      title: title ?? null,
    }));
  logger.timeEnd('searchDocuments');
  callback(searchResults);
}

const formatSearchQueryForSql = (query: string) =>
  // make apostrophes literal apostrophes,
  // remove exact quote matches for now since they're annoying to deal with
  query.replace(/'/g, `\\'`).replace(/"/g, ``);

export type ContentSearchResult = {
  id: string; // parsed doc ID
  url: string;
  text: string;
};

export type DocumentSearchResult = {
  id: string;
  url: string | null;
  author: string | null;
  title: string | null;
};

export const searchContent = async (
  query: string,
  callback: (docs: ContentSearchResult[]) => void,
): Promise<void> => {
  logger.time(`searchContent ${query}`);
  if (query.length < 2) {
    callback([]);
    return;
  }
  await initDB();

  const finalResults = await searchDb.select<ContentSearchResult>(`
    select id, url, snippet(search, -1, '<mark>', '</mark>', '...', 24) as text
    from search
    where search match ?
    order by rank
    limit 10`,
  [`"${formatSearchQueryForSql(query)}"*`]);

  if (finalResults.length < 10) {
    const wildCardResults = await searchDb.select<ContentSearchResult>(`
      select id, url, snippet(search, -1, '<mark>', '</mark>', '...', 24) as text
      from search
      where search match ?
      order by rank limit 10`,
      [`"${formatSearchQueryForSql(query)}"*`]);
    // Add any results from the wildcard search that we don't already have:
    const newResults = wildCardResults.filter(({ id: wildcardId }) => !finalResults.find(({ id }) => id === wildcardId));
    finalResults.push(...newResults);
  }

  logger.timeEnd(`searchContent ${query}`);

  callback(finalResults);
};

export const getDocumentContentsIndexedCount: Search['getDocumentContentsIndexedCount'] = async () => {
  const [{ count }] = await searchDb.select<{ count: number; }>('SELECT COUNT(*) AS count FROM search');
  return count;
};
