import { DESKTOP_SESSIONID } from '../../../shared/commonConstants';
// eslint-disable-next-line import/no-cycle
import background from '../../../shared/foreground/portalGates/toBackground/singleProcess';

export async function handleDesktopAuthCallback() {
  if (location.pathname !== '/--/doneauth') {
    return;
  }
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('sessionid');
  if (!sessionId) {
    throw new Error('done auth but no sessionid');
  }
  await background.setCacheItem(DESKTOP_SESSIONID, sessionId);
  if (location.pathname === '/--/doneauth') {
    location.pathname = '/';
  }
}
