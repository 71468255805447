/* eslint-disable @shopify/typescript/prefer-pascal-case-enums */

export enum LogicalOperator {
  OR = 'OR',
  AND = 'AND',
  NOT = 'NOT'
}

export enum TypeOperator {
  GreaterThan = '__gt:',
  LessThan = '__lt:',
  GreaterThanOrEqual = '__gte:',
  LessThanOrEqual = '__lte:',
  After = '__after:',
  Before = '__before:',
  Contains = '__contains:',
  Exact = '__exact:',
  Fuzzy = ':',
  Not = '__not:',
}

export enum NodeType {
  Logical = 'logical',
  Node = 'node',
}

export enum TokenType {
  Key = 'key',
  Value = 'value',
  OpenParen = 'openParen',
  CloseParen = 'closeParen',
  TypeOperator = 'typeOperator',
  LogicOperator = 'logicOperator',
}

export type TokenTypeOperator = { type: TokenType.TypeOperator; value: TypeOperator; };
export type TokenValue = { type: TokenType.Value; value: string; };
export type TokenKey = { type: TokenType.Key; value: string; };
export type TokenOpenParen = { type: TokenType.OpenParen; value: '('; };
export type TokenCloseParen = { type: TokenType.CloseParen; value: ')'; };
export type TokenLogicalOperator = { type: TokenType.LogicOperator; value: LogicalOperator; };

export type Token =
  | TokenOpenParen
  | TokenCloseParen
  | TokenKey
  | TokenTypeOperator
  | TokenValue
  | TokenLogicalOperator;

export type ExpressionNode = {
  type: NodeType.Node;
  key: string;
  operator: TypeOperator;
  value: string;
};

export type ExpressionLogical = {
  type: NodeType.Logical;
  operator: LogicalOperator;
  left: Expression;
  right: Expression;
};

export type Expression = ExpressionNode | ExpressionLogical;

export type AST = Expression;

export type PartialExpressionLogical = {
  type: NodeType.Logical;
  operator: LogicalOperator;
  left?: PartialExpression;
  right?: PartialExpression;
};

export type PartialExpression =
  | Expression
  | PartialExpressionLogical
  | undefined;
