import { useCallback, useMemo } from 'react';

import { DocumentLocation } from '../../types';
import fixBadDocumentLocationsValue from './fixBadDocumentLocationsValue';
// eslint-disable-next-line import/no-cycle
import useGlobalStateWithFallback from './useGlobalStateWithFallback';

export default function useDocumentLocations ({
  shouldIncludeFeed,
}: {
  shouldIncludeFeed?: boolean;
} = {}) {
  const resultsFromState = useGlobalStateWithFallback([], useCallback(
    // TODO: something is broken here, settings should never be nullish.
    (state) => state.persistent.settings?.documentLocations ?? [],
    [],
  ));
  const fixedResults = useMemo(() => fixBadDocumentLocationsValue(resultsFromState), [resultsFromState]);
  return useMemo(() => {
    if (shouldIncludeFeed) {
      return fixedResults;
    }
    return fixedResults.filter((documentLocation) => documentLocation !== DocumentLocation.Feed);
  }, [fixedResults, shouldIncludeFeed]);
}
