import keyBy from 'lodash/keyBy';

import type Database from '../../../database/Database';
import {
  type AnyDocument,
  type DocumentId,
  type IdToDocumentMap,
  type LightweightStateUpdateOperation,
  type MinimalPersistentState,
  type PersistentState,
  type PersistentStateWithDocuments,
  type PersistentUpdate,
  PersistentStateLoadingState,
} from '../../../types';
import attachRxDBDocumentsToPersistentState from '../../../utils/attachRxDBDocumentsToPersistentState';
import makeLogger from '../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import database from '../../database';
import { CancelStateUpdate, globalState, updatePropertyInState, updateState } from '../../models';
// eslint-disable-next-line import/no-cycle
import listenToDocumentLocationsAndFixClientState
  from '../../utils/listenToDocumentLocationsAndFixClientState.platform';
import updateAllStateUsingJsonPatchOperations from '../../utils/updateAllStateUsingJsonPatchOperations';

const logger = makeLogger(__filename);

export const setCurrentPersistentStateLoadingState = async (persistentLoadingState: PersistentStateLoadingState) => {
  await updatePropertyInState('persistentStateLoadingState', persistentLoadingState, {
    eventName: 'persistent-state-loading-state-updated',
    isUndoable: false,
    userInteraction: null,
  });
};

export const setPersistentStateTotalDocumentsToAddCount = (documentCount: string) => {
  updatePropertyInState('persistentStateTotalDocumentsToAddCount', documentCount, {
    eventName: 'persistent-state-loading-state-total-document-count-updated',
    isUndoable: false,
    userInteraction: null,
  });
};

export const incrementPersistentStateLoadedDocumentCountByN = (n: number) => {
  updateState((state) => {
    state.persistentStateNumberOfDocumentsAdded += n;
  }, {
    eventName: 'persistent-state-loading-state-total-document-count-updated',
    isUndoable: false,
    userInteraction: null,
  });
};

export const setAreServerUpdatesBeingAppliedToForeground = (changesAreBeingApplied: boolean) => {
  updateState((state) => {
    if (state.areServerUpdatesBeingAppliedToForeground === changesAreBeingApplied) {
      throw new CancelStateUpdate();
    }
    state.areServerUpdatesBeingAppliedToForeground = changesAreBeingApplied;
  }, {
    eventName: 'are-server-updates-being-applied-to-foreground-updated',
    isUndoable: false,
    userInteraction: null,
  });
};

export const setAreServerUpdatesBeingFetchedByUser = (areUpdatesBeingFetched: boolean) => {
  updateState((state) => {
    if (state.areServerUpdatesBeingFetchedByUser === areUpdatesBeingFetched) {
      throw new CancelStateUpdate();
    }
    state.areServerUpdatesBeingFetchedByUser = areUpdatesBeingFetched;
  }, {
    eventName: 'are-server-updates-being-fetched-updated',
    isUndoable: false,
    userInteraction: null,
  });
};

export const initPersistentState = async (persistentState: PersistentState | MinimalPersistentState): Promise<void> => {

  listenToDocumentLocationsAndFixClientState();

  await updateState((state) => {
    state.persistent = persistentState;
    state.persistentStateLoaded = true;
    state.persistentStateLoadingState = PersistentStateLoadingState.Done;
  }, {
    eventName: 'persistent-state-initialized',
    shouldNotSendPersistentChangesToServer: true,
    isUndoable: false,
    userInteraction: null,
  });
};

const doWeCareAboutThisPotentialStateUpdate = ({
  allLightweightOperations,
  docsAlreadyInState,
  documentIdsDirectlyEditedInThisUpdate,
  lightweightOperation,
}: {
  allLightweightOperations: LightweightStateUpdateOperation[];
  docsAlreadyInState: IdToDocumentMap | null;
  documentIdsDirectlyEditedInThisUpdate: AnyDocument['id'][];
  lightweightOperation: LightweightStateUpdateOperation;
}): boolean => {
  const { parent, path } = lightweightOperation;
  // Ignore if it's an update to any of these keys / their descendants
  if ([
    '/currentlyReadingId',
    '/rssFeeds',
    '/filteredViews',
    '/settings/ttsVoicePreference',
  ].some((prefix) => path.startsWith(prefix))) {
    return false;
  }

  // If it's a document, ignore unless it's a document we have / a child of one...

  if (!path.startsWith('/documents/') || !docsAlreadyInState) {
    return true;
  }

  const pathSegments = path.split('/').filter(Boolean);
  // Do we have the document that it's touching in state?
  if (pathSegments[1] in docsAlreadyInState) {
    return true;
  }

  // Is it a child document being created/removed/replaced directly?
  if (pathSegments.length === 2 && parent !== undefined) {
    // Is it a child of a document we have in state?
    if (parent in docsAlreadyInState) {
      return true;
    }

    // Is parent being created/removed/replaced in this update? And is this parent update allowed to happen?
    if (documentIdsDirectlyEditedInThisUpdate.includes(parent)) {
      const parentOperations = allLightweightOperations.filter((otherLightweightOperation) =>
        otherLightweightOperation.path === `/documents/${parent}`);
      if (parentOperations.some((parentOperation) =>
        doWeCareAboutThisPotentialStateUpdate({
          allLightweightOperations,
          documentIdsDirectlyEditedInThisUpdate,
          docsAlreadyInState,
          lightweightOperation: parentOperation,
        }))) {
        return true;
      }
    }
  }

  return false;
};

export const onBackgroundStateUpdates = async (updates: PersistentUpdate[]): Promise<void> => {
  logger.debug('Background to foreground state updates: ', { updates });
  const operations = [];
  for (const update of updates) {
    for (const op of update.patch) {
      operations.push(op);
    }
  }
  await updateAllStateUsingJsonPatchOperations({
    database,
    canModifyOperations: true,
    operations,
    eventName: 'background-updates',
    shouldNotSendPersistentChangesToServer: true,
    userInteraction: null,
    isUndoable: false,
    updateState,
  });
};

export const filterPotentialStateUpdates = async (
  potentialStateUpdates: LightweightStateUpdateOperation[][],
): Promise<boolean[][]> => {
  const state = globalState.getState();
  // TODO: is this sufficiently efficient?
  const docsAlreadyInState = keyBy(await database.collections.documents.findAll(), 'id');

  return potentialStateUpdates.map((lightweightOperations) => {
    if (!state.isStateMinimized) {
      return lightweightOperations.map(() => true);
    }

    const documentIdsDirectlyEditedInThisUpdate: AnyDocument['id'][] = [];
    for (const { path } of lightweightOperations) {
      const segments = path.split('/').filter(Boolean);
      if (segments[0] === 'documents' && segments.length === 2) {
        documentIdsDirectlyEditedInThisUpdate.push(segments[1]);
      }
    }

    return lightweightOperations.map((lightweightOperation) => {
      return doWeCareAboutThisPotentialStateUpdate({
        allLightweightOperations: lightweightOperations,
        docsAlreadyInState,
        documentIdsDirectlyEditedInThisUpdate,
        lightweightOperation,
      });
    });
  });
};

// Should only used by the background for the sake of syncing -- not by the foreground for any app logic.
export const getCurrentPersistentStateWithDocuments = async (database: Database, filterDocumentIds?: DocumentId[]): Promise<PersistentStateWithDocuments> => {
  const persistentState: PersistentState = globalState.getState().persistent;

  return attachRxDBDocumentsToPersistentState(database, persistentState, filterDocumentIds);
};

export const runForegroundStateChecksum = (expectedChecksum: number) => {
  return true;
  // TODO: uncomment when we re-enable checksums
  // const documents = keyBy(
  //   await database.collections.documents.findAll(),
  //   'id',
  // );
  // return runUserDocumentsChecksum(documents, expectedChecksum);
};
