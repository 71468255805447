import type { Event } from '@sentry/browser';

import { appCategory, appVersion, channel, commitId, isDeployPreview, isExtension } from './utils/environment';
import { ReadwiseFetchNetworkConnectivityError, ReadwiseFetchServerError } from './utils/Errors';
import isSentryEventFromExtension from './utils/isSentryEventFromExtension';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default ({ currentExtensionId }: { currentExtensionId?: string; } = {}): { [key: string]: any; } => {
  // NOTE: this sets the Sentry environment to 'preview' only for read--faster.readwise.io.
  // other read--*.readwise.io domains do not have Sentry releases created for them.
  const environment = isDeployPreview ? 'preview' : channel;
  return {
    beforeSend(event: Event) {
      if (isExtension !== isSentryEventFromExtension(event, currentExtensionId)) {
        return null;
      }

      const firstValue = event.exception?.values?.[0]?.value;

      if (isExtension) {
        // Ignore the Firefox mobile app
        if (navigator.userAgent.toLowerCase().includes('android')) {
          return null;
        }

        if (typeof firstValue === 'string' && (
          firstValue.includes('No tab with id:') ||
          firstValue.includes('The browser is shutting down')
        )) {
          return null;
        }
      }

      const type = event.exception?.values?.[0]?.type;
      if (type && [ReadwiseFetchNetworkConnectivityError.name, ReadwiseFetchServerError.name].includes(type)) {
        return null;
      }

      return event;
    },
    environment,
    release: `${appCategory}@${appVersion === 'unknown' ? commitId : appVersion}`,
    // Percentage of transactions captured for performance monitoring
    tracesSampleRate: 0,
  };
};
