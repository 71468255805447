import { BaseDirectory, removeFile } from '@tauri-apps/plugin-fs';
import TauriSQL from '@tauri-apps/plugin-sql';

import type { GenericSqliteRow, ISqliteDatabase, SqliteValue } from './sqliteDatabase';


export class DesktopSqliteDatabase implements ISqliteDatabase {
  _db: TauriSQL | undefined;

  async init(filename: string, forceNewDb: boolean): Promise<void> {
    if (forceNewDb) {
      try {
        await removeFile(`${filename}.sqlite`, {
          dir: BaseDirectory.AppData,
        });
      } catch (e) {
        // do nothing, because the file didn't exist
      }
    }
    this._db = await TauriSQL.load(`sqlite:${filename}.sqlite`);
  }

  get db(): TauriSQL {
    if (!this._db) {
      throw new Error('DB not initialized');
    }
    return this._db;
  }

  async execute(query: string, params?: SqliteValue[]): Promise<void> {
    await this.db.execute(query, params);
  }

  select<Row extends GenericSqliteRow = GenericSqliteRow>(query: string, params?: SqliteValue[]): Promise<Row[]> {
    return this.db.select<Row[]>(query, params);
  }

  async save(): Promise<void> {
    // no-op, on desktop the sqlite DB is automatically persisted
  }
}
