import type { RxStorageInstanceCreationParams } from 'rxdb';
import { ensureRxStorageInstanceParamsAreCorrect, RxStorageDefaultStatics } from 'rxdb';

import type { RxStorageMemory, RxStorageMemoryInstanceCreationOptions, RxStorageMemorySettings } from './memory-types';
import { createMemoryStorageInstance, RxStorageInstanceMemory } from './rx-storage-instance-memory';

/**
 * Keep the state even when the storage instance is closed.
 * This makes it easier to use the memory storage
 * to test filesystem-like and multiInstance behaviors.
 */
const COLLECTION_STATES = new Map();

export function getRxStorageMemory(
  settings: RxStorageMemorySettings = {},
): RxStorageMemory {

  return {
    name: 'memory',
    statics: RxStorageDefaultStatics,
    collectionStates: COLLECTION_STATES,
    createStorageInstance<RxDocType>(
      params: RxStorageInstanceCreationParams<RxDocType, RxStorageMemoryInstanceCreationOptions>,
    ): Promise<RxStorageInstanceMemory<RxDocType>> {
      ensureRxStorageInstanceParamsAreCorrect(params);
      const useSettings = {
        ...settings,
        ...params.options,
      };

      return createMemoryStorageInstance(this, params, useSettings);
    },
  };
}

export * from './rx-storage-instance-memory';
