// This package polyfills window.locks if it doesn't exist in the browser
import 'navigator.locks';

import type {
  LockManager,
} from 'navigator.locks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lockManager = window?.navigator ? (window.navigator as any).locks as LockManager : null;

export default lockManager;
