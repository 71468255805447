import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export default function asArray<T>(input: T | T[] | { [key: string]: T; }): T[] {
  if (isArray(input)) {
    return input;
  }
  if (isObject(input)) {
    return Object.values(input);
  }
  return [input];
}
