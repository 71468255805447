import type { Paths } from 'rxdb/dist/types/types';

import type { AnyDocument } from '../../types';

export type DocumentIndexes = ReadonlyArray<ReadonlyArray<Paths<AnyDocument>>>;


/* NOTE: Order of index fields is CRUCIAL for index selection.
 * If you order fields suboptimally, then your index might not be used in an expensive query, and it might default to full table scan.
 * For SQLite details, see https://www.sqlite.org/optoverview.html. (But the same is even more true for web).
 *
 * For web storages, we override indexes for IndexedDB to reduce the write load. Instead of using all indices,
 * we pick only certain indexes for IndexedDB (defined in DocumentIndexesForWebIndexedDBAndInMemoryStorage)
 * and define in-memory only indexes in DocumentIndexesForWebInMemoryStorageOnly.
 */


// These indexes are shared across all storage backends, so be sparing with them.
// Adding too many indexes here can create significant write + storage overhead across all platforms.
// These are the only indexes that are used inside the IndexedDB storage backend.
export const DocumentIndexesCommonForWebAndMobileAndDesktop: DocumentIndexes = [
  ['triage_status', 'rxdbOnly.indexFields.category'],
  ['triage_status', 'rxdbOnly.indexFields.firstOpenedAtExists'],
  ['triage_status', 'rxdbOnly.indexFields.last_status_update_desc'],
  ['rxdbOnly.indexFields.triage_status_exists', 'rxdbOnly.indexFields.lastOpenedAt'],
  ['rxdbOnly.indexFields.triage_status_exists', 'rxdbOnly.indexFields.hasHighlights', 'rxdbOnly.indexFields.lastOpenedAt'],
  ['rxdbOnly.indexFields.triage_status_exists', 'rxdbOnly.indexFields.isNewOrLater', 'rxdbOnly.indexFields.length_in_seconds'],
  ['rxdbOnly.indexFields.triage_status_exists', 'rxdbOnly.indexFields.isNewOrLater', 'rxdbOnly.indexFields.startedReading', 'rxdbOnly.indexFields.lastOpenedAt'],
  ['rxdbOnly.indexFields.triage_status_exists', 'rxdbOnly.indexFields.isNewOrLater', 'saved_at'],
  ['rxdbOnly.indexFields.triage_status_exists', 'triage_status', 'rxdbOnly.indexFields.showInUnseenAfter', 'rxdbOnly.indexFields.saved_at_desc'],
  ['rxdbOnly.indexFields.triage_status_exists', 'triage_status', 'rxdbOnly.indexFields.showInSeenAfter', 'rxdbOnly.indexFields.saved_at_desc'],
  // shortlist:
  ['rxdbOnly.indexFields.triage_status_exists', 'triage_status', 'rxdbOnly.indexFields.hasShortlistTag', 'rxdbOnly.indexFields.isNewOrLater', 'rxdbOnly.indexFields.last_status_update_desc'],
  // TODO: other non-category default queries from here: https://github.com/TristanH/rekindled/blob/8c7c7d3102833c0970e0a9928ef31b7643f74829/reader/constants.py#L86
  // library:
  ['rxdbOnly.indexFields.triage_status_exists', 'triage_status', 'rxdbOnly.indexFields.last_status_update_desc'],
  ['rxdbOnly.indexFields.triage_status_exists', 'triage_status', 'rxdbOnly.indexFields.category', 'rxdbOnly.indexFields.saved_at_desc'],
  ['rxdbOnly.indexFields.triage_status_exists', 'rxdbOnly.indexFields.triage_status_is_feed', 'updated'],
  ['rxdbOnly.indexFields.parsed_doc_id'],
] as const;

export const DocumentIndexesForWebInMemoryStorageOnly: DocumentIndexes = [
  ['rxdbOnly.indexFields.author'],
  ['rxdbOnly.indexFields.category'],
  ['rxdbOnly.indexFields.last_status_update'],
  ['rxdbOnly.indexFields.last_status_update_desc'],
  ['rxdbOnly.indexFields.lastOpenedAt'],
  ['rxdbOnly.indexFields.length_in_seconds'],
  ['rxdbOnly.indexFields.parsed_doc_id'],
  ['rxdbOnly.indexFields.published_date'],
  ['rxdbOnly.indexFields.randomNumber1'],
  ['rxdbOnly.indexFields.randomNumber2'],
  ['rxdbOnly.indexFields.randomNumber3'],
  ['rxdbOnly.indexFields.randomNumber4'],
  ['rxdbOnly.indexFields.readingPosition_scrollDepth'],
  ['rxdbOnly.indexFields.saved_at'],
  ['rxdbOnly.indexFields.saved_at_desc'],
  ['rxdbOnly.indexFields.title'],
  ['rxdbOnly.indexFields.triage_status_exists'],
  ['rxdbOnly.indexFields.triage_status_exists', 'rxdbOnly.indexFields.firstOpenedAtExists', 'source_specific_data.rss_feed'],
] as const;

export const DocumentIndexesForDocumentsJSONSchema: DocumentIndexes = [
  ...DocumentIndexesCommonForWebAndMobileAndDesktop,

  // These are filtered out of IndexedDB via whitelisting DocumentIndexesCommonForWebAndMobile inside createCustomHybridRxStorage().
  ...DocumentIndexesForWebInMemoryStorageOnly,
] as const;

export const DocumentIndexesForSQLiteDocumentsStorageOnly: DocumentIndexes = [
  // builtin query for feed UNSEEN
  [
    'rxdbOnly.indexFields.triage_status_exists',
    'triage_status',
    'rxdbOnly.indexFields.saved_at_desc',
    'id',
    'rxdbOnly.indexFields.showInUnseenAfter',
  ],
  // builtin query for feed SEEN
  [
    'rxdbOnly.indexFields.triage_status_exists',
    'triage_status',
    'rxdbOnly.indexFields.saved_at_desc',
    'id',
    'rxdbOnly.indexFields.showInSeenAfter',
  ],
  // builtin query for Views > Feeds
  [
    'rxdbOnly.indexFields.triage_status_exists',
    'rxdbOnly.indexFields.firstOpenedAtExists',
    'rxdbOnly.indexFields.published_date',
    'id',
    'source_specific_data.rss_feed',
  ],
  // builtin query for filtered views of multiple feeds (created via the Manage Feeds page)
  [
    'rxdbOnly.indexFields.triage_status_exists',
    'rxdbOnly.indexFields.firstOpenedAtExists',
    'rxdbOnly.indexFields.last_status_update_desc',
    'id',
    // rss_feed needs to be at the end, because it's queried via set membership which fans out into an OR condition.
    // sqlite basically runs 1 query for each branch of the OR in parallel and UNIONs the results at the end.
    'source_specific_data.rss_feed',
  ],
  // builtin query for "Quick reads" and "Long reads" views
  [
    'rxdbOnly.indexFields.triage_status_exists',
    'triage_status',
    'rxdbOnly.indexFields.last_status_update_desc',
    'id',
    'rxdbOnly.indexFields.length_in_seconds',
  ],
  // builtin query for "Recently highlighted" view
  [
    'rxdbOnly.indexFields.triage_status_exists',
    'children',
    'rxdbOnly.indexFields.last_status_update_desc',
    'id',
    'lastOpenedAt',
  ],
  // builtin query for all category views
  [
    'rxdbOnly.indexFields.triage_status_exists',
    'rxdbOnly.indexFields.category',
    'rxdbOnly.indexFields.last_status_update_desc',
    'id',
  ],
  // to drastically speed up highlight & document deletion
  [
    'parent',
  ],
] as const;
