export enum LogLevel {
  Debug = 'debug',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

export enum LogMethodName {
  Info = 'info',
  Debug = 'debug',
  Error = 'error',
  Time = 'time',
  TimeEnd = 'timeEnd',
  Warn = 'warn',
  Log = 'log',
}

export const LogMethodToLogLevel: {[name in LogMethodName]: LogLevel} = {
  [LogMethodName.Info]: LogLevel.Info,
  [LogMethodName.Debug]: LogLevel.Debug,
  [LogMethodName.Error]: LogLevel.Error,
  [LogMethodName.Time]: LogLevel.Debug,
  [LogMethodName.TimeEnd]: LogLevel.Debug,
  [LogMethodName.Warn]: LogLevel.Warn,
  [LogMethodName.Log]: LogLevel.Debug,
};

export const LogLevelToRank: {[level in LogLevel]: number} & { none: number; } = {
  [LogLevel.Debug]: 0,
  [LogLevel.Info]: 1,
  [LogLevel.Warn]: 2,
  [LogLevel.Error]: 3,
  none: 3,
};

export const DefaultLogLevel = LogLevel.Info;
