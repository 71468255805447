import { Event } from '@sentry/browser';

export default (event: Event, extensionId?: string): boolean => {
  // Allow Sentry.captureMessage();
  if (event.level && ['log', 'info', 'warn'].includes(event.level)) {
    return true;
  }

  const frames = event.exception && event.exception.values && event.exception.values[0] && event.exception.values[0].stacktrace && event.exception.values[0].stacktrace.frames || [];
  let filenameRegexStr = '^@?[a-z]+-extension://';
  if (extensionId && !extensionId.includes('@')) {
    filenameRegexStr += extensionId.replace(/[{}]/g, '');
  }
  const filenameRegex = new RegExp(filenameRegexStr, 'i');
  return frames.some((frame) => frame.filename && filenameRegex.test(frame.filename));
};
