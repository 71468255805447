import * as jsonpatch from 'readwise-fast-json-patch';
import type { RxChangeEvent, RxCollection } from 'rxdb';

import type { AnyDocument, PersistentStateWithDocuments } from '../types';
import type { DatabaseCollectionNames, DatabaseCollectionNamesToDocType } from '../types/database';
import cloneDeep from '../utils/cloneDeep';
import removeNonSyncingPropertiesFromDocument from '../utils/removeNonSyncingPropertiesFromDocument';
import safeJsonPatchCompareOfState from '../utils/safeJsonPatchCompareOfState';
import { convertRxDocumentDataToOurJson } from './internals/convertRxDocumentToOurJson';

type Result = {
  forward: jsonpatch.Operation[];
  reverse: jsonpatch.Operation[];
};

export default function createJsonPatchOperationsFromRxChangeEvents<
  TCollectionName extends DatabaseCollectionNames,
  TDocType extends DatabaseCollectionNamesToDocType[TCollectionName],
>(
  events: RxChangeEvent<TDocType>[],
  rxCollection: RxCollection<TDocType>,
): Result {
  const jsonPatchOperations: Result = {
    forward: [],
    reverse: [],
  };
  for (const event of events) {
    const previousState = {
      [rxCollection.name]: event.operation === 'INSERT'
      ? {}
      : {
        [event.documentId]: convertRxDocumentDataToOurJson(rxCollection, cloneDeep(event.previousDocumentData)),
      },
    } as unknown as PersistentStateWithDocuments;
    const nextState = {
      [rxCollection.name]: event.operation === 'DELETE'
        ? {}
        : {
          [event.documentId]: convertRxDocumentDataToOurJson(rxCollection, cloneDeep(event.documentData)),
        },
    } as unknown as PersistentStateWithDocuments;

    for (const doc of Object.values(previousState.documents).concat(Object.values(nextState.documents))) {
      removeNonSyncingPropertiesFromDocument(doc as AnyDocument);
    }

    const forwardJsonPatchOperations = safeJsonPatchCompareOfState(previousState, nextState);
    if (forwardJsonPatchOperations.length) {
      jsonPatchOperations.forward.push(...forwardJsonPatchOperations);
      jsonPatchOperations.reverse.push(...safeJsonPatchCompareOfState(nextState, previousState));
    }
  }
  return jsonPatchOperations;
}
