// eslint-disable-next-line restrict-imports/restrict-imports
import database from '../../foreground/database';
import { isTest } from '../../utils/environment';

export default isTest

  /*
    Our Cypress component tests were throwing "Cannot access 'database' before initialization" for some reason. This works around that.
  */
  ? new Proxy({}, {
    get(target, property) {
      return database[property];
    },
  })
  : database;
