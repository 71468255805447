import { DatabaseGetSchemaResult } from '../../../types/database';
import * as commonDefinitions from './commonDefinitions';

function getSchema(): { state_syncing_updates: DatabaseGetSchemaResult<'state_syncing_updates'>; } {
  return {
    state_syncing_updates: {
      schema: {
        primaryKey: 'id',
        properties: {
          id: commonDefinitions.getString(100),
          sentToServer: {
            type: 'number',
            minimum: 0,
            maximum: 1,
            multipleOf: 1,
          },
          value: commonDefinitions.getString(Infinity),
        },
        required: ['id', 'sentToServer', 'value'],
        indexes: [
          ['sentToServer'],
        ],
        type: 'object',
        version: 0,
      },
    },
  } as const;
}

export default {
  getSchema,
};
