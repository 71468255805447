import merge from 'lodash/merge';
import omit from 'lodash/omit';
import remove from 'lodash/remove';

import batchifyDatabaseUpdate from '../../../database/batchify';
import {
  type Font,
  type FullZustandState,
  type TextDirection,
  type TshirtSize,
  type UserEvent,
  Category,
} from '../../../types';
import type { GlobalTag } from '../../../types/tags';
import { notEmpty } from '../../../typeValidators';
import { cleanAndValidateTagName } from '../../../utils/cleanAndValidateTagName';
import { isMobile } from '../../../utils/environment';
import makeLogger from '../../../utils/makeLogger';
import database from '../../database';
// eslint-disable-next-line import/no-cycle
import { getDefaultClientState, globalState, updateState, WEB_FONTS_SANS_SERIF, WEB_FONTS_SERIF } from '../../models';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';
import fixBadDocumentLocationsValue from '../../utils/fixBadDocumentLocationsValue';

const logger = makeLogger(__filename);

/*
  Global tags are now stored in RxDB but they were once stored in client state under `allTags`. If that exists,
  we move the data over to the RxDB collection.
*/
export async function initClientData (
  clientData: null | FullZustandState['client'] & {
    allTags?: {
      [key: string]: {
        count: number;
        created: number;
        docsCount?: number;
        highlightsCount?: number;
        name: string;
      };
    };
  },
): Promise<void> {
  const currentGlobalState = globalState.getState();
  const clientState = merge(
    {},
    getDefaultClientState({
      documentLocations: fixBadDocumentLocationsValue(currentGlobalState.persistent.settings.documentLocations),
    }),
    clientData,
  );

  const promises: Promise<unknown>[] = [
    updateState((state: FullZustandState) => {
      state.client = omit(clientState, ['allTags']);
      state.clientStateLoaded = true;
    }, { userInteraction: null, eventName: 'client-settings-updated', isUndoable: false }),
  ];

  if (clientData?.allTags) {
    const globalTags: GlobalTag[] = Object.entries(clientData.allTags)
      .map(([id, allTagsTag]) => {
        const { cleanTagName, validationError } = cleanAndValidateTagName(allTagsTag.name);
        if (validationError) {
          logger.warn('Invalid tag name', { validationError });
          return;
        }
        return {
          firstClassDocumentsCount: allTagsTag.docsCount ?? 0,
          highlightsCount: allTagsTag.highlightsCount ?? 0,
          id,
          lastAssignedAt: allTagsTag.created,
          name: cleanTagName,
          totalCount: allTagsTag.count ?? 0,
        };
      })
      .filter(notEmpty);
    promises.push(batchifyDatabaseUpdate({
      args: [globalTags, {}],
      collectionName: 'global_tags',
      func: database.collections.global_tags.bulkUpsert,
    }));
  }

  await Promise.all(promises);
}

export const setFontSize = async (val: TshirtSize, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState((state) => {
    if (isMobile) {
      state.client.readerSettings.mobile.fontSize = val;
    } else {
      state.client.readerSettings.desktop.fontSize = val;
    }
  }, { ...options, eventName: 'font-size-updated', isUndoable: false });
};

export const setLineSpacing = async (val: TshirtSize, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState((state) => {
    if (isMobile) {
      state.client.readerSettings.mobile.lineHeight = val;
    } else {
      state.client.readerSettings.desktop.lineHeight = val;
    }
  }, { ...options, eventName: 'line-spacing-updated', isUndoable: false });
};

export const setReaderHorizontalMargin = async (val: TshirtSize, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState((state) => {
    if (isMobile) {
      state.client.readerSettings.mobile.pageWidth = val;
    } else {
      state.client.readerSettings.desktop.lineLength = val;
    }
  }, { ...options, eventName: 'horizontal-margin-updated', isUndoable: false });
};

export const setFont = async (font: Font, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState((state) => {
    if (isMobile) {
      state.client.readerSettings.mobile.font = font;
    } else {
      state.client.readerSettings.desktop.font = font;
    }
  }, { ...options, eventName: 'mobile-font-updated', isUndoable: false });
};

function getNextFont(currentFont: Font): Font {
  const keys = [...WEB_FONTS_SERIF, ...WEB_FONTS_SANS_SERIF];

  const currentIndex = keys.findIndex((key) => {
    return key === currentFont;
  });

  const firstFont = keys[0];
  return (keys[currentIndex + 1] || firstFont) as Font;
}

export const cycleTypeface = async (options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  const currentFont = globalState.getState().client.readerSettings.desktop.font;
  const nextFont = getNextFont(currentFont);
  setFont(nextFont, { userInteraction: 'unknown' });
};

export const setTextDirection = async (direction: TextDirection) => {
  const updateResult = await updateState((state) => {
    if (isMobile) {
      state.client.readerSettings.mobile.direction = direction;
    } else {
      state.client.readerSettings.desktop.direction = direction;
    }
  }, { eventName: 'text-direction-changed', userInteraction: null, isUndoable: false });

  createToast({
    content: `Text direction changed to ${direction.toLocaleUpperCase()}`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const setPaginationAnimationsDisabled = async (disabled: boolean) => {
  if (!isMobile) {
    return;
  }
  await updateState((state) => {
    state.client.readerSettings.mobile.arePaginationAnimationsDisabled = disabled;
  }, { eventName: 'mobile-pagination-animations-toggled', userInteraction: null, isUndoable: false });
};
// TODO: Re-enable if we want to try this again
// export const setPaginationHapticsOnScrollEnabled = async (enabled: boolean) => {
//   if (!isMobile) {
//     return;
//   }
//   await updateState((state) => {
//     state.client.readerSettings.mobile.arePaginationHapticsOnScrollEnabled = enabled;
//   }, { eventName: 'mobile-pagination-haptics-on-scroll-toggled', userInteraction: null, isUndoable: false });
// };
export const setPaginationDefaultForCategories = async (categories: Category[], defaultEnabled: boolean) => {
  if (!isMobile) {
    return;
  }
  await updateState((state) => {
    const arrCopy = Array.from(state.client.readerSettings.mobile.paginationOnByDefaultList);
    for (const category of categories) {
      remove(arrCopy, (v) => v === category);
      if (defaultEnabled) {
        arrCopy.push(category);
      }
    }
    state.client.readerSettings.mobile.paginationOnByDefaultList = arrCopy;
  }, { eventName: 'mobile-default-pagination-for-category-toggled', userInteraction: null, isUndoable: false });
};

export const toggleShouldInvertPDFColors = async (): Promise<void> => {
  await updateState((state) => {
    state.client.shouldInvertPDFColors = !state.client.shouldInvertPDFColors;
  }, { userInteraction: 'unknown', eventName: 'toggle-should-invert-pdf-colors' });
};

export const toggleMobileShouldUseVolumeButtonsToAdvancePage = async (): Promise<void> => {
  await updateState((state) => {
    state.client.mobileShouldUseVolumeButtonsToScrollPages = !state.client.mobileShouldUseVolumeButtonsToScrollPages;
  }, { userInteraction: 'tap', eventName: 'toggle-mobile-should-use-volume-buttons-to-advance-page' });
};
export const toggleMobileAreAnimationsDisabled = async (): Promise<void> => {
  await updateState((state) => {
    state.client.mobileAreAnimationsDisabled = !state.client.mobileAreAnimationsDisabled;
  }, { userInteraction: 'tap', eventName: 'toggle-mobile-are-animations-disabled' });
};

export const toggleIsGhostreaderEnabled = async (): Promise<void> => {
  await updateState((state) => {
    state.client.isGhostreaderEnabled = !state.client.isGhostreaderEnabled;
  }, { userInteraction: 'tap', eventName: 'toggle-enable-ghostreader' });
};
