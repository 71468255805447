import { relaunch } from '@tauri-apps/plugin-process';

import { isDesktopApp } from './utils/environment';

export default async () => {
  if (isDesktopApp) {
    await relaunch();
  } else {
    window.location?.reload();
  }
};

