import {
  ensureRxStorageInstanceParamsAreCorrect,
  FilledMangoQuery,
  flatClone,
  INTERNAL_STORE_SCHEMA_TITLE,
  PreparedQuery,
  randomCouchString,
  RxDocumentData,
  RxJsonSchema,
  RxStorage,
  RxStorageInstance,
  RxStorageInstanceCreationParams,
} from 'rxdb';

import { getRxStorageMemory } from '../storage-memory';
import { HybridRxStorageInstance } from './hybrid-storage-instance';
import type { HybridInstanceCreationOptions, HybridStorageInternals, HybridStorageSettings } from './hybrid-types';
import { getFilteredIndexes } from './utils';

const memoryStorage = getRxStorageMemory();

export class RxStorageHybrid implements RxStorage<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HybridStorageInternals<any>,
  HybridInstanceCreationOptions
> {
  public name = 'hybrid';

  public statics = {
    ...memoryStorage.statics,
    prepareQuery: <RxDocType>(
      schema: RxJsonSchema<RxDocumentData<RxDocType>>,
      mutateableQuery: FilledMangoQuery<RxDocType>,
    ): PreparedQuery<RxDocType> => {

      /**
       * For the meta instance of the RxDatabase, we directly use the
       * persisted storage, so prepared queries must also run on
       * that storage's prepareQuery()
       */
      if (schema.title === INTERNAL_STORE_SCHEMA_TITLE) {
        return this.settings.storage.statics.prepareQuery(schema, mutateableQuery);
      } else {
        return memoryStorage.statics.prepareQuery(schema, mutateableQuery);
      }
    },
  };

  constructor(
    public settings: HybridStorageSettings,
  ) {
  }

  async createStorageInstance<RxDocType>(
    params: RxStorageInstanceCreationParams<RxDocType, HybridInstanceCreationOptions>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<RxStorageInstance<RxDocType, HybridStorageInternals<any>, HybridInstanceCreationOptions>> {
    ensureRxStorageInstanceParamsAreCorrect(params);

    if (params.schema.attachments) {
      throw new Error('The hybrid plugin does not support attachments');
    }

    const masterParams = flatClone(params);

    /**
     * Handle the indices for the wrapped storage. The wrapped storage might
     * benefit from a reduced set of indices in order to reduce the load on
     * writes. The following code must handle compressed and uncompressed
     * schemas.
     */
    masterParams.schema = flatClone(masterParams.schema);
    if (
      this.settings.wrappedStorageSchema?.[params.collectionName] &&
      this.settings.wrappedStorageIndexes?.[params.collectionName]
    ) {
      const wrappedStorageSchema = this.settings.wrappedStorageSchema[params.collectionName];
      // normalize indices by appending `id`
      const wrappedStorageIndexes = this.settings.wrappedStorageIndexes[params.collectionName]
        .map((index) => {
          const preliminaryIndex = Array.isArray(index) ? index : [index];
          if (preliminaryIndex[preliminaryIndex.length - 1] === 'id') {
            return preliminaryIndex;
          }
          preliminaryIndex.push('id');
          return preliminaryIndex;
        });
      masterParams.schema.indexes = getFilteredIndexes(
        Boolean(this.settings.wrappedStorageSchemaCompression),
        params.schema,
        wrappedStorageSchema,
        wrappedStorageIndexes,
      );
    }

    const masterInstancePromise = this.settings.storage.createStorageInstance(masterParams);
    const forkInstancePromise = memoryStorage.createStorageInstance(
      {
        ...params,
        multiInstance: false,
        databaseInstanceToken: randomCouchString(10),
        collectionName: `${params.collectionName}-hybrid`,
      },
    );

    const masterInstance = await masterInstancePromise;
    const forkInstance = await forkInstancePromise;

    const storage = new HybridRxStorageInstance(
      params.databaseName,
      params.collectionName,
      params.schema,
      {
        masterInstance,
        forkInstance,
      },
      {},
    );

    // eslint-disable-next-line no-console
    console.time(`replicate ${params.collectionName}`);
    storage
      .replicate(this.settings, this.settings.batchSize)
      .then((_) => {
        // eslint-disable-next-line no-console
        console.timeEnd(`replicate ${params.collectionName}`);
      })
      // eslint-disable-next-line no-console
      .catch(console.error);

    return storage;
  }
}

export function getHybridRxStorage(
  settings: HybridStorageSettings,
) {
  return new RxStorageHybrid(settings);
}

export * from './hybrid-types';
export * from './hybrid-storage-instance';
