import type { ById, CategorizeBulkWriteRowsOutput, RxAttachmentWriteData, RxStorageWriteError } from 'rxdb';
import {
  BulkWriteRow,
  defaultHashSha256,
  ensureNotFalsy,
  EventBulk,
  getIndexableStringMonad,
  getPrimaryFieldOfPrimaryKey,
  getQueryMatcher,
  getSortComparator,
  getStartIndexStringFromLowerBound,
  getStartIndexStringFromUpperBound,
  getUniqueDeterministicEventKey,
  newRxError,
  now,
  objectPathMonad,
  randomCouchString,
  RxConflictResultionTask,
  RxConflictResultionTaskSolution,
  RxDocumentData,
  RxDocumentDataById,
  RxJsonSchema,
  RxStorageBulkWriteResponse,
  RxStorageChangeEvent,
  RxStorageCountResult,
  RxStorageDefaultCheckpoint,
  RxStorageInstance,
  RxStorageInstanceCreationParams,
  RxStorageQueryResult,
  StringKeys,
  stripAttachmentsDataFromDocument,
  stripAttachmentsDataFromRow,
} from 'rxdb';
import { RxQueryPlanKey } from 'rxdb/dist/types/types';
import { getHeightOfRevision } from 'rxdb/plugins/utils';
import { BulkWriteRowProcessed, RxStorageWriteErrorAttachment } from 'rxdb/src/types';
import { Observable, Subject } from 'rxjs';
import BTree from 'sorted-btree';

import {
  MemoryPreparedQuery,
  MemoryStorageInternals,
  RxStorageMemory,
  RxStorageMemoryInstanceCreationOptions,
  RxStorageMemorySettings,
} from './memory-types';

const RX_META_LWT_MINIMUM = 1;
const PROMISE_RESOLVE_VOID = Promise.resolve();

enum IndexType {
  SelectorIndex = 'SELECTOR_INDEX_TYPE',
  SortIndex = 'SORT_INDEX_TYPE'
}

const isDev = import.meta.env.DEV;

class Index<RxDocType> extends BTree<RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string, RxDocumentData<RxDocType>> {
  public readonly keyFn;
  public readonly valueFn;
  public readonly indexFields: string[];

  public constructor(
    public schema: RxJsonSchema<RxDocumentData<RxDocType>>,
    indexFields: string | ReadonlyArray<string>,
    normalizeIndex = true,
  ) {
    super();

    const index = Array.isArray(indexFields) ? Array.from(indexFields) : [indexFields];

    /**
     * Running a query will only return non-deleted documents
     * so all indexes must have the deleted field as first index field.
     */
    if (normalizeIndex) {
      index.unshift('_deleted');
    }

    this.indexFields = index;
    this.keyFn = getIndexableStringMonad(schema, index);
    this.valueFn = (fieldName: string) => objectPathMonad(fieldName);
  }
}

export class RxStorageInstanceMemory<RxDocType> implements RxStorageInstance<
  RxDocType,
  MemoryStorageInternals<RxDocType>,
  RxStorageMemoryInstanceCreationOptions,
  RxStorageDefaultCheckpoint
> {

  public readonly primaryPath: StringKeys<RxDocumentData<RxDocType>>;
  public closed = false;

  private readonly table: Index<RxDocType>;
  private readonly indices: Map<string, Index<RxDocType>>;

  constructor(
    public readonly storage: RxStorageMemory,
    public readonly databaseName: string,
    public readonly collectionName: string,
    public readonly schema: Readonly<RxJsonSchema<RxDocumentData<RxDocType>>>,
    public readonly internals: MemoryStorageInternals<RxDocType>,
    public readonly options: Readonly<RxStorageMemoryInstanceCreationOptions>,
    public readonly settings: RxStorageMemorySettings,
  ) {
    this.primaryPath = getPrimaryFieldOfPrimaryKey(this.schema.primaryKey);

    const indices = (schema.indexes ?? [])
      .concat([[
        '_meta.lwt',
        this.primaryPath,
      ]]);

    this.table = new Index<RxDocType>(schema, this.primaryPath);
    this.indices = new Map(indices.map((index) => {
      const indexName = getIndexString(index);
      return [indexName, new Index<RxDocType>(schema, index)];
    }));

    // special index for __getChangedDocumentsSince (we don't care about deleted flag)
    this.indices.set(
      '__getChangedDocumentsSince',
      new Index<RxDocType>(schema, ['_meta.lwt', this.primaryPath], false),
    );

    if (isDev && typeof window !== 'undefined') {
      window[`custom-memory-storage:${databaseName}:${collectionName}`] = this;
    }

    if (isDev && typeof globalThis !== 'undefined') {
      globalThis[`custom-memory-storage:${databaseName}:${collectionName}`] = this;
    }
  }

  async bulkWrite(
    documentWrites: BulkWriteRow<RxDocType>[],
    context: string,
  ): Promise<RxStorageBulkWriteResponse<RxDocType>> {
    const primaryPath = this.primaryPath;
    const ret: RxStorageBulkWriteResponse<RxDocType> = {
      success: {},
      error: {},
    };

    const categorized = categorizeBulkWriteRows<RxDocType>(
      this,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      primaryPath as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.table,
      documentWrites,
      context,
    );
    ret.error = categorized.errors;

    const bulkInsertDocs = categorized.bulkInsertDocs;
    for (const doc of bulkInsertDocs) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const docId: any = doc.document[this.primaryPath];
      this.write(docId, doc.document);
      ret.success[docId] = doc.document;
    }

    const bulkUpdateDocs = categorized.bulkUpdateDocs;
    for (const doc of bulkUpdateDocs) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const docId: any = doc.document[this.primaryPath];
      this.write(docId, doc.document);
      ret.success[docId] = doc.document;
    }

    if (categorized.eventBulk.events.length > 0 && !context.startsWith('replication-downstream-rx-storage-replication')) {
      const lastState = ensureNotFalsy(categorized.newestRow).document;
      categorized.eventBulk.checkpoint = {
        id: lastState[this.primaryPath],
        lwt: lastState._meta.lwt,
      };
      this.internals.changes$.next(categorized.eventBulk);
    }

    return ret;
  }

  async findDocumentsById(
    docIds: string[],
    withDeleted: boolean,
  ): Promise<RxDocumentDataById<RxDocType>> {
    return docIds.reduce((result, docId) => {
      const doc = this.read(docId);
      if (doc && (doc._deleted && withDeleted || !doc._deleted)) {
        result[doc[this.primaryPath] as string] = doc;
      }
      return result;
    }, {});
  }

  async query(
    preparedQuery: MemoryPreparedQuery<RxDocType>,
  ): Promise<RxStorageQueryResult<RxDocType>> {
    const timerId = Math.random();
    if (isDev) {
      // eslint-disable-next-line no-console
      console.time(`rxdb.internals.storage.memory.query ${timerId}`);
    }

    // TODO When the query has no limit, it is very likely that the best index
    //  from the selector is going to visit and load fewer rows, than iterating
    //  through a fully sorted index + checking matches.
    //  1.: We can determine the likely cost of a query from adding index stats.
    //  e.g., the distribution of values in a particular index. This only makes
    //  sense when there are relatively few different values (not timestamps).
    //  2.: Given a simple $eq at the top-level would result in a small % of
    //  rows returned, we can just pick this index.

    const queryPlan = preparedQuery.queryPlan;

    const limit = preparedQuery.query.limit;
    const skip = preparedQuery.query.skip ?? 0;

    // new index determination
    const {
      direction: indexDirection,
      fields: indexFields,
      name: indexName,
      type: indexType,
      startKeys,
      endKeys,
      reSort: forceResort,
    } = determineOptimalIndex(this.primaryPath, this.indices, preparedQuery);
    const fullIndexFields = ['_deleted'].concat(indexFields);
    const index = indexName ? this.indices.get(indexName)! : this.table;

    const lowerBoundString = startKeys
      ? getStartIndexStringFromLowerBound(
        this.schema,
        fullIndexFields,
        [false, ...startKeys],
        queryPlan.inclusiveStart,
      )
      : undefined;

    const upperBoundString = endKeys
      ? getStartIndexStringFromUpperBound(
        this.schema,
        fullIndexFields,
        [false, ...endKeys],
        queryPlan.inclusiveEnd,
      )
      : undefined;

    // converts the mango query into a set of functions that can verify if a document should show up in the result
    const queryMatcher = getQueryMatcher(
      this.schema,
      preparedQuery.query,
    );

    // reverse the iterator over the candidate set when direction is `descending`
    const indexRangeWithDirection = indexDirection === 'asc'
      ? values(index, lowerBoundString, upperBoundString, undefined, indexType === IndexType.SortIndex ? false : !queryPlan.inclusiveEnd)
      : valuesReversed(index, lowerBoundString, upperBoundString, undefined, indexType === IndexType.SortIndex ? false : !queryPlan.inclusiveStart);

    // TODO When IndexType.SelectorIndex, manually re-sort on the final range
    //  when (for whatever reason), the range.length === table.length, try to
    //  pivot using the sort index and use either entries() or entriesReversed()
    //  depending on the direction.
    const sortComparator = getSortComparator(this.schema, preparedQuery.query);
    const sortedIndexRangeWithDirection = forceResort
      // TODO: is there a better way than consuming all entries just for sorting
      ? Array.from(indexRangeWithDirection).sort(sortComparator)
      : indexRangeWithDirection;

    // create final result set
    const documents: RxDocumentData<RxDocType>[] = [];
    // number of documents added to result set
    let i = 0;
    // number of documents visited
    let v = 0;
    // this consumes the iterator up to the point where we have found enough documents
    for (const document of sortedIndexRangeWithDirection) {
      v++;

      if (queryMatcher(document)) {
        i++;
        if (skip && skip >= i) {
          continue;
        }

        documents.push(document);
      }

      if (limit && i === limit + skip) {
        break;
      }
    }

    if (isDev) {
      // eslint-disable-next-line no-console
      // eslint-disable-next-line no-console
      console.debug(`rxdb.internals.storage.memory.query ${timerId}:\n\nquery=${JSON.stringify(preparedQuery.query, null, 2)}\nlimit=${limit}, visited=${v}, returned=${documents.length}\nindex=${indexName ?? 'full-table scan'}\nindexFields=${indexFields}\nindexType=${indexType}\n\tlower=${lowerBoundString}\n\tupper=${upperBoundString}\n\tdirection=${indexDirection}\nselectorSatisfiedByIndex=${preparedQuery.queryPlan.selectorSatisfiedByIndex}\nsortFieldsSameAsIndexFields=${preparedQuery.queryPlan.sortFieldsSameAsIndexFields}`);
      // eslint-disable-next-line no-console
      console.log(`rxdb.internals.storage.memory.query ${timerId}:`, index);
      // eslint-disable-next-line no-console
      console.timeEnd(`rxdb.internals.storage.memory.query ${timerId}`);
    }

    return { documents };
  }

  async count(
    preparedQuery: MemoryPreparedQuery<RxDocType>,
  ): Promise<RxStorageCountResult> {
    const result = await this.query(preparedQuery);
    return {
      count: result.documents.length,
      mode: 'slow',
    };
  }

  async getChangedDocumentsSince(
    limit: number,
    checkpoint?: RxStorageDefaultCheckpoint,
  ): Promise<{
    documents: RxDocumentData<RxDocType>[];
    checkpoint: RxStorageDefaultCheckpoint;
  }> {
    const sinceLwt = checkpoint ? checkpoint.lwt : RX_META_LWT_MINIMUM;
    const sinceId = checkpoint ? checkpoint.id : '';

    const indexName = '__getChangedDocumentsSince';

    const lowerBoundString = getStartIndexStringFromLowerBound(
      this.schema,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ['_meta.lwt', this.primaryPath as any],
      [
        sinceLwt,
        sinceId,
      ],
      false,
    );

    const docsWithIndex = this.indices.get(indexName)!;
    const documents = docsWithIndex
      .getRange(lowerBoundString, docsWithIndex.maxKey()!, true, limit)
      .map(([, doc]) => doc);

    const lastDoc = lastOfArray(documents);
    return {
      documents,
      checkpoint: lastDoc ? {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        id: lastDoc[this.primaryPath] as any,
        lwt: lastDoc._meta.lwt,
      } : checkpoint ? checkpoint : {
        id: '',
        lwt: 0,
      },
    };
  }

  cleanup(minimumDeletedTime: number): Promise<boolean> {
    return Promise.resolve(true);
  }

  getAttachmentData(
    documentId: string,
    attachmentId: string,
    digest: string,
  ): Promise<string> {
    return Promise.resolve('');
  }

  changeStream(): Observable<EventBulk<RxStorageChangeEvent<RxDocumentData<RxDocType>>, RxStorageDefaultCheckpoint>> {
    return this.internals.changes$.asObservable();
  }

  async remove(): Promise<void> {
    this.table.clear();
    this.indices.forEach((index) => index.clear());
    await this.close();
  }

  close(): Promise<void> {
    if (this.closed) {
      return Promise.reject(new Error('already closed'));
    }
    this.closed = true;

    return PROMISE_RESOLVE_VOID;
  }

  conflictResultionTasks(): Observable<RxConflictResultionTask<RxDocType>> {
    return this.internals.conflictResolutionTasks$.asObservable();
  }

  resolveConflictResultionTask(_taskSolution: RxConflictResultionTaskSolution<RxDocType>): Promise<void> {
    return PROMISE_RESOLVE_VOID;
  }

  private write(key: RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string, newDocument: RxDocumentData<RxDocType>) {
    const previousDocument = this.table.get(key);
    for (const [, index] of this.indices) {
      const newIndexString = index.keyFn(newDocument);
      if (previousDocument) {
        const previousIndexString = index.keyFn(previousDocument);
        // if the index string hasn't changed, we can do nothing, otherwise we
        // remove the old entry and replace with the new one
        if (newIndexString !== previousIndexString) {
          index.delete(previousIndexString);
        }
      }
      index.set(newIndexString, newDocument);
    }
    this.table.set(key, newDocument);
  }

  private read(key: RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string) {
    return this.table.get(key);
  }
}

export async function createMemoryStorageInstance<RxDocType>(
  storage: RxStorageMemory,
  params: RxStorageInstanceCreationParams<RxDocType, RxStorageMemoryInstanceCreationOptions>,
  settings: RxStorageMemorySettings,
): Promise<RxStorageInstanceMemory<RxDocType>> {
  const collectionKey = getMemoryCollectionKey(
    params.databaseName,
    params.collectionName,
    params.schema.version,
  );

  let internals = storage.collectionStates.get(collectionKey);
  if (!internals) {
    internals = {
      conflictResolutionTasks$: new Subject(),
      changes$: new Subject(),
    };
    storage.collectionStates.set(collectionKey, internals);
  }

  return new RxStorageInstanceMemory(
    storage,
    params.databaseName,
    params.collectionName,
    params.schema,
    internals,
    params.options,
    settings,
  );
}

function getMemoryCollectionKey(
  databaseName: string,
  collectionName: string,
  schemaVersion: number,
): string {
  return [
    databaseName,
    collectionName,
    schemaVersion,
  ].join('--memory--');
}

function getIndexString(index: string | ReadonlyArray<string>) {
  return Array.isArray(index) ? index.join(',') : index as string;
}

type IndexDetails = {
  type: IndexType;
  direction: 'asc' | 'desc';
  fields: string[];
  reSort: boolean;
  name?: string;
  startKeys?: RxQueryPlanKey[];
  endKeys?: RxQueryPlanKey[];
};

function determineOptimalIndex<RxDocType>(
  primaryKey: string,
  indices: Map<string, Index<RxDocType>>,
  preparedQuery: MemoryPreparedQuery<RxDocType>,
): IndexDetails {
  const queryPlan = preparedQuery.queryPlan;
  const limit = preparedQuery.query.limit;

  const indexName = getIndexString(queryPlan.index);
  const indexExists = indices.has(indexName);

  const sortFields = preparedQuery.query.sort.reduce((fields, field) => {
    const [sortField, sortDirection] = Object.entries(field)[0];
    fields.set(sortField, sortDirection);
    return fields;
  }, new Map());
  const sortIndexName = getIndexString(Array.from(sortFields.keys()));
  const sortDirection: 'asc' | 'desc' = Array.from(sortFields.values())[0];
  const sortIndexExists = indices.has(sortIndexName);

  const fields = queryPlan.index;
  const fullFields = ['_deleted'].concat(fields);

  // 1. selector includes sort field and selector is satisfied by an index
  if (queryPlan.selectorSatisfiedByIndex && queryPlan.sortFieldsSameAsIndexFields && indexExists) {
    return {
      direction: sortDirection,
      fields,
      reSort: false,
      type: IndexType.SortIndex, // force no manual re-sort, it is a perfect index
      name: indexName,
      startKeys: preparedQuery.queryPlan.startKeys,
      endKeys: preparedQuery.queryPlan.endKeys,
    };
  }

  // 2. there is no limit clause on the query, but the selector is satisfied by index
  if (!limit && queryPlan.selectorSatisfiedByIndex && indexExists) {
    return {
      direction: sortDirection,
      fields,
      reSort: true,
      type: IndexType.SelectorIndex,
      name: indexName,
      startKeys: preparedQuery.queryPlan.startKeys,
      endKeys: preparedQuery.queryPlan.endKeys,
    };
  }

  /*  3. there is a limit, selector is satisfied by an index and start or end keys are
   *     higher/lower than the min/max keys of the index
   *
   *  When the start and/or end keys are defined, it is very likely that
   *  the selector can reduce the query set significantly. Prefer the selector
   *  index over the sort index in such cases.
   *
   *  This does a simple check on the provided startKeys being higher than the
   *  minKey of the selected index, or the endKeys being lower than the maxKey.
   */

  if (limit && queryPlan.selectorSatisfiedByIndex && indexExists && (preparedQuery.queryPlan.startKeys || preparedQuery.queryPlan.endKeys)) {
    const index = indices.get(indexName)!;
    const minKey = index.minKey();
    const maxKey = index.maxKey();
    const startKey = getStartIndexStringFromLowerBound(
      index.schema,
      fullFields,
      [false, ...preparedQuery.queryPlan.startKeys],
      queryPlan.inclusiveStart,
    );
    const firstKey = index.getPairOrNextHigher(startKey)?.[0];
    const endKey = getStartIndexStringFromUpperBound(
      index.schema,
      fullFields,
      [false, ...preparedQuery.queryPlan.endKeys],
      queryPlan.inclusiveEnd,
    );
    const lastKey = index.getPairOrNextLower(endKey)?.[0];
    const startKeyIsHigher = Boolean(firstKey && (!minKey || minKey < firstKey));
    const endKeyIsLower = Boolean(lastKey && (!maxKey || maxKey > lastKey));

    if (startKeyIsHigher || endKeyIsLower) {
      return {
        direction: sortDirection,
        fields,
        reSort: true,
        type: IndexType.SelectorIndex,
        name: indexName,
        startKeys: preparedQuery.queryPlan.startKeys,
        endKeys: preparedQuery.queryPlan.endKeys,
      };
    }
  }

  // 4. there is a limit, no sort index, but the selector is satisfied by an index
  if (limit && queryPlan.selectorSatisfiedByIndex && indexExists && !sortIndexExists) {
    return {
      direction: sortDirection,
      fields,
      reSort: true,
      type: IndexType.SelectorIndex,
      name: indexName,
      startKeys: preparedQuery.queryPlan.startKeys,
      endKeys: preparedQuery.queryPlan.endKeys,
    };
  }

  // 5. there is a sort index with or without limit
  if (sortIndexExists) {
    return {
      direction: sortDirection,
      fields: Array.from(sortFields.keys()),
      reSort: false,
      type: IndexType.SortIndex,
      name: sortIndexName,
    };
  }

  if (indexName !== primaryKey) {
    // eslint-disable-next-line no-console
    console.warn(`rxdb.internals.storage.memory.query: missing sort index, field="${Array.from(sortFields.keys()).join(',')}"`);
  }

  // 6. full-table scan
  return {
    direction: 'asc',
    fields: [primaryKey],
    reSort: true,
    type: IndexType.SortIndex,
  };
}

export function categorizeBulkWriteRows<RxDocType>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storageInstance: RxStorageInstance<any, any, any>,
  primaryPath: StringKeys<RxDocType>,

  /**
   * Current state of the documents
   * inside the storage. Used to determine
   * which writes cause conflicts.
   * This can be a Map for better performance,
   * but it can also be an object because some storages
   * need to work with something that is JSON-stringify-able,
   * and we do not want to transform a big object into a Map
   * each time we use it.
   */
  docsInDb: BTree<RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string, RxDocumentData<RxDocType>>,

  /**
   * The write rows that are passed to
   * RxStorageInstance().bulkWrite().
   */
  bulkWriteRows: BulkWriteRow<RxDocType>[],
  context: string,
): CategorizeBulkWriteRowsOutput<RxDocType> {
  const hasAttachments = Boolean(storageInstance.schema.attachments);
  const bulkInsertDocs: BulkWriteRowProcessed<RxDocType>[] = [];
  const bulkUpdateDocs: BulkWriteRowProcessed<RxDocType>[] = [];
  const errors: ById<RxStorageWriteError<RxDocType>> = {};
  const changeByDocId = new Map<string, RxStorageChangeEvent<RxDocumentData<RxDocType>>>();
  const eventBulkId = randomCouchString(10);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventBulk: EventBulk<RxStorageChangeEvent<RxDocumentData<RxDocType>>, any> = {
    id: eventBulkId,
    events: [],
    checkpoint: null,
    context,
  };

  const attachmentsAdd: {
    documentId: string;
    attachmentId: string;
    attachmentData: RxAttachmentWriteData;
    digest: string;
  }[] = [];
  const attachmentsRemove: {
    documentId: string;
    attachmentId: string;
    digest: string;
  }[] = [];
  const attachmentsUpdate: {
    documentId: string;
    attachmentId: string;
    attachmentData: RxAttachmentWriteData;
    digest: string;
  }[] = [];


  const startTime = now();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasDocsInDb = docsInDb.size > 0;

  let newestRow: BulkWriteRowProcessed<RxDocType> | undefined;

  const rowAmount = bulkWriteRows.length;
  for (let rowId = 0; rowId < rowAmount; rowId++) {
    const writeRow = bulkWriteRows[rowId];
    const docId = writeRow.document[primaryPath] as string;
    let documentInDb: RxDocumentData<RxDocType> | undefined;
    if (hasDocsInDb) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      documentInDb = docsInDb.get(docId);
    }
    let attachmentError: RxStorageWriteErrorAttachment<RxDocType> | undefined;

    if (!documentInDb) {

      /**
       * It is possible to insert already deleted documents,
       * this can happen on replication.
       */
      const insertedIsDeleted = Boolean(writeRow.document._deleted);
      if (hasAttachments) {
        Object.entries(writeRow.document._attachments).forEach(([attachmentId, attachmentData]) => {
          if (
            !(attachmentData as RxAttachmentWriteData).data
          ) {
            attachmentError = {
              documentId: docId,
              isError: true,
              status: 510,
              writeRow,
              attachmentId,
            };
            errors[docId] = attachmentError;
          } else {
            attachmentsAdd.push({
              documentId: docId,
              attachmentId,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              attachmentData: attachmentData as any,
              digest: defaultHashSha256((attachmentData as RxAttachmentWriteData).data),
            });
          }
        });
      }
      if (!attachmentError) {
        if (hasAttachments) {
          bulkInsertDocs.push(stripAttachmentsDataFromRow(writeRow));
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          bulkInsertDocs.push(writeRow as any);
        }
        if (
          !newestRow ||
          newestRow.document._meta.lwt < writeRow.document._meta.lwt
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          newestRow = writeRow as any;
        }
      }

      if (!insertedIsDeleted) {
        const event = {
          eventId: getUniqueDeterministicEventKey(
            eventBulkId,
            rowId,
            docId,
            writeRow,
          ),
          documentId: docId,
          operation: 'INSERT' as const,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          documentData: hasAttachments ? stripAttachmentsDataFromDocument(writeRow.document) : writeRow.document as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          previousDocumentData: hasAttachments && writeRow.previous ? stripAttachmentsDataFromDocument(writeRow.previous) : writeRow.previous as any,
          startTime,
          endTime: now(),
        };
        changeByDocId.set(docId, event);
        eventBulk.events.push(event);
      }
    } else {
      // update existing document
      const revInDb: string = documentInDb._rev;

      /**
       * Check for conflict
       */

      if (

        !writeRow.previous ||

        Boolean(writeRow.previous) &&
        writeRow.previous[primaryPath] === documentInDb[primaryPath] &&
        getHeightOfRevision(revInDb) !== getHeightOfRevision(writeRow.previous._rev)

      ) {
        // is conflict error
        errors[docId] = {
          isError: true,
          status: 409,
          documentId: docId,
          writeRow,
          documentInDb,
        };
        continue;
      }

      // handle attachments data

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updatedRow: BulkWriteRowProcessed<RxDocType> = hasAttachments ? stripAttachmentsDataFromRow(writeRow) : writeRow as any;
      if (hasAttachments) {
        if (writeRow.document._deleted) {

          /**
           * Deleted documents must have cleared all their attachments.
           */
          if (writeRow.previous) {
            Object
              .keys(writeRow.previous._attachments)
              .forEach((attachmentId) => {
                attachmentsRemove.push({
                  documentId: docId,
                  attachmentId,
                  digest: ensureNotFalsy(writeRow.previous)._attachments[attachmentId].digest,
                });
              });
          }
        } else {
          // first check for errors
          Object
            .entries(writeRow.document._attachments)
            .find(([attachmentId, attachmentData]) => {
              const previousAttachmentData = writeRow.previous ? writeRow.previous._attachments[attachmentId] : undefined;
              if (
                !previousAttachmentData &&
                !(attachmentData as RxAttachmentWriteData).data
              ) {
                attachmentError = {
                  documentId: docId,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  documentInDb: documentInDb as any,
                  isError: true,
                  status: 510,
                  writeRow,
                  attachmentId,
                };
              }
              return true;
            });
          if (!attachmentError) {
            Object
              .entries(writeRow.document._attachments)
              .forEach(([attachmentId, attachmentData]) => {
                const previousAttachmentData = writeRow.previous ? writeRow.previous._attachments[attachmentId] : undefined;
                if (!previousAttachmentData) {
                  attachmentsAdd.push({
                    documentId: docId,
                    attachmentId,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    attachmentData: attachmentData as any,
                    digest: defaultHashSha256((attachmentData as RxAttachmentWriteData).data),
                  });
                } else {
                  const newDigest = updatedRow.document._attachments[attachmentId].digest;
                  if (
                    (attachmentData as RxAttachmentWriteData).data &&

                    /**
                     * Performance shortcut,
                     * do not update the attachment data if it did not change.
                     */
                    previousAttachmentData.digest !== newDigest
                  ) {
                    attachmentsUpdate.push({
                      documentId: docId,
                      attachmentId,
                      attachmentData: attachmentData as RxAttachmentWriteData,
                      digest: defaultHashSha256((attachmentData as RxAttachmentWriteData).data),
                    });
                  }
                }
              });
          }
        }
      }

      if (attachmentError) {
        errors[docId] = attachmentError;
      } else {
        bulkUpdateDocs.push(updatedRow);
        if (
          !newestRow ||
          newestRow.document._meta.lwt < updatedRow.document._meta.lwt
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          newestRow = updatedRow as any;
        }
      }

      const writeDoc = writeRow.document;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let eventDocumentData: RxDocumentData<RxDocType> | undefined = null as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let previousEventDocumentData: RxDocumentData<RxDocType> | undefined = null as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let operation: 'INSERT' | 'UPDATE' | 'DELETE' = null as any;

      if (writeRow.previous && writeRow.previous._deleted && !writeDoc._deleted) {
        operation = 'INSERT';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        eventDocumentData = hasAttachments ? stripAttachmentsDataFromDocument(writeDoc) : writeDoc as any;
      } else if (writeRow.previous && !writeRow.previous._deleted && !writeDoc._deleted) {
        operation = 'UPDATE';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        eventDocumentData = hasAttachments ? stripAttachmentsDataFromDocument(writeDoc) : writeDoc as any;
        previousEventDocumentData = writeRow.previous;
      } else if (writeDoc._deleted) {
        operation = 'DELETE';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        eventDocumentData = ensureNotFalsy(writeRow.document) as any;
        previousEventDocumentData = writeRow.previous;
      } else {
        throw newRxError('SNH', { args: { writeRow } });
      }

      const event = {
        eventId: getUniqueDeterministicEventKey(
          eventBulkId,
          rowId,
          docId,
          writeRow,
        ),
        documentId: docId,
        documentData: eventDocumentData as RxDocumentData<RxDocType>,
        previousDocumentData: previousEventDocumentData,
        operation,
        startTime,
        endTime: now(),
      };
      changeByDocId.set(docId, event);
      eventBulk.events.push(event);
    }
  }

  return {
    bulkInsertDocs,
    bulkUpdateDocs,
    newestRow,
    errors,
    changeByDocId,
    eventBulk,
    attachmentsAdd,
    attachmentsRemove,
    attachmentsUpdate,
  };
}

function lastOfArray<T>(ar: T[]): T | undefined {
  return ar[ar.length - 1];
}

function values<RxDocType>(
  index: Index<RxDocType>,
  lowestKey?: RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string,
  highestKey?: RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string,
  reusedArray?: (RxDocumentData<RxDocType>)[],
  skipHighest?: boolean,
): IterableIterator<RxDocumentData<RxDocType>> {
  const it = index.entries(lowestKey, reusedArray);
  return iterator<RxDocumentData<RxDocType>>(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const entry: IteratorResult<any> = it.next();

    if (entry.value) {
      const key = entry.value[0];
      entry.value = entry.value[1];

      if (highestKey && skipHighest && key >= highestKey) {
        // skip highest key
        entry.done = true;
        entry.value = undefined;
      } else if (highestKey && key > highestKey) {
        // include the highest key
        entry.done = true;
        entry.value = undefined;
      }
    }

    return entry;
  });
}

// sorted-btree does not a `valuesReversed` method
function valuesReversed<RxDocType>(
  index: Index<RxDocType>,
  lowestKey?: RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string,
  highestKey?: RxDocumentData<RxDocType>[StringKeys<RxDocType>] | string,
  reusedArray?: (RxDocumentData<RxDocType>)[],
  skipLowest?: boolean,
): IterableIterator<RxDocumentData<RxDocType>> {
  const it = index.entriesReversed(highestKey, reusedArray, false);
  return iterator<RxDocumentData<RxDocType>>(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const entry: IteratorResult<any> = it.next();

    if (entry.value) {
      const key = entry.value[0];
      entry.value = entry.value[1];

      if (lowestKey && skipLowest && key <= lowestKey) {
        // skip highest key
        entry.done = true;
        entry.value = undefined;
      } else if (lowestKey && key < lowestKey) {
        // include the highest key
        entry.done = true;
        entry.value = undefined;
      }
    }

    return entry;
  });
}

function iterator<T>(next: () => IteratorResult<T> = () => ({ done: true, value: undefined })): IterableIterator<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = { next };
  if (Symbol && Symbol.iterator) {
    result[Symbol.iterator] = function () {
      return this;
    };
  }
  return result;
}
