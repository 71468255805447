import {
  type EditableKeys,
  type OverridesForm,
  type UserEvent,
} from '../../../../types';
import { removeTagFromDocObject } from '../../../../utils/tagHelpers';
// eslint-disable-next-line import/no-cycle
import {
  overrideAuthorInDocData,
  overrideCategoryInDocData,
  overrideCoverImageInDocData,
  overrideGeneratedSummaryInDocData,
  overrideLanguageInDocData,
  overridePublishedDateInDocData,
  overrideSummaryInDocData,
  overrideTitleInDocData,
  updateReadingPositionInDocData,
} from '../../../stateUpdateHelpers';
import { createToast } from '../../../toasts.platform';
import {
  type StateUpdateOptionsWithoutEventName,
} from '../../../types';
import { updateDocument } from './update';

export const overrideDocTitle = async (documentId: string, title: EditableKeys['title'], options: StateUpdateOptionsWithoutEventName): Promise<void> => {

  const updateResult = await updateDocument(
    documentId,
    (doc) => {
      overrideTitleInDocData(doc, title);
      return doc;
    }, { ...options, eventName: 'document-title-updated' },
  );

  createToast({
    content: 'Title updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const overrideDocSummary = async (documentId: string, summary: EditableKeys['summary'], options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  const updateResult = await updateDocument(
    documentId,
    (doc) => {
      overrideSummaryInDocData(doc, summary);
      return doc;
    },
    { ...options, eventName: 'document-summary-updated' },
  );

  createToast({
    content: 'Summary updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const overrideLanguage = async (documentId: string, overridesForm: OverridesForm, options: StateUpdateOptionsWithoutEventName): Promise<undefined | UserEvent> => {
  const updateResult = await updateDocument(
    documentId,
    (doc) => {
      overrideLanguageInDocData(doc, overridesForm.language);
      return doc;
    },
    { ...options, eventName: 'document-language-updated' },
  );

  if (!updateResult || !updateResult.userEvent) {
    return;
  }
  createToast({
    content: 'Language updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
  return updateResult.userEvent;
};

export const overrideDocProps = async (documentId: string, overridesForm: OverridesForm, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  const updateResult = await updateDocument(
    documentId,
    (doc) => {
      const overrideTitle = overridesForm.title;
      if (overrideTitle) {
        overrideTitleInDocData(doc, overrideTitle);
      }

      const overrideAuthor = overridesForm.author;
      if (overrideAuthor) {
        overrideAuthorInDocData(doc, overrideAuthor);
      }

      const overrideLanguage = overridesForm.language;
      if (overrideLanguage) {
        overrideLanguageInDocData(doc, overrideLanguage);
      }

      const overrideCategory = overridesForm.category;
      if (overrideCategory) {
        overrideCategoryInDocData(doc, overrideCategory);
      }

      const overrideGeneratedSummary = overridesForm.generated_summary;
      if (overrideGeneratedSummary) {
        overrideGeneratedSummaryInDocData(doc, overrideGeneratedSummary);
      }

      const overrideSummary = overridesForm.summary;
      if (overrideSummary) {
        overrideSummaryInDocData(doc, overrideSummary);
      }

      const overrideCoverUrl = overridesForm.coverImageUrl;
      if (overrideCoverUrl) {
        overrideCoverImageInDocData(doc, overrideCoverUrl);
      }

      const overridePublishedDate = overridesForm.publishedDate;
      if (overridePublishedDate) {
        overridePublishedDateInDocData(doc, overridePublishedDate);
      }

      const tagsToRemove = overridesForm.tagsToRemove;
      if (tagsToRemove) {
        tagsToRemove.forEach((tag) => removeTagFromDocObject(doc, tag));
      }

      if (overridesForm.docProgress !== undefined) {
        const scrollDepth = overridesForm.docProgress === 100 ? 1 : 0;

        const position = {
          scrollDepth,
          serializedPosition: null,
        };

        updateReadingPositionInDocData(doc, position, { force: true });
      }
    },
    { ...options, eventName: 'document-metadata-updated' },
  );

  createToast({
    content: 'Metadata updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};
