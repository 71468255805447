// Safari <= 14.1 doesn't support dates with format YYYY-MM-DD, it needs to be YYYY/MM/DD.
// https://www.linkedin.com/pulse/fix-invalid-date-safari-ie-hatem-ahmad/

export default (date: string | number) => {
  if (typeof date === 'string') {
    return new Date(date.replace(/-/g, '/'));
  }

  return new Date(date);
};
