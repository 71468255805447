/* eslint-disable array-func/prefer-array-from */
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import type { MangoQuery, MangoQueryNoLimit } from 'rxdb';
import type { Subscription } from 'rxjs';

// eslint-disable-next-line restrict-imports/restrict-imports
import { Cache as PersistentQueryCache } from '../../../background/cache.platform';
import { DEFAULT_LIMIT_BUFFER } from '../../../constants.platform';
import optimizeMangoQuery from '../../../database/internals/optimizeMangoQuery';
import type {
  CommonDatabaseHookOptions,
  DatabaseCollectionNames,
  DatabaseCollectionNamesToDocType,
  InfiniteDatabaseHookResultArray,
  InfiniteDatabaseHookResultObject,
} from '../../../types/database';
import makeLogger from '../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import database from '../../database';
import { useDisablePersistentQueryCache } from '../../models';
import { useDeepEqualMemo } from '../../utils/useDeepEqualMemo';
import useLiveValueRef from '../../utils/useLiveValueRef';
import convertQueryResultToArray from './convertQueryResultToArray';
import getRxCollection from './getRxCollection';
import { useNumberQuerySubscription } from './subscriptionHooks';
import useOptions from './useOptions';

/*
  We need to be extra careful about reactivity in this hook. This performance-critical. There are tests which check how
  often this hook re-renders, which helps a lot.

  This is partly why we use a reducer (plus it's easier to reason about).
*/

type State<
  TCollectionName extends DatabaseCollectionNames,
  TReturnedItemType extends DatabaseCollectionNamesToDocType[TCollectionName],
> = {
  allItemsRetrieved: TReturnedItemType[];
  deepestPageIndex: number;
  hasReachedEnd: boolean;
  isFetchingNextPage: boolean;
  numberOfTimesQueryHasChanged: number;
  numberOfTimesResetWasCalled: number;
  pageSize: number;
  totalCount: number | null;
  // This gets enabled after the first pagesUpdated call
  isTotalCountQueryEnabled: boolean;
};

type StateAction<
  TCollectionName extends DatabaseCollectionNames,
  TReturnedItemType extends DatabaseCollectionNamesToDocType[TCollectionName],
> =
  | {
    name: 'fetchingNextPage';
  }
  | {
    name: 'pagesUpdated';
    allItemsRetrieved: TReturnedItemType[];
    deepestIndexUpdated: number;
  }
  | {
    name: 'queryChanged';
    updates?: Partial<State<TCollectionName, TReturnedItemType>>;
  }
  | {
    name: 'reset';
    isEnabled: boolean;
    updates?: Partial<State<TCollectionName, TReturnedItemType>>;
  }
  | {
    name: 'totalCountUpdated';
    totalCount: number;
  };

const logger = makeLogger(__filename);

function addPagination<TDocType>(
  mangoQuery: MangoQueryNoLimit<TDocType>,
  skip: number | undefined,
  limit: number,
): MangoQuery<TDocType> {
  const result: MangoQuery<TDocType> = {
    ...mangoQuery,
    limit,
  };
  if (typeof skip === 'number') {
    result.skip = skip;
  }
  return result;
}

function getInitialState<
  TCollectionName extends DatabaseCollectionNames,
  TReturnedItemType extends DatabaseCollectionNamesToDocType[TCollectionName],
>(
  {
    isEnabled,
    pageSize,
  }: {
    isEnabled: boolean;
  } & Pick<State<TCollectionName, TReturnedItemType>, 'pageSize'>,
): State<TCollectionName, TReturnedItemType> {
  return {
    allItemsRetrieved: [],
    deepestPageIndex: 0,
    hasReachedEnd: !isEnabled,
    isFetchingNextPage: isEnabled,
    numberOfTimesQueryHasChanged: 0,
    numberOfTimesResetWasCalled: 0,
    pageSize,
    isTotalCountQueryEnabled: false,
    totalCount: null,
  };
}

function getTotalPageCount(totalAmount: number, pageSize: number): number {
  return Math.ceil(totalAmount / pageSize);
}

export function useFindInfinite<
  TCollectionName extends DatabaseCollectionNames = DatabaseCollectionNames,
  TReturnedItemType extends DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[
    TCollectionName
  ],
>(
  collectionName: TCollectionName,
  query?: MangoQueryNoLimit<DatabaseCollectionNamesToDocType[TCollectionName]>,
  optionsArgument: CommonDatabaseHookOptions & {
    pageSize?: number;
    queryForCount?: MangoQuery<DatabaseCollectionNamesToDocType[TCollectionName]>;
    areSubscriptionsPaused?: boolean;
  } = {},
): InfiniteDatabaseHookResultArray<TReturnedItemType[]> {

  const [memoizedQuery, setMemoizedQuery] = useState<typeof query>(query);

  useEffect(() => {
    if (isEqual(memoizedQuery, query)) {
      return;
    }
    setMemoizedQuery(query);
  }, [memoizedQuery, query]);

  const options = useOptions(optionsArgument, !memoizedQuery);

  const {
    isEnabled = true,
    pageSize = 10,
  } = options;

  const isEnabledRef = useLiveValueRef(isEnabled);

  const initialState = useMemo(() => getInitialState<TCollectionName, TReturnedItemType>({
    isEnabled,
    pageSize,
  }), [isEnabled, pageSize]);

  const disablePersistentQueryCache = useDisablePersistentQueryCache();

  const reducer = useCallback((
    state: State<TCollectionName, TReturnedItemType>,
    action: StateAction<TCollectionName, TReturnedItemType>,
  ): State<TCollectionName, TReturnedItemType> => {
    logger.debug(`useFindInfinite reducer action: ${action.name}`, logger.shouldLog ? { action: { ...action }, currentState: { ...state } } : {});

    let nextState: State<TCollectionName, TReturnedItemType> | null = null;
    if (action.name === 'fetchingNextPage') {
      nextState = {
        ...state,
        deepestPageIndex: state.allItemsRetrieved.length
          ? getTotalPageCount(state.allItemsRetrieved.length, state.pageSize)
          : 1,
        isFetchingNextPage: true,
      };
    } else if (action.name === 'pagesUpdated') {
      nextState = {
        ...state,
        isTotalCountQueryEnabled: true,
        allItemsRetrieved: isEqual(action.allItemsRetrieved, state.allItemsRetrieved)
          ? state.allItemsRetrieved
          : action.allItemsRetrieved,
      };
      if (
        // If we got fewer than a page worth of items, we've hit the end, whether or not the count memoizedQuery has finished
        action.allItemsRetrieved.length < state.pageSize ||
        // Or if we've gotten more than the total count
        nextState.totalCount && nextState.allItemsRetrieved.length >= nextState.totalCount
      ) {
        nextState.hasReachedEnd = true;
        nextState.isFetchingNextPage = false;
      } else if (
        // If the deepest index requested has now been fetched, we're no longer fetching the next page
        action.deepestIndexUpdated >= state.deepestPageIndex
      ) {
        nextState.isFetchingNextPage = false;
      }
    } else if (action.name === 'queryChanged') {
      nextState = {
        ...state,
        ...action.updates,
        numberOfTimesQueryHasChanged: state.numberOfTimesQueryHasChanged + 1,
      };
    } else if (action.name === 'reset') {
      nextState = {
        ...getInitialState<TCollectionName, TReturnedItemType>({
          isEnabled: action.isEnabled,
          pageSize: state.pageSize,
        }),
        ...action.updates,
        numberOfTimesResetWasCalled: state.numberOfTimesResetWasCalled + 1,
      };
    } else if (action.name === 'totalCountUpdated') {
      const totalPageCount = getTotalPageCount(action.totalCount, state.pageSize);
      const deepestPageIndex = Math.max(Math.min(totalPageCount - 1, state.deepestPageIndex), 0);
      nextState = {
        ...state,
        deepestPageIndex,
        // We've reached the end if the items already fetched satisfy the page count
        hasReachedEnd: action.totalCount !== null && state.allItemsRetrieved.length >= action.totalCount,
        totalCount: action.totalCount,
      };
      if (nextState.hasReachedEnd) {
        nextState.isFetchingNextPage = false;
      }
    }
    if (nextState !== null) {
      logger.debug(`action completed ${action.name}`, logger.shouldLog ? { nextState: { ...nextState } } : {});
      return nextState;
    }

    throw new Error('unknown action');
  }, []);

  const [state, dispatch] = useReducer<
    Reducer<
      State<TCollectionName, TReturnedItemType>,
      StateAction<TCollectionName, TReturnedItemType>
    >
  >(
    reducer,
    initialState,
  );

  const stateRef = useLiveValueRef(state);
  const rxCollection = useMemo(() => getRxCollection(database, collectionName), [collectionName]);

  const queryForCount = useDeepEqualMemo<typeof options['queryForCount']>(options.queryForCount);

  const [totalCount, totalCountResultObject] = useNumberQuerySubscription<TCollectionName, number | null>(
    useMemo(() => {
      const queryToUse = queryForCount ?? memoizedQuery;
      // NOTE: we don't omit sort fields from query (even though it's not useful for a count).
      // Omitting the sort runs makes `optimizeMangoQuery` add a bunch of unused sort fields,
      // which messes with efficient index selection.
      const rxQuery = state.isTotalCountQueryEnabled
          ? rxCollection.count(optimizeMangoQuery(rxCollection, omit(queryToUse, 'limit')))
          : undefined;

      return {
        isEnabled: state.isTotalCountQueryEnabled && !options.areSubscriptionsPaused,
        initialDataValue: null,
        queryStringForChangeDetection: JSON.stringify(queryToUse) + state.numberOfTimesQueryHasChanged +
          state.numberOfTimesResetWasCalled,
        // https://linear.app/readwise/issue/RW-32333/database-hooks-even-when-isenabled-false-an-entry-is-put-in-rxdbs
        rxQuery,
      };
    }, [queryForCount, memoizedQuery, state.isTotalCountQueryEnabled, state.numberOfTimesQueryHasChanged, state.numberOfTimesResetWasCalled, rxCollection, options.areSubscriptionsPaused]),
  );

  useEffect(() => {
    if (!isEnabledRef.current || totalCount === null || totalCount === stateRef.current.totalCount || totalCountResultObject.isFetching || !state.isTotalCountQueryEnabled) {
      return;
    }
    dispatch({
      name: 'totalCountUpdated',
      totalCount,
    });
  }, [dispatch, isEnabledRef, state.isTotalCountQueryEnabled, stateRef, totalCount, totalCountResultObject]);

  const queryPage = useCallback(function queryPage({
    index,
    onResult,
  }: {
    index: number;
    onResult: (data: {
      index: number;
      items: TReturnedItemType[];
    }) => void;
  }): Subscription | undefined {
    // This is only needed for TypeScript; if `memoizedQuery` is missing, `isEnabled` will be false thanks to `useOptions`
    if (!memoizedQuery) {
      return;
    }

    const paginatedQuery = addPagination(
      memoizedQuery,
      index * state.pageSize,
      state.pageSize,
    );

    let rxQuery = rxCollection.find(optimizeMangoQuery(rxCollection, paginatedQuery));

    const queryString = JSON.stringify(rxQuery?.mangoQuery ?? '');
    if (!disablePersistentQueryCache && !paginatedQuery.skip && !queryString.includes('showInUnseenAfter') && !queryString.includes('showInSeenAfter')) {
      rxQuery.enablePersistentQueryCache(PersistentQueryCache);
    }

    if (paginatedQuery.limit && !paginatedQuery.skip) {
      rxQuery = rxQuery.enableLimitBuffer(DEFAULT_LIMIT_BUFFER);
    }

    return rxQuery.$
      .subscribe((queryResult) => {
        onResult({
          index,
          items: convertQueryResultToArray(queryResult, rxCollection) as TReturnedItemType[],
        });
      });
  }, [disablePersistentQueryCache, memoizedQuery, rxCollection, state.pageSize]);

  const deepestPageIndexMemoized = useMemo(() => state.deepestPageIndex, [state.deepestPageIndex]);

  useEffect(() => {
    if (!isEnabled || options.areSubscriptionsPaused) {
      return;
    }

    const pagesToItemsMap = new Map<number, TReturnedItemType[]>();

    const updateState = debounce(() => {
      if (!isEnabledRef.current) {
        return;
      }
      const allItemsRetrieved: TReturnedItemType[] = [];
      let deepestIndexUpdated: number | null = null;

      /*
        Convert the map into an array, but if there is a missing page, ignore any following pages.

        E.g. imagine we start fetching the first page and `fetchMore` is immediately called so but that second page data
        arrives first. We don't want to return just the second pages results upstream or even worse, have one pages'
        results overwrite the others.

        To be clear, we're still running the queries. We're just not returning results from a page if any previous
        page's data hasn't been fetched.

        We're also not throwing away data; it's kept in the map and once the preceeding page(s) is ready, we include
        the preceeding page(s) and the overly-fast page in the results.
      */
      for (let pageIndex = 0; pagesToItemsMap.has(pageIndex); pageIndex++) {
        allItemsRetrieved.push(
          // This should exist (we used `.has` above) but TypeScript is saying it could be undefined
          ...pagesToItemsMap.get(pageIndex) ?? [],
        );
        deepestIndexUpdated = pageIndex;
      }
      if (deepestIndexUpdated === null) {
        // We should actually ignore these updates for now; e.g. the second page was fetched before the first
        return;
      }
      dispatch({
        name: 'pagesUpdated',
        allItemsRetrieved,
        deepestIndexUpdated,
      });
    }, 15, { leading: false, trailing: true });

    const onResult = ({
      index,
      items,
    }: {
      index: number;
      items: TReturnedItemType[];
    }) => {
      if (!isEnabledRef.current) {
        return;
      }
      pagesToItemsMap.set(index, items);
      updateState();
    };

    const subscriptions: Subscription[] = [];
    for (let i = 0; i <= deepestPageIndexMemoized; i++) {
      const subscription = queryPage({
        index: i,
        onResult,
      });
      if (subscription) {
        subscriptions.push(subscription);
      }
    }

    return () => {
      updateState.cancel();
      for (const subscription of subscriptions) {
        subscription.unsubscribe();
      }
    };
  }, [deepestPageIndexMemoized, isEnabled, isEnabledRef, queryPage, stateRef, options.areSubscriptionsPaused]);

  // Reset isFetching when query, etc. changes. There is a separate useEffect below to reset when isEnabled becomes false
  const didQueryChangeEffectRunRef = useRef(false);
  useEffect(() => {
    const isFirstTime = !didQueryChangeEffectRunRef.current;
    if (isFirstTime) {
      didQueryChangeEffectRunRef.current = true;
      return;
    }
    didQueryChangeEffectRunRef.current = true;
    dispatch({
      name: 'queryChanged',
      updates: {
        pageSize,
      },
    });
  }, [didQueryChangeEffectRunRef, dispatch, isEnabledRef, pageSize, query]);

  // Reset when isEnabled becomes false
  const didIsEnabledChangeUseEffectRun = useRef(false);
  useEffect(() => {
    const isFirstTime = !didIsEnabledChangeUseEffectRun.current;
    didIsEnabledChangeUseEffectRun.current = true;
    if (isFirstTime || isEnabled) {
      return;
    }
    dispatch({
      name: 'reset',
      isEnabled,
    });
  }, [didIsEnabledChangeUseEffectRun, dispatch, isEnabled]);

  const requestFetchMore = useCallback(() => {
    if (!isEnabledRef.current || stateRef.current.hasReachedEnd) {
      return;
    }
    dispatch({
      name: 'fetchingNextPage',
    });
  }, [dispatch, isEnabledRef, stateRef]);

  const requestReset = useCallback(() => {
    dispatch({
      name: 'reset',
      isEnabled: isEnabledRef.current,
    });
  }, [dispatch, isEnabledRef]);

  return useMemo(
    (): InfiniteDatabaseHookResultArray<TReturnedItemType[]> => {
      const resultObject: InfiniteDatabaseHookResultObject<TReturnedItemType[]> = {
        data: state.allItemsRetrieved,
        fetchMore: requestFetchMore,
        hasReachedEnd: state.hasReachedEnd,
        isFetching: state.isFetchingNextPage,
        isFetchingInitialInput: state.isFetchingNextPage && state.numberOfTimesQueryHasChanged === 0,
        isFetchingUpdatedInput: state.isFetchingNextPage && state.numberOfTimesQueryHasChanged > 0,
        reset: requestReset,
        totalCount: state.totalCount,
      };
      return [resultObject.data, resultObject];
    },
    [
      requestFetchMore,
      requestReset,
      state.allItemsRetrieved,
      state.hasReachedEnd,
      state.isFetchingNextPage,
      state.numberOfTimesQueryHasChanged,
      state.totalCount,
    ],
  );
}
