import { ArrayWithAtLeastOneItem } from './typescriptUtils';

export default function urlJoin(urls: ArrayWithAtLeastOneItem<(string | null | undefined)>): string {
  const nonEmptyUrls = urls.filter(Boolean);

  if (!nonEmptyUrls.length) {
    throw new Error('No non-empty URLs given');
  }

  return nonEmptyUrls
    .join('/')

    /*
      Replace multiple slashes with a single one, unless preceeded by `:`.
      .replace(/(?<!:)\/+/g, '/') isn't supported in Safari so we'll do this:
    */
    .replace(/:\/{3,}/g, '://') // e.g. ":///" -> "://"
    .split(/:\/\//g) // Split by "://" to preserve them
    .map((piece) => piece.replace(/\/{2,}/g, '/')) // "//" -> "/" in the split pieces
    .join('://'); // Put back in the "://" instances
}
