import pick from 'lodash/pick';

import type { RxDBInstance } from '../../../types/database';
import { isExtension } from '../../../utils/environment';
import logger from '../logger';
import documents from './documents';
import global_tags from './global_tags';
import state_syncing_key_value_pairs from './state_syncing_key_value_pairs';
import state_syncing_updates from './state_syncing_updates';

// If you're updating this, also update the DatabaseCollectionNamesToDocType type
export const schema = {
  ...documents.getSchema(),
  ...global_tags.getSchema(),
  ...state_syncing_key_value_pairs.getSchema(),
  ...state_syncing_updates.getSchema(),
};

export default async function defineSchema(database: RxDBInstance, isInForeground?: boolean) {
  await database.addCollections(isExtension && isInForeground ? pick(schema, ['documents', 'global_tags']) : schema);
  logger.debug('Schema created');
}
