import isObject from 'lodash/isObject';

import logger from './logger';

export default async function handleValidationErrorsCleanly<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  executeQuery: (...args: any[]) => Promise<T>,
): Promise<T> {
  function log(errors: Error[]) {
    logger.error([
      'Validation failed!',
      'This could be caused by some client-side code or old/bad data in your Postgres database.',
      'We have a snippet you can run to fix the data in your database. How you run it depends on the backend you are pointing at;',
      '- Local backend via Docker: run `make shell` at the root of the project, copy-paste the contents of `snippets/fix_validation_errors_for_all_docs.py`, and press enter.',
      '- Local backend, no Docker: run `python snippets/fix_validation_errors_for_all_docs.py` from the root of the project.',
      '- Production: *carefully* run `snippets/fix_validation_errors_for_all_docs.py` against the account in question.',
      'Note: some schemas have `allOf` which contains business logic validation rules.',
      '',
      JSON.stringify(errors, null, 2),
      '',
    ].join('\n\n'), {
      executeQuery,
      stack: new Error('Dummy error to get stack').stack,
    });
  }

  let result: Awaited<ReturnType<typeof executeQuery>>;
  try {
    result = await executeQuery();
    if (isObject(result) && 'error' in result) {
      const errors = result.error as Error[] | undefined;
      if (errors?.length) {
        log(errors);
      }
    }
    return result;
  } catch (error) {
    if (error instanceof Error && error.message.includes('RxError (VD2)')) {
      log([error]);
    }
    throw error;
  }
}
