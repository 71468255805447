import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import type { SettingsState } from '../../types';
import { DocumentLocation } from '../../types';
import validDocumentLocationsSettingValues from '../validDocumentLocationsSettingValues';

export default function fixBadDocumentLocationsValue (
  input: SettingsState['documentLocations'],
): SettingsState['documentLocations'] {
  // Remove duplicates (this has happened)
  let results = uniq(input);

  /*
    If the order is incorrect or the value is just invalid, then return the valid one with shortlist, unless their
    setting value contains inbox.
  */
  if (!validDocumentLocationsSettingValues.find((validConfiguration) => isEqual(validConfiguration, results))) {
    const importantDocumentLocation = results.length && results.includes(DocumentLocation.New)
      ? DocumentLocation.New
      : DocumentLocation.Shortlist;

    const closestValidValue = validDocumentLocationsSettingValues
      .find((configuration) => configuration.includes(importantDocumentLocation));

    // To make TypeScript happy...
    if (!closestValidValue) {
      throw new Error("Can't find closest valid documentLocations value from their broken one");
    }
    results = closestValidValue;
  }

  return results;
}
