import * as jsonpatch from 'readwise-fast-json-patch';

import type Database from '../../database/Database';
import updateDocumentsInDatabaseUsingJsonPatchOperations
  from '../../database/updateDocumentsInDatabaseUsingJsonPatchOperations';
import type { FullZustandState, UserEvent, UserInteractionName } from '../../types';
import cloneDeep from '../../utils/cloneDeep';
import groupPatchOperationsByStorage from '../../utils/groupPatchOperationsByStorage';
// eslint-disable-next-line import/no-cycle
import { setAreServerChangesBeingAppliedToForeground } from '../methods';
import type { UpdateStateType } from '../models';

export default async function updateAllStateUsingJsonPatchOperations({
  canModifyOperations,
  database,
  extraStateChanger,
  updateState,
  operations: allOperations, // it's renamed so it's more obvious if you accidentally use it
  ...otherUpdateOptions
}: {
  canModifyOperations: boolean; // I.e. can we avoid deep cloning (which would better for performance)?
  correlationId?: UserEvent['correlationId'];
  database: Database;
  extraStateChanger?: (state: FullZustandState) => void;
  eventName: string;
  isUndoable?: boolean;
  operations: jsonpatch.Operation[];
  shouldNotSendPersistentChangesToServer?: boolean;
  updateState: UpdateStateType;
  userInteraction: UserInteractionName | null;
}): Promise<void> {
  // We need to split the operations into two arrays; Zustand and RxDB
  const {
    operationsForRxDb,
    operationsForZustand,
  } = groupPatchOperationsByStorage({ operations: allOperations });
  const commonUpdateOptions = otherUpdateOptions;

  if (operationsForRxDb.length) {
    await updateDocumentsInDatabaseUsingJsonPatchOperations({
      ...commonUpdateOptions,
      canModifyOperations,
      database,
      operations: operationsForRxDb,
    });
  }

  setAreServerChangesBeingAppliedToForeground(false);

  if (operationsForZustand.length || extraStateChanger) {
    await updateState((state) => {
      if (operationsForZustand.length) {
        const patchClone = canModifyOperations ? operationsForZustand : cloneDeep(operationsForZustand);
        jsonpatch.applyPatch(state.persistent, patchClone);
      }
      extraStateChanger?.(state);
    }, commonUpdateOptions);
  }
}
