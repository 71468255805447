import onNextEvent from './onNextEvent';

export default function onNextUserInteraction(): Promise<void> {
  return onNextEvent(document.body, [
    'click',
    'keydown',
    'mousemove',
    'scroll',
  ]);
}
