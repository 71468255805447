/*
  This file is for state updaters related to the DocumentContent type, not just any document content related stuff, e.g.
  fonts.
*/

import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import uniq from 'lodash/uniq';

import {
  AnyDocument,
  BaseDocument,
  ContentParsingStatus,
  ContentRequestLoadingStatus,
  DocumentContent,
  DocumentWithOptionalTransientData,
  FirstClassDocument,
  FullZustandState,
  TransientDocumentData,
} from '../../../types';
import { isFirstClassDocument, notEmpty } from '../../../typeValidators';
import exceptionHandler from '../../../utils/exceptionHandler.platform';
import makeLogger from '../../../utils/makeLogger';
import createInitialTransientDocumentData from '../../createInitialTransientDocumentData';
// eslint-disable-next-line import/no-cycle
import database from '../../database';
// eslint-disable-next-line import/no-cycle
import { CancelStateUpdate, globalState, updateState } from '../../models';
import background, { portalGate as backgroundPortalGate } from '../../portalGates/toBackground/singleProcess';
import { StoreItemEventCallback } from '../../types/events';
import { documentShouldForceContentLoad } from '../../utils/documentShouldForceContentLoad';

const logger = makeLogger(__filename);

type ParsedDocIdToDocumentIdsMap = {[parsedDocId: DocumentContent['id']]: AnyDocument['id'][];};
type DocumentContentWithDocumentIds = [DocumentContent, AnyDocument['id'][]];
const onDocumentContentItemsLoadedForState = async (items: DocumentContentWithDocumentIds[]) => {
  if (!items.length) {
    return;
  }

  const currentState = globalState.getState();

  let willChangeState = false;
  const changedTransientDocumentsData: FullZustandState['transientDocumentsData'] = {};

  const documentIdsToReload: AnyDocument['id'][] = [];

  for (const [documentContent, documentIds] of items) {
    if (!documentIds) {
      const message = 'No documents related to parsed doc ID. Maybe user deleted the doc as the content was loading?';
      logger.error(message, { documentContent, items, currentState });
      exceptionHandler.captureException(message, { extra: { documentContent, items, currentState } });
      continue;
    }
    for (const docId of documentIds) {
      const currentTransientData = currentState.transientDocumentsData[docId];
      const newTransientData = {
        ...currentTransientData || createInitialTransientDocumentData(),
        contentRequestLoadingStatus: ContentRequestLoadingStatus.Loaded,
        contentParsingStatus: documentContent.status,
      };

      if (documentContent.status === ContentParsingStatus.Success) {
        newTransientData.content = documentContent.html;
        if (!newTransientData.tts) {
          newTransientData.tts = {};
        }
        newTransientData.tts = documentContent.tts;
        newTransientData.ttsParsingStatus = documentContent.tts_status;
      }

      if (!isEqual(currentTransientData, newTransientData)) {
        changedTransientDocumentsData[docId] = newTransientData;
        willChangeState = true;
      }
    }
    if (documentContent.status === ContentParsingStatus.Pending || documentContent.status === ContentParsingStatus.ServerTaskNotStartedYet) {
      documentIdsToReload.push(...documentIds);
    }
  }

  if (willChangeState) {
    await updateState((state) => {
      const newTransientDocumentsData = {
        ...state.transientDocumentsData,
        ...changedTransientDocumentsData,
      };
      if (isEqual(state.transientDocumentsData, newTransientDocumentsData)) {
        throw new CancelStateUpdate();
      }
      state.transientDocumentsData = newTransientDocumentsData;
      state.haveSomeDocumentContentItemsLoaded = true;
    }, {
      eventName: 'document-content-updated',
      shouldCreateUserEvent: false,
      isUndoable: false,
      userInteraction: null,
    });
  }

  if (!documentIdsToReload.length) {
    return;
  }

  setTimeout(() => fetchDocumentContent(documentIdsToReload, true), 1000);
};


const loadDocumentContentAndAddToState = async (parsedDocIdToDocumentIdMap: ParsedDocIdToDocumentIdsMap, shouldBypassCache: boolean) => {
  const parsedDocIds = Object.keys(parsedDocIdToDocumentIdMap);
  if (!parsedDocIds.length) {
    return;
  }

  /*
    Track which IDs we want to add to the state. We could've introduced an
    `item-loaded-${id}` event, which would've been simpler in a way, but one
    state update with N items is better than N state updates with one item.
  */
  const expectedIds = parsedDocIds;
  const onItemsLoaded = (async (message: DocumentContent[] | { foundItems: DocumentContent[]; missingIds: string[]; }) => {
    // We are listening to two types of signals, so get the data from each format
    const foundItems = Array.isArray(message) ? message : message.foundItems;

    const itemsLoaded = foundItems.filter(notEmpty).filter((item) => expectedIds.includes(item.id));
    if (!itemsLoaded.length) {
      return;
    }
    const documentContentAndDocumentIds: DocumentContentWithDocumentIds[] = itemsLoaded.map(
      (documentContent) => [documentContent, parsedDocIdToDocumentIdMap[documentContent.id]],
    );
    // Add to state
    await onDocumentContentItemsLoadedForState(documentContentAndDocumentIds);

    // Update list of tracked IDs and stop listening if we're done
    for (const item of itemsLoaded) {
      expectedIds.splice(expectedIds.findIndex((id) => id === item.id), 1);
    }
    if (!expectedIds.length) {
      backgroundPortalGate.off('stores__documentContent__items-received-by-id', onItemsLoaded);
      backgroundPortalGate.off('stores__documentContent__items-received-from-server', onItemsLoaded);
    }
  }) as StoreItemEventCallback;
  backgroundPortalGate.on('stores__documentContent__items-received-by-id', onItemsLoaded);
  backgroundPortalGate.on('stores__documentContent__items-received-from-server', onItemsLoaded);

  if (!parsedDocIds.length) {
    return;
  }

  return background.loadDocumentContentByIds(parsedDocIds, shouldBypassCache);
};

let documentIdsToPreloadContentFor: BaseDocument['id'][] = [];
const fetchDocumentContentThrottled = throttle(async (shouldFetchEvenIfAlreadyLoaded?: boolean): Promise<void> => {
  if (!documentIdsToPreloadContentFor.length) {
    return;
  }
  const state = globalState.getState();

  const docsToLoad: DocumentWithOptionalTransientData<FirstClassDocument>[] = [];
  const docIdsToFind = documentIdsToPreloadContentFor;
  documentIdsToPreloadContentFor = []; // Reset batch / queue
  let docs: AnyDocument[] = [];
  try {
    docs = await database.collections.documents.findByIds(docIdsToFind);
  } catch (error) {
    exceptionHandler.captureException(error, {
      extra: {
        docIdsToFind,
        documentIdsToPreloadContentFor,
      },
    });
    // re-add failed document IDs so that we retry them on the next fetchDocumentContentThrottled().
    documentIdsToPreloadContentFor.push(...docIdsToFind);
  }
  for (const doc of docs) {
    // `continue` below = don't bother loading

    if (!isFirstClassDocument(doc)) {
      continue;
    }

    const transientDocumentData = state.transientDocumentsData[doc.id] as TransientDocumentData | undefined;
    if (
      transientDocumentData?.contentRequestLoadingStatus === ContentRequestLoadingStatus.Loading ||
      !doc.parsed_doc_id // Docs may not have their parsed_doc_id synced yet. Wait until they do.
    ) {
      // Docs may not have their parsed_doc_id synced yet. Wait until they do.
      continue;
    }

    const forceLoadedContent = documentShouldForceContentLoad({ ...doc, transientData: transientDocumentData }) || shouldFetchEvenIfAlreadyLoaded;
    // Sometimes we want to force load again, e.g. they're viewing the document and the parsing isn't complete (so it's refetched periodically)
    if (transientDocumentData?.contentRequestLoadingStatus === ContentRequestLoadingStatus.Loaded && !forceLoadedContent) {
      continue;
    }
    docsToLoad.push(doc);
  }

  if (!docsToLoad.length) {
    return;
  }

  const getLoadingStatusUpdateFunction = (loadingStatus: ContentRequestLoadingStatus): (state: FullZustandState) => void => {
    return (state) => {
      for (const docToLoad of docsToLoad) {
        state.transientDocumentsData[docToLoad.id] = {
          ...state.transientDocumentsData[docToLoad.id] || createInitialTransientDocumentData(),
          contentRequestLoadingStatus: loadingStatus,
        };
      }
    };
  };

  const stateUpdateOptions = {
    eventName: 'document-content-loading-status-updated',
    shouldCreateUserEvent: false,
    isUndoable: false,
    userInteraction: null,
  };

  updateState(getLoadingStatusUpdateFunction(ContentRequestLoadingStatus.Loading), stateUpdateOptions);

  try {
    const parsedDocIdsToDocumentIdsToLoad: ParsedDocIdToDocumentIdsMap = {};
    const parsedDocIdsToDocumentIdsToForcefullyLoad: ParsedDocIdToDocumentIdsMap = {};
    for (const doc of docsToLoad) {
      const parsedDocId = doc.parsed_doc_id?.toString();
      if (parsedDocId) {
        if (documentShouldForceContentLoad(doc) || shouldFetchEvenIfAlreadyLoaded) {
          parsedDocIdsToDocumentIdsToForcefullyLoad[parsedDocId] ??= [];
          parsedDocIdsToDocumentIdsToForcefullyLoad[parsedDocId].push(doc.id);
        } else {
          parsedDocIdsToDocumentIdsToLoad[parsedDocId] ??= [];
          parsedDocIdsToDocumentIdsToLoad[parsedDocId].push(doc.id);
        }
      }
    }
    if (Object.keys(parsedDocIdsToDocumentIdsToForcefullyLoad).length || Object.keys(parsedDocIdsToDocumentIdsToLoad).length) {
      const loadPromises: ReturnType<typeof loadDocumentContentAndAddToState>[] = [];
      if (Object.keys(parsedDocIdsToDocumentIdsToForcefullyLoad).length) {
        loadPromises.push(loadDocumentContentAndAddToState(parsedDocIdsToDocumentIdsToForcefullyLoad, true));
      }
      if (Object.keys(parsedDocIdsToDocumentIdsToLoad).length) {
        loadPromises.push(loadDocumentContentAndAddToState(parsedDocIdsToDocumentIdsToLoad, false));
      }
      await Promise.all(loadPromises);
    } else {
      logger.warn('fetchDocumentContentThrottled: of the IDs requested, none of the documents (in state) have `parsed_doc_id`');
    }
  } catch (error) {
    updateState(getLoadingStatusUpdateFunction(ContentRequestLoadingStatus.Failed), stateUpdateOptions);
    throw error;
  }
}, 200);

// We combine calls in order to send fewer requests
export const fetchDocumentContent = async (docIds: string[], shouldFetchEvenIfAlreadyLoaded?: boolean): Promise<void> => {
  documentIdsToPreloadContentFor = uniq([
    ...documentIdsToPreloadContentFor,
    ...docIds,
  ]);
  // TODO: this shouldFetchEvenIfAlreadyLoaded param doesn't really work.
  //  For example, if it is passed as true, another debounced call to fetchDocumentContent will often happen with the
  //  param false immediately after. In this case, fetchDocumentContentThrottled will never fetchEvenIfAlreadyLoaded
  //  and we'll never actually fetch the content we need to
  await fetchDocumentContentThrottled(shouldFetchEvenIfAlreadyLoaded);
};
