import userAgentParser from 'ua-parser-js';

import { AppVersion } from '../types';

// navigator does not exist in node-based execution environments
const userAgentInfo = typeof navigator !== 'undefined' ? userAgentParser(navigator.userAgent) : undefined;
const { name, version } = userAgentInfo?.os ?? {};

export const systemName = name;
export const systemVersion = version;
export const appVersion: AppVersion = `unknown`;

export const logLevelPreference = process.env.READER_LOG_LEVEL;

export const pdftronAssetsDirectoryName = process.env.VITE_PDFTRON_ASSETS_DIRECTORY_NAME;

export const isRxDBKeyCompressionDisabled = Boolean(process.env.IS_RXDB_KEY_COMPRESSION_DISABLED);
// Force the in-memory RxDB storage to run locally i.e. not in a Shared Worker
// NOTE: Since all queries are run in the UI thread, an excessive DB load might freeze up the app.
// DO NOT EXTRAPOLATE and assume this is the case in production as well. In production, the DB runs in a separate thread.
export const shouldRunRxDBInMemoryStorageLocally = Boolean(process.env.SHOULD_RUN_RXDB_IN_MEMORY_STORAGE_LOCALLY);

export const shouldPrintSqlQueryPlans = false; // N/A for web
