import React from 'react';

import styles from './Spinner.module.css';


export default function Spinner({ className }: { className?: string; }) {
  const classes = ['spinner', styles.spinner, className];
  return (
    <div className={classes.filter(Boolean).join(' ')}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
