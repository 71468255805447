// eslint-disable-next-line import/no-cycle
import type { MangoQuery, MangoQuerySelector } from 'rxdb';

import {
  AnyDocument,
  BaseDocument,
  DocumentLocation,
  DocumentWithTransientData,
  FeedDocumentLocation,
  FilteredView,
  FullZustandState,
  Highlight,
  Note,
  PartialDocument,
  RssFeed,
  SortRule,
} from '../types';
import { isExtension, isMobile, isWebApp } from '../utils/environment';
import makeLogger from '../utils/makeLogger';
import createInitialTransientDocumentData from './createInitialTransientDocumentData';
// eslint-disable-next-line import/no-cycle
import database from './database';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_DOCUMENT_SORT_RULES, globalState } from './models';
import fixBadDocumentLocationsValue from './utils/fixBadDocumentLocationsValue';
import getSortActiveViewName from './utils/getSortActiveViewName';

const logger = makeLogger(__filename);

export const getCurrentSortRule = ({
  filterByFeedDocumentLocation,
  filterByDocumentLocation,
  listId,
  sortRules,
  sortRulesKey,
  state = globalState.getState(),
}: {
  filterByFeedDocumentLocation?: FeedDocumentLocation;
  filterByDocumentLocation?: DocumentLocation;
  listId?: string;
  sortRules?: SortRule[];
  sortRulesKey?: string;
  state?: FullZustandState;
}): SortRule => {
  const rulesMap = DEFAULT_DOCUMENT_SORT_RULES;
  const defaultRules = rulesMap[DocumentLocation.New];

  if (sortRules?.length) {
    return sortRules[0];
  }

  let rules: SortRule[];
  if (listId && state.client.listSortRules && state.client.listSortRules[listId]) {
    rules = state.client.listSortRules[listId];
  } else {
    const sortKey = getSortActiveViewName({
      activeDocumentLocation: filterByFeedDocumentLocation ?? filterByDocumentLocation,
      documentLocations: fixBadDocumentLocationsValue(state.persistent.settings.documentLocations),
      isFeedView: Boolean(filterByFeedDocumentLocation),
    });
    rules = rulesMap[sortRulesKey ?? sortKey as string];
  }
  const currentRules = rules ?? defaultRules;
  return currentRules[0];
};

// Note: if document has no transientData, we attach a new transientData but don't save it to our Zustand state.
export async function getDocumentWithTransientData<T extends AnyDocument>(
  id: BaseDocument['id'] | null | void,
  state: FullZustandState = globalState.getState(),
): Promise<DocumentWithTransientData<T> | null> {
  if (!id) {
    return null;
  }
  const doc = await database.collections.documents.findOne<T>(id);
  if (!doc) {
    return null;
  }
  const transientData = state.transientDocumentsData[id] ?? createInitialTransientDocumentData();
  return {
    ...doc,
    transientData,
  };
}

export async function getDocument<T extends AnyDocument>(
  id: BaseDocument['id'],
): Promise<T | null> {
  return database.collections.documents.findOne<T>(id);
}

export async function getDocuments<T extends AnyDocument>(
  ids: BaseDocument['id'][],
): Promise<T[]> {
  return database.collections.documents.findByIds<T>(ids);
}

/**
 * Retrieves documents from the database in batches. Batches and sorts by document ID.
 */
export async function iterateThroughDocumentsInBatches<T extends AnyDocument = AnyDocument>(
  selector: MangoQuerySelector<T>,
  handleBatch: (batch: T[]) => Promise<void>,
  options: {
    batchSize?: number;
    shouldExpensivelyCountDocsLeft?: boolean; // for debug purposes, don't leave on in production
  } = {},
): Promise<void> {
  // We're trying to strike a balance between:
  //    1. total number of queries fired, which increases the time that this function runs, and
  //    2. the cost of a single query i.e. how much it freezes up the app.
  const batchSize = options.batchSize ?? (isMobile ? 50 : 250);

  const batchNum = 0;
  let lastId = '';
  while (true) {
    const timerId = Math.random().toString().slice(2);
    const batchQuery: MangoQuery<AnyDocument> = {
      selector: {
        ...selector,
        id: {
          $gt: lastId,
        },
      },
      limit: batchSize,
      sort: [{
        id: 'asc',
      }],
    };
    logger.time(`getDocumentsInBatches batch ${batchNum} #${timerId}`);
    logger.debug(`getDocumentsInBatches batch ${batchNum} #${timerId} preparing`, { batchQuery, batchSize });
    const documentBatch = await database.collections.documents.find<T>(batchQuery);
    if (options.shouldExpensivelyCountDocsLeft) {
      const queryForCount: MangoQuery<AnyDocument> = {
        ...batchQuery,
        limit: undefined,
      };
      const docsLeft = await database.collections.documents.count(queryForCount);
      logger.debug(`getDocumentsInBatches batch ${batchNum} #${timerId} docs left: ${docsLeft}`);
    }
    logger.debug(`getDocumentsInBatches batch ${batchNum} #${timerId} results`, { documentBatch, batchQuery, batchSize });
    logger.timeEnd(`getDocumentsInBatches batch ${batchNum} #${timerId}`);
    if (documentBatch.length === 0) {
      break;
    }
    await handleBatch(documentBatch);
    const lastDoc = documentBatch[documentBatch.length - 1];
    lastId = lastDoc.id;
    logger.debug(`getDocumentsInBatches batch ${batchNum} #${timerId} next params`, { lastDoc, lastId });
  }
}

export const getFeed = <T = RssFeed>(id: string | null | void, state: FullZustandState = globalState.getState()): T | null => {
  if (!id || !state.persistent.rssFeeds) {
    return null;
  }
  return state.persistent.rssFeeds[id] as unknown as T;
};

export const getView = <T = FilteredView>(id: string | null | void, state: FullZustandState = globalState.getState()): T | null => {
  if (!id || !state.persistent.filteredViews) {
    return null;
  }
  return state.persistent.filteredViews[id] as unknown as T;
};

export const getIsAutoHighlightingEnabled = (state: FullZustandState = globalState.getState()): boolean => {
  if (isExtension) {
    // Defaults to false
    return Boolean(state.persistent.settings.extension?.isAutoHighlightingEnabled);
  }
  // We do `!== false` because it may not exist. In that case we default to true
  if (isMobile) {
    return state.persistent.settings.mobile?.autoHighlight !== false;
  }
  if (isWebApp) {
    return state.persistent.settings.web?.isAutoHighlightingEnabled !== false;
  }
  throw new Error('Unknown platform');
};

export const getAutoSummarizeEnabled = (state: FullZustandState = globalState.getState()): boolean => {
  // backwards compatible check to avoid false negatives (auto-summarize is on by default)
  return state.persistent.settings.openai?.isAutoSummarizeEnabled !== false;
};

export const getIsAutoTaggingEnabled = (state: FullZustandState = globalState.getState()): boolean => {
  return state.persistent.settings.openai?.isAutoTaggingEnabled === true;
};

export const getAskToPasteUrl = (state: FullZustandState = globalState.getState()): boolean => {
  return state.client.askToPasteUrls;
};

// NOTE: Use the useHighlightNote() DB hook if you can. This function is only for one-time retrieval.
export async function getNoteFromHighlight(
  highlight: Highlight | PartialDocument<Highlight, 'id' | 'children'> | void | null,
): Promise<NonNullable<Note['content']>> {
  if (!highlight || !highlight.children || highlight.children.length === 0) {
    return '';
  }

  if (highlight.children.length > 1) {
    logger.warn(`Highlight#${highlight.id} has more than one child. This should not happen, highlights can only have one (note) child. This function will only return the first one.`, { highlight });
  }
  const note = await database.collections.documents.findOne(highlight.children[0]);
  return note?.content ?? '';
}
