import { isExtension } from './environment';

export default (timeout?: number): Promise<void> => new Promise((resolve) => {
  // requestAnimationFrame doesn't work in the extension background
  if (timeout || isExtension) {
    setTimeout(resolve, timeout);
    return;
  }
  requestAnimationFrame(() => resolve());
});
