import type { UserEvent } from '../../../types';
// eslint-disable-next-line import/no-cycle
import database from '../../database';
import foregroundEventEmitter from '../../eventEmitter';
// eslint-disable-next-line import/no-cycle
import {
  CancelStateUpdate,
  getRecentUserEventsWithDataUpdates,
  recentUndoneUserEvents,
  updateState,
  userEventsState,
} from '../../models';
// eslint-disable-next-line import/no-cycle
import { removeToast } from '../../toasts.platform';
import documentLocationUpdatedEventName from '../../utils/documentLocationUpdatedEventName';
import fullDocumentDeletedEventName from '../../utils/fullDocumentDeletedEventName';
import updateAllStateUsingJsonPatchOperations from '../../utils/updateAllStateUsingJsonPatchOperations';
import { setFocusedDocumentId } from './other';

export const undoDocumentAction = async (eventId: UserEvent['id'], userInteraction: string, eventName = 'undo-action'): Promise<void> => {
  const recentUserEventsWithDataUpdates = getRecentUserEventsWithDataUpdates();
  const index = recentUserEventsWithDataUpdates.findIndex(({ id }) => id === eventId);
  if (index === -1) {
    throw new Error('No such event exists');
  }

  const [mostRecentEvent] = recentUserEventsWithDataUpdates.splice(index, 1);
  const mostRecentCorrelationId = mostRecentEvent.correlationId;

  // Make sure we undo _all_ the recent events with the same correlationId
  const events = [mostRecentEvent];

  for (let i = index - 1; i >= 0; i--) {
    const event = recentUserEventsWithDataUpdates[i];
    if (event?.correlationId === mostRecentCorrelationId) {
      events.push(event);
      // Remove this related event from the recentUserEventsWithDataUpdates stack too
      recentUserEventsWithDataUpdates.splice(i, 1);
    }
  }

  for (const event of events) {
    recentUndoneUserEvents.push(event);

    await updateAllStateUsingJsonPatchOperations({
      canModifyOperations: false,
      correlationId: event.correlationId,
      extraStateChanger: (state) => {
        state.canUndoAction = false;
      },
      eventName,
      isUndoable: false,
      operations: event.dataUpdates.reversePatch,
      userInteraction,
      // TODO: should we really be importing database here? it was previously imported from
      //  updateAllStateUsingJsonPatchOperations which is the same thing?
      database,
      updateState,
    });

    // If there was a toast created for this event, remove it
    removeToast(event.id);
  }

  // Without this when you delete or change the location of a document
  // and then undo it, the focusedDocumentId is not correct.
  const shouldRefocusOnDoc = mostRecentEvent.name === documentLocationUpdatedEventName || mostRecentEvent.name === fullDocumentDeletedEventName;
  if (shouldRefocusOnDoc) {
    const updatedItem = mostRecentEvent.dataUpdates.itemsUpdated[0];
    if (updatedItem.type === 'documents') {
      setFocusedDocumentId(updatedItem.id, { userInteraction: null });
    }
  }

  foregroundEventEmitter.emit('undid-action', { events });
};

export const redoDocumentAction = async (eventId: UserEvent['id'], userInteraction: string, eventName = 'redo-action'): Promise<void> => {
  const index = recentUndoneUserEvents.findIndex(({ id }) => id === eventId);
  if (index === -1) {
    throw new Error('No such event exists');
  }

  const [event] = recentUndoneUserEvents.splice(index, 1);
  await updateAllStateUsingJsonPatchOperations({
    canModifyOperations: true,
    correlationId: event.correlationId,
    extraStateChanger: (state) => {
      state.canUndoAction = true;
    },
    eventName,
    operations: event.dataUpdates.forwardPatch,
    userInteraction,
    database,
    updateState,
  });
};

const undoEventName = 'undo-last-document-action';

export const undoLastDocumentAction = async (userInteraction: string): Promise<void> => {
  const recentUserEventsWithDataUpdates = getRecentUserEventsWithDataUpdates();
  if (!recentUserEventsWithDataUpdates.length) {
    return;
  }
  await undoDocumentAction(
    recentUserEventsWithDataUpdates[recentUserEventsWithDataUpdates.length - 1].id,
    userInteraction,
    undoEventName,
  );
};

const redoEventName = 'redo-last-document-action';

export const redoLastDocumentAction = async (userInteraction: string): Promise<void> => {
  if (!recentUndoneUserEvents.length) {
    return;
  }

  const state = userEventsState.getState();
  const lastEventName = state.userEvents[state.userEvents.length - 1].name;
  const lastEventWasUndo = lastEventName === undoEventName;
  const lastEventWasRedo = lastEventName === redoEventName;

  // Only allow a redo if last event was an undo or redo.
  // If not, clear the recentUndoedUserEvents array.
  if (lastEventWasUndo || lastEventWasRedo) {
    await redoDocumentAction(
      recentUndoneUserEvents[recentUndoneUserEvents.length - 1].id,
      userInteraction,
      redoEventName,
    );
  } else {
    recentUndoneUserEvents.length = 0;
  }
};

let undoActionTimer: ReturnType<typeof window.setTimeout> | null = null;
export const setCanUndoAction = async (canUndo: boolean): Promise<void> => {
  if (undoActionTimer) {
    clearTimeout(undoActionTimer);
    undoActionTimer = null;
  }
  await updateState((state) => {
    if (canUndo) {
      undoActionTimer = setTimeout(async () => setCanUndoAction(false), 5000);
    }
    if (state.canUndoAction === canUndo) {
      throw new CancelStateUpdate();
    }
    state.canUndoAction = canUndo;
  }, {
    eventName: 'can-undo-action',
    shouldCreateUserEvent: false, // We don't care when this happens
    userInteraction: 'unknown',
  });
};
