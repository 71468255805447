import zip from 'lodash/zip';
import { Database } from 'sql.js';

import background from './portalGates/toBackground/singleProcess';
import initSqlJs from './sql-wasm';
import type { GenericSqliteRow, ISqliteDatabase, SqliteValue } from './sqliteDatabase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let SQLitePromise: any;


export class BrowserSqliteDatabase implements ISqliteDatabase {
  filename: string | undefined;
  _db: Database | undefined;

  async init(filename: string, forceNewDb: boolean): Promise<void> {
    this.filename = filename;
    if (!SQLitePromise) {
      SQLitePromise = initSqlJs({
        // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
        // TODO: we should really serve this ourselves, maybe once we're on vite
        locateFile: (file: string) => `/${file}`,
      });
    }
    const SQLite = await SQLitePromise;
    let dbDump: ArrayBuffer | undefined;
    if (!forceNewDb) {
      const cache = await caches.open(filename);
      const cacheResponse = await cache.match('/data.json');
      dbDump = cacheResponse && await cacheResponse.arrayBuffer();
    }
    if (dbDump) {
      this._db = new SQLite.Database(new Uint8Array(dbDump));
    } else {
      this._db = new SQLite.Database();
    }
  }

  get db(): Database {
    if (!this._db) {
      throw new Error('DB not initialized');
    }
    return this._db;
  }

  async execute(query: string, params?: SqliteValue[]): Promise<void> {
    this.db.run(query, params);
  }

  async select<Row extends GenericSqliteRow = GenericSqliteRow>(query: string, params?: SqliteValue[]): Promise<Row[]> {
    const results = this.db.exec(query, params);
    if (results.length === 0) {
      return [];
    }
    const result = results[0];

    return result.values.map((rawRow) => Object.fromEntries(zip(result.columns, rawRow)));
  }

  async save(): Promise<void> {
    if (!this.filename) {
      throw new Error('DB not initialized');
    }
    const binaryDump = this.db.export();
    await background.putItemInCacheStorage(this.filename, '/data.json', binaryDump);
  }

}
