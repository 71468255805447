import React, { forwardRef } from 'react';
import {
  Link,
} from 'react-router-dom';

import styles from './Button.module.css';

export type ButtonVariant = 'primary' | 'secondary' | 'unstyled' | 'default' | 'blue' | 'danger';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
  hideAccessibly?: boolean;
  isActive?: boolean;
  to?: string;
  variant?: ButtonVariant;
  isExternal?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default forwardRef<any, Props>(function Button({
  children,
  className,
  hideAccessibly,
  isActive = false,
  to,
  variant = 'unstyled',
  isExternal = false,
  ...extraProps
}, ref): JSX.Element {
  const variantClass = `button${variant[0].toUpperCase()}${variant.substr(1)}`;
  const classes = [
    'button',
    styles.button,
    className,
    isActive ? styles.activeButton : '',
    styles[variantClass],
  ].filter(Boolean);
  const otherProps = { ...extraProps };

  if (hideAccessibly) {
    classes.push('hideAccessibly');
    otherProps.tabIndex = -1;
  }

  const _className = classes.join(' ');

  if (to) {
    if (isExternal) {
      return (
        <a
          {...otherProps}
          className={_className}
          ref={ref}
          href={to}
          rel="noopener noreferrer"
        >
          {children}
        </a >
      );
    }

    return (
      <Link
        {...otherProps}
        className={_className}
        ref={ref}
        to={{ pathname: to }}
      >
        {children}
      </Link >
    );
  }

  /* eslint-disable react/button-has-type*/
  return (
    <button
      {...otherProps}
      className={_className}
      ref={ref}
      type={otherProps.type || 'button'}>
      {children}
    </button >
  );
  /* eslint-enable react/button-has-type*/
});
