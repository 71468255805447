import { DatabaseGetSchemaResult } from '../../../types/database';
import * as commonDefinitions from './commonDefinitions';

function getSchema(): { state_syncing_key_value_pairs: DatabaseGetSchemaResult<'state_syncing_key_value_pairs'>; } {
  return {
    state_syncing_key_value_pairs: {
      schema: {
        primaryKey: 'id',
        properties: {
          id: commonDefinitions.getString(100),
          value: commonDefinitions.getString(Infinity),
        },
        required: ['value'],
        type: 'object',
        version: 0,
      },
    },
  } as const;
}

export default {
  getSchema,
};
