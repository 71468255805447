import * as jsonpatch from 'readwise-fast-json-patch';

import type { UserEvent, UserInteractionName } from '../types';
import type { DatabaseCollectionNamesToDocType } from '../types/database';
import type { JSONPatchOperationWithPathSegments } from '../types/jsonpatch';
import cloneDeep from '../utils/cloneDeep';
import makeLogger from '../utils/makeLogger';
import batchifyDatabaseUpdate from './batchify';
import type Database from './Database';

type CommonOptions = {
  canModifyOperations: boolean; // I.e. can we avoid deep cloning (which would better for performance)?
  correlationId?: UserEvent['correlationId'];
  database: Database;
  eventName: string;
  isUndoable?: boolean;
  shouldNotSendPersistentChangesToServer?: boolean;
  userInteraction: UserInteractionName | null;
};

const logger = makeLogger(__filename);

function applyOperationsToDocumentData({
  canModifyOperations,
  doc,
  documentId,
  operations,
}: Pick<CommonOptions, 'canModifyOperations'> & {
  doc: DatabaseCollectionNamesToDocType['documents'];
  documentId: DatabaseCollectionNamesToDocType['documents']['id'];
  operations: jsonpatch.Operation[];
}) {
  for (const operation of operations) {
    const newOp = canModifyOperations ? operation : cloneDeep(operation);
    newOp.path = operation.path.replace(`/documents/${documentId}`, '');
    jsonpatch.applyOperation(doc, newOp);
  }
  return doc;
}

// Find the index of the last whole document operation and slice the operations starting there (or take all)
function findLastWholeDocumentOperation(operations: JSONPatchOperationWithPathSegments[]): {
  lastWholeDocumentOperationIndex: number;
  remainingOperations: JSONPatchOperationWithPathSegments[];
} {
  const lastWholeDocumentOperationIndex = Array.from(operations).reverse().findIndex((operation) =>
    ['add', 'remove', 'replace'].includes(operation.op) && isWholeDocumentOperation(operation));
  return {
    lastWholeDocumentOperationIndex,
    remainingOperations: operations.slice(Math.max(lastWholeDocumentOperationIndex, 0)),
  };
}

function groupOperationsByDocumentId(
  operations: JSONPatchOperationWithPathSegments[],
): Map<string, JSONPatchOperationWithPathSegments[]> {
  const operationsMap = new Map<string, JSONPatchOperationWithPathSegments[]>();

  for (const operation of operations) {
    const documentId = operation.pathSegments[1];
    const documentOperations = operationsMap.get(documentId) ?? [];
    documentOperations.push(operation);
    operationsMap.set(documentId, documentOperations);
  }

  return operationsMap;
}

function isWholeDocumentOperation(operation: JSONPatchOperationWithPathSegments) {
  return operation.pathSegments.length === 2;
}

async function performDocumentDeletions({
  ids,
  options,
}: {
  ids: DatabaseCollectionNamesToDocType['documents']['id'][];
  options: CommonOptions;
}) {
  if (!ids.length) {
    return;
  }
  logger.debug('Applying deletion JSONPatch operations to RxDB documents', {
    ids,
    options,
  });
  await batchifyDatabaseUpdate({
    args: [ids, options],
    collectionName: 'documents',
    func: options.database.collections.documents.deleteByIds,
  });
  logger.debug('Done applying deletion JSONPatch operations to RxDB documents', {
    ids,
    options,
  });
}

async function performDocumentUpserts({
  documents,
  options,
}: {
  documents: DatabaseCollectionNamesToDocType['documents'][];
  options: CommonOptions;
}) {
  if (!documents.length) {
    return;
  }
  logger.debug('Applying JSONPatch operations as upserts to RxDB documents', {
    documents,
    options,
  });
  await batchifyDatabaseUpdate({
    args: [documents, options],
    collectionName: 'documents',
    func: options.database.collections.documents.bulkUpsert,
  });
  logger.debug('Done applying JSONPatch operations as upserts to RxDB documents', {
    documents,
    options,
  });
}

async function performPartialUpdatesToDocuments({
  ids,
  idsToOperations,
  options,
}: {
  ids: DatabaseCollectionNamesToDocType['documents']['id'][];
  idsToOperations: Map<string, jsonpatch.Operation[]>;
  options: CommonOptions;
}) {
  if (!ids.length) {
    return;
  }
  logger.debug('Applying partial-update JSONPatch operations to RxDB documents', {
    operations: Array.from(idsToOperations),
    options,
  });

  const mutationFunction = (oldDocumentsData: DatabaseCollectionNamesToDocType['documents'][]) => {
    const results: DatabaseCollectionNamesToDocType['documents'][] = [];
    for (const oldDocumentData of oldDocumentsData) {
      const documentOperations = idsToOperations.get(oldDocumentData.id);
      if (!documentOperations) {
        // Absolutely unexpected
        throw new Error('Document found in database does not exist in operations?!');
      }
      results.push(applyOperationsToDocumentData({
        canModifyOperations: options.canModifyOperations,
        doc: oldDocumentData,
        documentId: oldDocumentData.id,
        operations: documentOperations,
      }));
    }
    return results;
  };


  await batchifyDatabaseUpdate({
    args: [
      ids,
      mutationFunction,
      {
        ...options,
        shouldWarnIfAnyMissing: true,
      },
    ],
    collectionName: 'documents',
    func: options.database.collections.documents.findByIdsAndBulkUpsert,
  });

  logger.debug('Done applying partial-update JSONPatch operations to RxDB documents', {
    operations: Array.from(idsToOperations),
    options,
  });
}

/*
  For one document, this throws away any pointless operations (e.g. an update to a document which is later deleted
  anyway), and then categorizes the remaining operations. This category tells us which kind of database update call
  we can do with these operations later; e.g. is it a document deletion? Some kind of update?
*/
function pruneAndCategorizeDocumentOperations({
  canModifyOperations,
  operations: allOperations, // it's renamed so it's more obvious if you accidentally use it
}: Pick<CommonOptions, 'canModifyOperations'> & {
  operations: JSONPatchOperationWithPathSegments[];
}):
  | {
    category: 'deletion';
  }
  | {
    category: 'partial-updates'; // Not a whole document replace for example
    prunedOperations: JSONPatchOperationWithPathSegments[];
  }
  | {
    category: 'upsert';
    document: DatabaseCollectionNamesToDocType['documents'];
  } {

  /*
    If there's a whole document add/remove/replace operation anywhere, ignore the previous operations; we'll apply the
    new first operation plus whatever comes after.

    If the (new) first operation is a whole document delete, there can't be anything after it, so we'll just delete the
    document.

    If the (new) first operation is a whole document add/replace, take this whole document and apply any subsequent
    operations to it. The resultant document is what will be upserted.

    Otherwise, all remaining operations must be partial document updates. They will eventually be applied to the
    document (once we get it from the database).
    NOTE: Of the remaining operations, there could be any kind of operation because they could be related to individual
    fields (not a whole-document operation).
  */

  const {
    lastWholeDocumentOperationIndex,
    remainingOperations,
  } = findLastWholeDocumentOperation(allOperations);

  if (lastWholeDocumentOperationIndex >= 0 && remainingOperations[0].op === 'remove') {
    return {
      category: 'deletion',
    };
  }

  // Do the remaining operations start with a full document add / replace?
  if (
    isWholeDocumentOperation(remainingOperations[0]) &&
    (remainingOperations[0].op === 'add' || remainingOperations[0].op === 'replace')
  ) {
    // There could be updates afterwards, so let's merge those; i.e. apply them this new/replaced document
    const finalDocument = applyOperationsToDocumentData({
      canModifyOperations,
      doc: remainingOperations[0].value,
      documentId: remainingOperations[0].pathSegments[1],
      operations: remainingOperations.slice(1),
    });

    return {
      category: 'upsert',
      document: finalDocument,
    };
  }

  /*
    At this point, the remaining operations are just operations on nested fields. We can't merge the operations because
    we don't have the full document like we do above.
  */
  return {
    category: 'partial-updates',
    prunedOperations: remainingOperations,
  };
}

/*
  This groups the operations by document ID, then it throws away any pointless operations (e.g. an update to a document
  which is later deleted anyway), and then it groups the sets of document operations into category batches so we know
  which kind of database updates we can use them for later.
*/
function pruneGroupAndCategorizeOperations({
  canModifyOperations,
  operations,
}: Pick<CommonOptions, 'canModifyOperations'> & {
  operations: JSONPatchOperationWithPathSegments[];
}): {
  documentIdsToBeDeleted: DatabaseCollectionNamesToDocType['documents']['id'][];
  documentIdsToBePartiallyUpdated: DatabaseCollectionNamesToDocType['documents']['id'][];
  documentIdsToPartialUpdateOperationsMap: Map<string, JSONPatchOperationWithPathSegments[]>;
  documentsToBeUpserted: DatabaseCollectionNamesToDocType['documents'][];
} {
  // Heads up, this will later be filtered down to documents that were only partially updated
  const documentIdsToOperationsMap = groupOperationsByDocumentId(operations);

  const documentIdsToBeDeleted: DatabaseCollectionNamesToDocType['documents']['id'][] = [];
  // This only exists as an optimization; to avoid a second `.keys()` call later
  const documentIdsToBePartiallyUpdated: DatabaseCollectionNamesToDocType['documents']['id'][] = [];
  const documentsToBeUpserted: DatabaseCollectionNamesToDocType['documents'][] = [];

  for (const [documentId, operations] of Array.from(documentIdsToOperationsMap)) {
    const categorizationResult = pruneAndCategorizeDocumentOperations({
      canModifyOperations,
      operations,
    });
    if (categorizationResult.category === 'partial-updates') {
      documentIdsToBePartiallyUpdated.push(documentId);
      documentIdsToOperationsMap.set(documentId, categorizationResult.prunedOperations);
    } else {
      documentIdsToOperationsMap.delete(documentId);

      if (categorizationResult.category === 'deletion') {
        documentIdsToBeDeleted.push(documentId);
      } else { // Upsert
        documentsToBeUpserted.push(categorizationResult.document);
      }
    }
  }

  return {
    documentIdsToBeDeleted,
    documentIdsToBePartiallyUpdated,
    documentIdsToPartialUpdateOperationsMap: documentIdsToOperationsMap,
    documentsToBeUpserted,
  };
}

// This is performance-critical; we aim to do bulk database calls as much as possible
export default async function updateDocumentsInDatabaseUsingJsonPatchOperations(
  options: CommonOptions & {
    operations: JSONPatchOperationWithPathSegments[];
  },
): Promise<void> {
  if (!options.operations.length) {
    return;
  }

  const {
    documentIdsToBeDeleted,
    documentIdsToBePartiallyUpdated,
    documentIdsToPartialUpdateOperationsMap,
    documentsToBeUpserted,
  } = pruneGroupAndCategorizeOperations({
    canModifyOperations: options.canModifyOperations,
    operations: options.operations,
  });

  /*
    The following is ordered to make receiving updates from the server as unnoticeable / unannoying to users as
    possible; e.g. imagine someone archived a document and then opened another client.
  */

  if (documentIdsToBePartiallyUpdated.length > 0) {
    await performPartialUpdatesToDocuments({
      ids: documentIdsToBePartiallyUpdated,
      idsToOperations: documentIdsToPartialUpdateOperationsMap,
      options,
    });
  }

  if (documentsToBeUpserted.length > 0) {
    await performDocumentUpserts({
      documents: documentsToBeUpserted,
      options,
    });
  }

  if (documentIdsToBeDeleted.length > 0) {
    await performDocumentDeletions({
      ids: documentIdsToBeDeleted,
      options,
    });
  }
}
