import userAgentParser from 'ua-parser-js';

import type { AppCategory, BrowserInfo, Channel, Device, OS } from '../types';
import {
  systemName,
  systemVersion,
} from './env.platform';

export * from './env.platform';

// Check if we are in a Node.js environment
if (typeof process === 'object' && typeof window === 'undefined') {
  // we need this check, otherwise other browser environments like workers fail
  if (typeof global === 'object') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    global.navigator = {
      userAgent: 'Node.js',
      product: 'Node.js',
    };
  }
}

const userAgentInfo = userAgentParser(navigator.userAgent);

// Can't use "browser" because that's reserved for the Web Extensions API in Firefox
export const browserInfo: BrowserInfo = {
  engine: userAgentInfo.engine.name || 'Unknown',
  name: userAgentInfo.browser.name || 'unknown',
  version: userAgentInfo.browser.version || 'unknown',
};

// If it's the empty string, we want the default
// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
export const channel: Channel = process.env.NODE_ENV || (__DEV__ ? 'development' : 'production') as Channel;

// If it's the empty string, we want the default
// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
export const commitId: string = process.env.COMMIT_ID || 'unknown';

export const device: Device = {
  model: userAgentInfo.device.model || 'unknown',
  type: userAgentInfo.device.type || 'unknown',
  vendor: userAgentInfo.device.vendor || 'unknown',
};

export const isCi = Boolean(process.env.CI);
const isCypressEnv = typeof window !== 'undefined' && 'Cypress' in window;
export const isTest = Boolean(process.env.IS_TEST) || Boolean(process.env.ENABLE_VITEST) || isCypressEnv;
// Use isDevOrTest unless you've a great reason
const isDev = channel === 'development';
export const isDevOrTest = isDev || isTest;
export const isDeployPreview = Boolean(process.env.CF_PAGES_BRANCH && process.env.CF_PAGES_BRANCH !== 'master');
export const isInReactNativeWebView = Boolean(process.env.IS_IN_REACT_NATIVE_WEB_VIEW);
export const isMobile = navigator.product === 'ReactNative' || isInReactNativeWebView;
export const isExtension = Boolean(process.env.IS_EXTENSION);

export const isWebApp = !isExtension && !isMobile;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDesktopApp = typeof window === 'undefined' ? false : Boolean((window as any).__TAURI_INTERNALS__);

export const isUsingSQLite = isMobile || isDesktopApp;

export const isDocumentShareApp = Boolean(process.env.IS_DOCUMENT_SHARE_APP);

export const appCategory: AppCategory = isMobile ? 'mobile-app' : isExtension ? 'extension' : 'web-app';
export const isZustandDevToolsEnabled = isDev && process.env.ZUSTAND_DEVTOOLS_ENABLED;

export const os: OS = {
  name: systemName || 'unknown',
  version: systemVersion || 'unknown',
};

export const getPlatformInfo = () => {
  return `${browserInfo.name} ${browserInfo.version} on ${os.name} ${os.version} (device: ${device.type} ${device.model} ${device.vendor})`;
};

export enum BrowserType {
  Chrome = 'Chrome',
  Safari = 'Safari',
  Opera = 'Opera',
  Edge = 'Edge',
  Firefox = 'Firefox',
  Brave = 'Brave',
}

export function getCurrentBrowser(): BrowserType | undefined {

  // https://stackoverflow.com/questions/36523448/how-do-i-tell-if-a-user-is-using-brave-as-their-browser
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (navigator.brave) {
    return BrowserType.Brave;
  }
  const userAgent = navigator.userAgent;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    return BrowserType.Chrome;
  } else if (userAgent.match(/firefox|fxios/i)) {
    return BrowserType.Firefox;
  } else if (userAgent.match(/safari/i)) {
    return BrowserType.Safari;
  } else if (userAgent.match(/opr\//i)) {
    return BrowserType.Opera;
  } else if (userAgent.match(/edg/i)) {
    return BrowserType.Edge;
  }
}

export function isIOSBrowser(): boolean {
  return /ipad|iphone|ipod/i.test(navigator.userAgent);
}

export function isAndroidBrowser(): boolean {
  return /android/i.test(navigator.userAgent);
}

export function isMobileBrowser(): boolean {
  return isIOSBrowser() || isAndroidBrowser();
}
