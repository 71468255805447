import '../networkDetector.platform';

import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { useCallback } from 'react';
import * as jsonpatch from 'readwise-fast-json-patch';
import create, { GetState, PartialState, SetState, State, StoreApi } from 'zustand';
import { devtools, StoreApiWithSubscribeWithSelector, subscribeWithSelector } from 'zustand/middleware';

import getDefaultSwipeConfig from '../../mobile/src/utils/getDefaultSwipeConfig';
import { AST } from '../filters-compiler/types';
import { NetworkStatus } from '../NetworkDetector';
import type {
  ClientState,
  FocusedHighlightIdState,
  FullZustandState,
  IsReadingState,
  PersistentState,
  SettingsState,
  SplitByValue,
  UserEvent,
  UserEventsState,
  UserEventWithDataUpdate,
} from '../types';
import {
  BulkActionType,
  Category,
  DisplayTheme,
  DocumentLocation,
  FeedDocumentLocation,
  Font,
  HeadphoneAction,
  MainTitleType,
  PersistentStateLoadingState,
  SortKey,
  SortOrder,
  SortRule,
  SplitByKey,
  SubMenu,
  TableSortKey,
  TextDirection,
  TshirtSize,
} from '../types';
import {
  ClassicThemeVariantId,
  FreshVariantId,
  GradientVariantId,
  ModernVariantId,
  QuoteshotAspectRatio,
  QuoteshotFont,
  QuoteshotThemeName,
  ScribbleVariantId,
  UnstyledVariantId,
} from '../types/quoteshots';
import { isDevOrTest, isExtension, isZustandDevToolsEnabled } from '../utils/environment';
import produce from '../utils/immer';
import makeLogger from '../utils/makeLogger';
import safeJsonPatchCompareOfState from '../utils/safeJsonPatchCompareOfState';
import handleStatePathsThatNeedToBeUpdatedAsAWhole from './handleStatePathsThatNeedToBeUpdatedAsAWhole';
// eslint-disable-next-line import/no-cycle
import handleStateUpdateSideEffects from './handleStateUpdateSideEffects';
// eslint-disable-next-line import/no-cycle
import background from './portalGates/toBackground/singleProcess';
// eslint-disable-next-line import/no-cycle

const logger = makeLogger(__filename);

// zustand doesn't export this
type NamedSet<T extends State> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  <K extends keyof T>(partial: PartialState<T, K>, replace?: boolean, name?: string): void;
};

export const FontSizeNumberDesktop = {
  [TshirtSize['5XS']]: 14,
  [TshirtSize['4XS']]: 15,
  [TshirtSize['3XS']]: 16,
  [TshirtSize['2XS']]: 17,
  [TshirtSize.XS]: 18,
  [TshirtSize.S]: 19,
  [TshirtSize.M]: 20,
  [TshirtSize.L]: 21,
  [TshirtSize.XL]: 22,
  [TshirtSize['2XL']]: 23,
  [TshirtSize['3XL']]: 24,
  [TshirtSize['4XL']]: 25,
  [TshirtSize['5XL']]: 26,
  [TshirtSize['6XL']]: 27,
  [TshirtSize['7XL']]: 28,
  [TshirtSize['8XL']]: 29,
  [TshirtSize['9XL']]: 30,
  [TshirtSize['10XL']]: 32,
  [TshirtSize['11XL']]: 34,
  [TshirtSize['12XL']]: 36,
  [TshirtSize['13XL']]: 38,
  [TshirtSize['14XL']]: 40,
  [TshirtSize['15XL']]: 44,
  [TshirtSize['16XL']]: 48,
  [TshirtSize['17XL']]: 52,
  [TshirtSize['18XL']]: 56,
  [TshirtSize['19XL']]: 60,
  [TshirtSize['20XL']]: 66,
  [TshirtSize['21XL']]: 74,
  [TshirtSize['22XL']]: 80,
};

export const FontSizeNumberMobile = {
  [TshirtSize['9XS']]: 12,
  [TshirtSize['8XS']]: 13,
  [TshirtSize['7XS']]: 14,
  [TshirtSize['6XS']]: 15,
  [TshirtSize['5XS']]: 16,
  [TshirtSize['4XS']]: 17,
  [TshirtSize['3XS']]: 18,
  [TshirtSize['2XS']]: 19,
  [TshirtSize.XS]: 20,
  [TshirtSize.S]: 21,
  [TshirtSize.M]: 22,
  [TshirtSize.L]: 23,
  [TshirtSize.XL]: 24,
  [TshirtSize['2XL']]: 25,
  [TshirtSize['3XL']]: 26,
  [TshirtSize['4XL']]: 27,
  [TshirtSize['5XL']]: 28,
  [TshirtSize['6XL']]: 29,
  [TshirtSize['7XL']]: 30,
  [TshirtSize['8XL']]: 32,
  [TshirtSize['9XL']]: 34,
  [TshirtSize['10XL']]: 36,
  [TshirtSize['11XL']]: 38,
  [TshirtSize['12XL']]: 40,
  [TshirtSize['13XL']]: 44,
  [TshirtSize['14XL']]: 48,
  [TshirtSize['15XL']]: 52,
  [TshirtSize['16XL']]: 56,
  [TshirtSize['17XL']]: 60,
  [TshirtSize['18XL']]: 66,
  [TshirtSize['19XL']]: 74,
  [TshirtSize['20XL']]: 80,
};

export const LineLengthNumberDesktop = {
  [TshirtSize.XS]: 504,
  [TshirtSize.S]: 576,
  [TshirtSize.M]: 648,
  [TshirtSize.L]: 720,
  [TshirtSize.XL]: 792,
};

// These are modifiers to the base margin, so M size is 20px (base) + 8px;
export const LineLengthNumberMobile = {
  [TshirtSize.XS]: 0,
  [TshirtSize.S]: 4,
  [TshirtSize.M]: 8,
  [TshirtSize.L]: 12,
  [TshirtSize.XL]: 16,
};

export const PageMarginMultiplierMobileTablet = {
  [TshirtSize.XS]: 4,
  [TshirtSize.S]: 3,
  [TshirtSize.M]: 2,
  [TshirtSize.L]: 1,
  [TshirtSize.XL]: 0,
};

export const LineHeightNumberDesktop = {
  [TshirtSize['4XS']]: 1.2,
  [TshirtSize['3XS']]: 1.3,
  [TshirtSize['2XS']]: 1.4,
  [TshirtSize.XS]: 1.5,
  [TshirtSize.S]: 1.6,
  [TshirtSize.M]: 1.7,
  [TshirtSize.L]: 1.8,
  [TshirtSize.XL]: 1.9,
  [TshirtSize['2XL']]: 2,
  [TshirtSize['3XL']]: 2.1,
  [TshirtSize['4XL']]: 2.2,
};

export const LineHeightNumberMobile = {
  [TshirtSize['4XS']]: 1.2,
  [TshirtSize['3XS']]: 1.3,
  [TshirtSize['2XS']]: 1.4,
  [TshirtSize.XS]: 1.5,
  [TshirtSize.S]: 1.6,
  [TshirtSize.M]: 1.7,
  [TshirtSize.L]: 1.8,
  [TshirtSize.XL]: 1.9,
  [TshirtSize['2XL']]: 2,
  [TshirtSize['3XL']]: 2.1,
  [TshirtSize['4XL']]: 2.2,
};

export function getNextTshirtSizeFromKeys({
  keys,
  currentSize,
  direction = 1,
}: { keys: string[]; currentSize: TshirtSize; direction?: number; }): TshirtSize {
  const currentIndex = keys.findIndex((key) => {
    return key === currentSize;
  });

  if (direction > 0) {
    const lastSize = keys[keys.length - 1];
    return (keys[currentIndex + 1] || lastSize) as TshirtSize;
  }

  const firstSize = keys[0];
  return (keys[currentIndex - 1] || firstSize) as TshirtSize;
}

type DocumentListProperties = {
  categoryToFilterAndSortWith?: Category;
  filterByDocumentLocation?: DocumentLocation;
  filterByFeedDocumentLocation?: FeedDocumentLocation;
  filter?: { ast: AST; query: string; };
  splitByKey?: SplitByKey;
  splitByValue?: string;
};
export const getListId = (
  {
    categoryToFilterAndSortWith,
    filterByDocumentLocation,
    filterByFeedDocumentLocation,
    filter,
    splitByKey,
    splitByValue,
  }: DocumentListProperties,
) => {
  // Warning: don't mess with the string structure as the getListPropertiesFromId function depends on it
  return `category:${categoryToFilterAndSortWith}-documentLocation:${filterByDocumentLocation}-feedDocumentLocation:${filterByFeedDocumentLocation}-filter:${filter?.query}-splitByKey:${splitByKey}-splitByValue:${splitByValue}`;
};

export enum FilteredViewScreen {
  SplitByNothing = 'splitByNothing',

  // The order matters here:
  SplitByDocumentLocationNew = 'splitByDocumentLocationNew',
  SplitByDocumentLocationLater = 'splitByDocumentLocationLater',
  SplitByDocumentLocationShortlist = 'splitByDocumentLocationShortlist',
  SplitByDocumentLocationArchive = 'splitByDocumentLocationArchive',

  SplitBySeenStatusUnseen = 'splitBySeenStatusUnseen',
  SplitBySeenStatusSeen = 'splitBySeenStatusSeen',
}

export const splitBySeenScreens = Object.values(FilteredViewScreen)
  .filter((value) => value.startsWith('splitBySeen'));

export const SplitByScreenSplitByValue = {
  [FilteredViewScreen.SplitByNothing]: undefined,

  [FilteredViewScreen.SplitByDocumentLocationArchive]: DocumentLocation.Archive,
  [FilteredViewScreen.SplitByDocumentLocationLater]: DocumentLocation.Later,
  [FilteredViewScreen.SplitByDocumentLocationNew]: DocumentLocation.New,
  [FilteredViewScreen.SplitByDocumentLocationShortlist]: DocumentLocation.Shortlist,

  [FilteredViewScreen.SplitBySeenStatusUnseen]: FeedDocumentLocation.New,
  [FilteredViewScreen.SplitBySeenStatusSeen]: FeedDocumentLocation.Seen,
};

export enum ForegroundEventName {
  DownloadPdf = 'DownloadPdf',
  ShowPdfThumbnails = 'ShowPdfThumbnails',
  GoToPdfPage = 'GoToPdfPage',
}

export const MOBILE_DEFAULT_FONT = Font.SourceSerif;

export const MOBILE_FONTS_SERIF = {
  shared: {
    [Font.Literata]: 'Literata-Regular',
    [Font.Piazzolla]: 'Piazzolla-Regular',
    [Font.SourceSerif]: 'SourceSerif4Roman-DisplayRegular',
    [Font.Times]: 'Times new Roman',
  },
  ios: {
  },
  android: {
  },
};

export const MOBILE_FONTS_SANS_SERIF = {
  shared: {
    [Font.Inter]: 'Inter-Regular',
    [Font.IBMPlexSans]: 'IBMPlexSans-Regular',
    [Font.SourceSans]: 'SourceSans3-Regular',
    [Font.OpenDyslexic]: 'OpenDyslexic-Regular',
    [Font.AtkinsonHyperlegible]: 'AtkinsonHyperlegible-Regular',
    [Font.PublicSans]: 'PublicSansRoman-Regular',
    [Font.RobotoMono]: 'RobotoMono-Regular',
  },
  ios: {
  },
  android: {
    // For some reason this font isn't working on android correctly, this is only in the font selection sheet;
    [Font.AtkinsonHyperlegible]: 'default',
  },
};

export const MOBILE_FONTS = merge({}, MOBILE_FONTS_SERIF, MOBILE_FONTS_SANS_SERIF);

export const MOBILE_CSS_FONTS_SERIF = [Font.Literata, Font.Piazzolla, Font.SourceSerif, Font.Times];
export const MOBILE_CSS_FONTS_SANS_SERIF = [Font.AtkinsonHyperlegible, Font.Inter, Font.IBMPlexSans, Font.PublicSans, Font.SourceSans, Font.OpenDyslexic];

export const MOBILE_CSS_FONTS = {
  [Font.Times]: 'Times New Roman, serif',
  [Font.Inter]: "'Inter VF', sans-serif",
  [Font.AtkinsonHyperlegible]: "'Atkinson Hyperlegible', sans-serif",
  [Font.PublicSans]: "'Public Sans VF', Helvetica, sans-serif",
  [Font.Literata]: "'Literata VF', Georgia, Serif",
  [Font.IBMPlexSans]: "'IBM Plex Sans VF', sans-serif",
  [Font.Piazzolla]: "'Piazzolla VF', serif",
  [Font.SourceSans]: "'Source Sans VF', sans-serif",
  [Font.SourceSerif]: "'Source Serif VF', serif",
  [Font.OpenDyslexic]: 'OpenDyslexic',
};

export const WEB_CSS_FONTS = {
  [Font.Inter]: "'Inter VF', sans-serif",
  [Font.AtkinsonHyperlegible]: "'Atkinson Hyperlegible', sans-serif",
  [Font.PublicSans]: "'Public Sans VF', Helvetica, sans-serif",
  [Font.Literata]: "'Literata VF', Georgia, Serif",
  [Font.IBMPlexSans]: "'IBM Plex Sans VF', sans-serif",
  [Font.Piazzolla]: "'Piazzolla VF', serif",
  [Font.SourceSans]: "'Source Sans VF', sans-serif",
  [Font.SourceSerif]: "'Source Serif VF', serif",
  [Font.OpenDyslexic]: "'OpenDyslexic', sans-serif",
};

export const WEB_DEFAULT_FONT = Font.SourceSerif;
export const WEB_FONTS_SERIF = [
  Font.Literata,
  Font.Piazzolla,
  Font.SourceSerif,
];
export const WEB_FONTS_SANS_SERIF = [
  Font.AtkinsonHyperlegible,
  Font.Inter,
  Font.IBMPlexSans,
  Font.PublicSans,
  Font.SourceSans,
  Font.OpenDyslexic,
];

export const SortKeyDisplayName = {
  [SortKey.LastStatusUpdate]: 'Date moved',
  [SortKey.SavedAt]: 'Date saved',
  [SortKey.ReadingProgress]: 'Progress',
  [SortKey.Title]: 'Title',
  [SortKey.Author]: 'Author',
  [SortKey.Category]: 'Category',
  [SortKey.LastOpenedAt]: 'Date last opened',
  [SortKey.WordCount]: 'Length',
  [SortKey.Published]: 'Date published',
  [SortKey.Random]: 'Random',
};

const SortOrderDisplayNameByType = {
  date: {
    [SortOrder.Asc]: 'Old → Recent',
    [SortOrder.Desc]: 'Recent → Old',
  },
  string: {
    [SortOrder.Asc]: 'A → Z',
    [SortOrder.Desc]: 'Z → A',
  },
  integer: {
    [SortOrder.Asc]: '0 → 100',
    [SortOrder.Desc]: '100 → 0',
  },
};

export const SortOrderDisplayName = {
  [SortKey.LastStatusUpdate]: SortOrderDisplayNameByType.date,
  [SortKey.SavedAt]: SortOrderDisplayNameByType.date,
  [SortKey.LastOpenedAt]: SortOrderDisplayNameByType.date,
  [SortKey.Published]: SortOrderDisplayNameByType.date,

  [SortKey.Title]: SortOrderDisplayNameByType.string,
  [SortKey.Author]: SortOrderDisplayNameByType.string,
  [SortKey.Category]: SortOrderDisplayNameByType.string,

  [SortKey.ReadingProgress]: SortOrderDisplayNameByType.integer,
  [SortKey.WordCount]: SortOrderDisplayNameByType.integer,
};

// These are the keys that are used in the mobile sort views menu
export const TableSortKeyDisplayName = {
  [TableSortKey.Name]: 'Name',
  [TableSortKey.Documents]: 'Document count',
  [TableSortKey.LastUpdated]: 'Last updated',
  [TableSortKey.Manual]: 'Web manual order',
};

export const TableSortOrderDisplayName = {
  [TableSortKey.Name]: SortOrderDisplayNameByType.string,
  [TableSortKey.Documents]: SortOrderDisplayNameByType.integer,
  [TableSortKey.LastUpdated]: SortOrderDisplayNameByType.date,
};

export const DEFAULT_DOCUMENT_SORT_RULES = {
  [DocumentLocation.New]: [{
    id: '1',
    key: SortKey.LastStatusUpdate,
    order: SortOrder.Desc,
  }],
  [DocumentLocation.Later]: [{
    id: '2',
    key: SortKey.LastStatusUpdate,
    order: SortOrder.Desc,
  }],
  [DocumentLocation.Archive]: [{
    id: '3',
    key: SortKey.LastStatusUpdate,
    order: SortOrder.Desc,
  }],
  filterView: [{
    id: '5',
    key: SortKey.SavedAt,
    order: SortOrder.Desc,
  }],
} as const;


export const DEFAULT_SORT_RULE_ID = '1';

export const DEFAULT_SORT_RULES = [{
  id: DEFAULT_SORT_RULE_ID,
  key: SortKey.LastStatusUpdate,
  order: SortOrder.Desc,
}];

const defaultDocumentLocationListSortRules: { [listId: string]: SortRule[]; } = Object.fromEntries(
  Object.values(DocumentLocation).map((documentLocation, index) => {
    return [
      getListId({ filterByDocumentLocation: documentLocation }),
      [{
        id: (index + 1).toString(),
        key: SortKey.LastStatusUpdate,
        order: SortOrder.Desc,
      }],
    ];
  }),
);

export const DEFAULT_DOCUMENT_LIST_SORT_RULES_STATE: { [listId: string]: SortRule[]; } = {
  ...defaultDocumentLocationListSortRules,
  [getListId({ filterByDocumentLocation: DocumentLocation.Feed, filterByFeedDocumentLocation: FeedDocumentLocation.Seen })]: [{
    id: '6',
    key: SortKey.SavedAt,
    order: SortOrder.Desc,
  }],
  [getListId({ filterByDocumentLocation: DocumentLocation.Feed, filterByFeedDocumentLocation: FeedDocumentLocation.New })]: [{
    id: '7',
    key: SortKey.SavedAt,
    order: SortOrder.Desc,
  }],
};

export const SplitByKeyDisplayName = {
  [SplitByKey.DocumentLocation]: 'Location',
  [SplitByKey.Seen]: 'Seen',
};

export const SplitBySeenValues = {
  unseen: {
    name: 'unseen',
    queryParamValue: 'false',
  },
  seen: {
    name: 'seen',
    queryParamValue: 'true',
  },
};

export const SplitByValues = {
  [SplitByKey.DocumentLocation]: Object.values(DocumentLocation).reduce((acc: { [key: string]: SplitByValue; }, documentLocation) => {
    if (documentLocation === DocumentLocation.Feed) {
      return acc;
    }

    return {
      ...acc,
      [documentLocation]: {
        name: documentLocation,
        queryParamValue: documentLocation,
      },
    };
  }, {}),

  [SplitByKey.Seen]: SplitBySeenValues,
};

export const groupTitleByBulkType = {
  [BulkActionType.AllDocs]: 'All Documents in List',
  [BulkActionType.AboveFocusedDoc]: 'Above Focused Document',
  [BulkActionType.BelowFocusedDoc]: 'Below Focused Document',
};

export const mainTitleTypeByBulkType = {
  [BulkActionType.AllDocs]: MainTitleType.AllDocumentsInList,
  [BulkActionType.AboveFocusedDoc]: MainTitleType.AboveFocusedDoc,
  [BulkActionType.BelowFocusedDoc]: MainTitleType.BelowFocusedDoc,
};

export const subMenuTypeByBulkType = {
  [BulkActionType.AllDocs]: SubMenu.BulkActionsTagsAll as SubMenu.BulkActionsTagsAll,
  [BulkActionType.AboveFocusedDoc]: SubMenu.BulkActionsTagsAbove as SubMenu.BulkActionsTagsAbove,
  [BulkActionType.BelowFocusedDoc]: SubMenu.BulkActionsTagsBelow as SubMenu.BulkActionsTagsBelow,
};

export const bulkActionTypeBySubmenu = {
  [SubMenu.BulkActionsTagsAll]: BulkActionType.AllDocs,
  [SubMenu.BulkActionsTagsAbove]: BulkActionType.AboveFocusedDoc,
  [SubMenu.BulkActionsTagsBelow]: BulkActionType.BelowFocusedDoc,
};

export const aboveBelowOrAll = (bulkType: BulkActionType) => {
  if (bulkType === BulkActionType.AboveFocusedDoc) {
    return 'above';
  }

  if (bulkType === BulkActionType.BelowFocusedDoc) {
    return 'below';
  }

  return 'all';
};

type GetInitialFocusedHighlightIdStateFunction = (
  set: NamedSet<FocusedHighlightIdState>,
  get: GetState<FocusedHighlightIdState>,
  api: StoreApi<FocusedHighlightIdState>,
) => FocusedHighlightIdState;

const getInitialFocusedHighlightIdState = (
  ...zustandArguments: Parameters<GetInitialFocusedHighlightIdStateFunction>
): ReturnType<GetInitialFocusedHighlightIdStateFunction> => {
  const [set, get] = zustandArguments;
  const update: FocusedHighlightIdState['update'] = async (
    updater,
  ) => {
    const lastState = get();
    // Produce the nextState of zustand using immer's produce function:
    const nextState = produce(lastState, (draft) => {
      updater(draft);
    });
    set(() => {
      return nextState;
    }, undefined);

  };
  return {
    update,
    focusedHighlightId: null,
  };
};

export const focusedHighlightIdState = create<FocusedHighlightIdState,
  SetState<FocusedHighlightIdState>,
  GetState<FocusedHighlightIdState>,
  StoreApiWithSubscribeWithSelector<FocusedHighlightIdState>>(subscribeWithSelector(
  (...args) => getInitialFocusedHighlightIdState(...args),
));

type GetInitialIsReadingStateFunction = (
  set: NamedSet<IsReadingState>,
  get: GetState<IsReadingState>,
  api: StoreApi<IsReadingState>,
) => IsReadingState;
const getInitialIsReadingState = (
  ...zustandArguments: Parameters<GetInitialIsReadingStateFunction>
): ReturnType<GetInitialIsReadingStateFunction> => {
  const [set, get] = zustandArguments;
  const update: IsReadingState['update'] = async (
    updater,
  ) => {
    const lastState = get();
    // Produce the nextState of zustand using immer's produce function:
    const nextState = produce(lastState, (draft) => {
      updater(draft);
    });
    set(() => {
      return nextState;
    }, undefined);

  };
  return {
    update,
    isReading: false,
  };
};
export const isReadingState = create<IsReadingState,
  SetState<IsReadingState>,
  GetState<IsReadingState>,
  StoreApiWithSubscribeWithSelector<IsReadingState>>(subscribeWithSelector(
  (...args) => getInitialIsReadingState(...args),
));

export const userEventsState = create<UserEventsState>(
  () => ({
    userEvents: [],
  }),
);

export async function addUserEvent(userEvent: UserEvent): Promise<void> {
  userEventsState.setState((state) => ({
    userEvents: [...state.userEvents, userEvent],
  }));
}

export const getDefaultClientState = ({
                                        documentLocations,
                                      }: {
  documentLocations: SettingsState['documentLocations'];
}): ClientState => ({
  theme: DisplayTheme.System,
  recentSearches: [],
  profile: null,
  dailyDigestHomeNudgeDisabled: false,
  rightSidebarHiddenInList: false,
  hideRightPanelOnEnteringReadingView: false,
  hideLeftPanelOnEnteringReadingView: false,
  readerSettings: {
    desktop: {
      fontSize: TshirtSize.M,
      lineHeight: TshirtSize['2XS'],
      lineLength: TshirtSize.M,
      font: WEB_DEFAULT_FONT,
      direction: TextDirection.LeftToRight,
    },
    mobile: {
      fontSize: TshirtSize.XS,
      lineHeight: TshirtSize['2XS'],
      pageWidth: TshirtSize.XL,
      font: MOBILE_DEFAULT_FONT,
      direction: TextDirection.LeftToRight,
      paginationOnByDefaultList: [],
      arePaginationAnimationsDisabled: false,
      arePaginationHapticsOnScrollEnabled: false,
    },
  },
  mobileDeveloperSettings: {
    showReadingViewLoadingTime: false,
    showPdfFileFetchedTime: false,
  },
  quoteshot: {
    currentThemeName: QuoteshotThemeName.Classic,
    currentFont: QuoteshotFont.SansSerif,
    aspectRatio: QuoteshotAspectRatio.Square,
    isDarkMode: false,
    variantForTheme: {
      [QuoteshotThemeName.Classic]: ClassicThemeVariantId.Yellow,
      [QuoteshotThemeName.Fresh]: FreshVariantId.BlueRed,
      [QuoteshotThemeName.Modern]: ModernVariantId.OrangePink,
      [QuoteshotThemeName.Scribble]: ScribbleVariantId.Brush,
      [QuoteshotThemeName.Gradient]: GradientVariantId.Pink,
      [QuoteshotThemeName.Unstyled]: UnstyledVariantId.White,
    },
  },
  listSortRules: DEFAULT_DOCUMENT_LIST_SORT_RULES_STATE,
  largeFeedView: false,
  documents: {},
  askToPasteUrls: true,
  isGhostreaderEnabled: true,
  keepAwakeWhileReading: true,
  mobileHapticsEnabled: true,
  openLinksInApp: true,
  swipeConfig: getDefaultSwipeConfig(documentLocations),
  autoAdvance: false,
  youtube: {
    autoScroll: true,
    playbackRate: 1,
  },
  mobileLastAnnotationSubpanelOpened: undefined,
  mobileSafeAreaInsets: { top: 0, left: 0, right: 0, bottom: 0 },
  mobileTopOffset: 0,
  mobileHomeNavBarHeight: 56,
  sortViewsByKey: TableSortKey.Manual,
  sortViewsByOrder: SortOrder.Desc,
  sortFeedsByKey: TableSortKey.LastUpdated,
  sortFeedsByOrder: SortOrder.Desc,
  sortTagsByKey: TableSortKey.Name,
  sortTagsByOrder: SortOrder.Asc,
  headphoneGestures: {
    previousTrack: HeadphoneAction.JumpBackward,
    nextTrack: HeadphoneAction.JumpForward,
  },
  shouldInvertPDFColors: true,
  mobileShouldUseVolumeButtonsToScrollPages: false,
  mobileAreAnimationsDisabled: false,
});

export const getDefaultFullZustandState = (): Omit<FullZustandState, 'isStateMinimized' | 'update'> => {
  const documentLocations = [
    DocumentLocation.Feed,
    DocumentLocation.New,
    DocumentLocation.Later,
    DocumentLocation.Archive,
  ];

  return {
    areAllDatabaseHooksDisabled: false,
    canUndoAction: false,
    client: getDefaultClientState({ documentLocations }),
    clientStateLoaded: false,
    haveSomeDocumentContentItemsLoaded: false,
    persistentStateLoaded: false,
    areViewsDocumentCountsIndexing: false,
    areFeedsDocumentCountsIndexing: false,
    areServerUpdatesBeingAppliedToForeground: false,
    areServerUpdatesBeingFetchedByUser: false,
    persistentStateLoadingState: PersistentStateLoadingState.HasNotStarted,
    persistentStateTotalDocumentsToAddCount: '0',
    persistentStateNumberOfDocumentsAdded: 0,
    cmdPalette: {
      isOpen: false,
      subMenu: SubMenu.Normal,
      openSubmenus: [],
    },
    quoteshotModalOpen: false,
    quoteshotHighlightId: null,
    documentsListScrolled: false,
    documentSummaryController: null,
    emptyStateCategory: null,
    filterPreviousRoute: '/',
    filterDirtyState: false,
    focusedDocumentId: null,
    focusedDocumentListQuery: null,
    focusedFeedId: null,
    focusedTagId: null,
    focusedViewId: null,
    focusedHighlightId: null,
    gptPromptLoading: false,
    gptPrompt: null,
    highlightIdToOpenAt: null,
    isAppearancePanelShown: false,
    isVideoHeaderShown: true,
    isVideoSettingsPanelShown: false,
    isDeleteDocumentDialogOpen: false,
    isDocMoreActionsDropdownOpen: false,
    isDocumentSummaryGenerating: false,
    isNotebookDropdownOpen: false,
    isEditTagsPopoverShown: false,
    isDocumentMetadataShown: false,
    isDocumentsSortMenuShown: false,
    isInboxZero: false,
    isOnline: true,
    keyboardShortcuts: {},
    leftSidebarHiddenForNarrowScreen: false,
    leftSidebarHiddenInReadingView: false,
    linkActionsUrl: null,
    mobileAppState: 'active',
    mobileActiveTableOfContentsHeadingId: undefined,
    mobileCurrentFocusedDocumentListId: undefined,
    mobileDocumentTableOfContents: [],
    mobileDocumentImageModalData: null,
    mobileFocusedListState: {
      isFilteredView: false,
      isFeedView: false,
      savedFilterView: undefined,
      filterQuery: undefined,
      routeName: '',
    },
    // This refers to LoggedInRootContainer, not the home screen
    mobileIsEmptyListState: false,
    mobileLoggedIn: null,
    mobileIsHomeTabActive: false,
    mobileSelectedFilteredViewQuery: null,
    modal: null,
    networkStatus: NetworkStatus.Offline,
    // Hack to make typescript happy -- it will only be this emptyish value before state is initialized:
    persistent: {
      settings: {
        defaultPage: 'library',
        documentLocations,
        web: {
          isAutoHighlightingEnabled: true,
        },
      },
    } as PersistentState,
    possibleRssFeeds: {},
    rightSidebarHiddenForNarrowScreen: false,
    rightSidebarHiddenInReadingView: false,
    routeStack: [],
    screenWidth: 0,
    tagNamesUsedRecently: [],
    temporarilyCachedFeedSuggestions: null,
    temporarilyCachedFeedSuggestionsSelection: null,
    transientDocumentsData: {},
    tts: null,
    ttsFetchingTimestamp: false,
    // If you need to use the theme in the web app *in JS*, use this. The regular theme value can contain `system`
    webEffectiveTheme: DisplayTheme.Dark,
    zenModeEnabled: false,
    youtube: {
      seekTo: null,
      isPlaying: false,
    },
    filterQueryToCreate: null,
    isPdfSnapToolEnabled: false,
    openedAnnotationBarPopoverHighlightId: null,
    pathNameToRedirectAfterOnboarding: null,
  };
};

type GetInitialStateFunction = (
  set: NamedSet<FullZustandState>,
  get: GetState<FullZustandState>,
  api: StoreApi<FullZustandState>,
) => FullZustandState;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addDevToolsMiddleware = (callback: GetInitialStateFunction): any => {
  if (isZustandDevToolsEnabled) {
    return devtools(callback) as GetInitialStateFunction;
  }
  return callback;
};

/**
 * Represents a pseudo-error that indicates the cancellation of a state update.
 * Most times it's thrown when state is determined to have not changed.
 * @extends Error
 */
export class CancelStateUpdate extends Error {}

const getInitialState = (
  { shouldMinimize }: { shouldMinimize?: boolean; } = {},
  ...zustandArguments: Parameters<GetInitialStateFunction>
): ReturnType<GetInitialStateFunction> => {
  const [set] = zustandArguments;
  const update: FullZustandState['update'] = async (
    updater,
    options,
  ) => {

    let nextState: FullZustandState | undefined;
    let lastState: FullZustandState | undefined;
    // Perform the Zustand state update
    set((draft) => {
      lastState = draft;
      try {
        nextState = produce(draft, updater);
      } catch (error) {
        if (error instanceof CancelStateUpdate) {
          nextState = undefined;
          return draft;
        }
        throw error;
      }

      if (isDevOrTest) { // Safety checks
        if (isEqual(draft, nextState)) {
          logger.warn('updateState: state unchanged in set()', {
            eventName: options.eventName,
          });
        }
      }

      return nextState;
    }, undefined, options.eventName);

    if (nextState === undefined) {
      // We got a signal to cancel update, so early return.
      return { };
    }
    if (!lastState) {
      throw new Error('lastState was not set inside of the set() call');
    }
    // Produce the nextState of zustand using immer's produce function:
    const willChangeAnyState = nextState !== lastState;

    let jsonPatchOperations: {
      forward: jsonpatch.Operation[];
      reverse: jsonpatch.Operation[];
    } | undefined;
    if (willChangeAnyState) {
      const forwardJsonPatchOperations = safeJsonPatchCompareOfState(lastState.persistent, nextState.persistent);
      if (forwardJsonPatchOperations.length) {
        jsonPatchOperations = {
          forward: handleStatePathsThatNeedToBeUpdatedAsAWhole({
            lastState,
            nextState,
            operations: forwardJsonPatchOperations,
          }),
          reverse: safeJsonPatchCompareOfState(nextState.persistent, lastState.persistent),
        };
      }
    }

    let userEventToAddToState: UserEvent | undefined;

    // This function is also used as part of RxDB update flows
    const { userEvent } = await handleStateUpdateSideEffects({
      ...options,
      addUserEventToZustandState: async (userEvent) => {
        userEventToAddToState = userEvent;
      },
      didChangeAnyState: willChangeAnyState,
      jsonPatchOperations,
    });

    if (userEventToAddToState) {
      addUserEvent(userEventToAddToState);
    }

    // Has `handleStateUpdateSideEffects` decided we don't need to go any further?
    if (!userEvent && !willChangeAnyState) {
      return { userEvent };
    }

    // Update the cached client state if needed
    if (willChangeAnyState && nextState.clientStateLoaded) {
      onClientDataChanged(lastState, nextState);
    }
    return { userEvent };
  };

  return {
    isStateMinimized: Boolean(shouldMinimize),
    update,
    ...getDefaultFullZustandState(),
  };
};

export const globalState = create<FullZustandState,
  SetState<FullZustandState>,
  GetState<FullZustandState>,
  StoreApiWithSubscribeWithSelector<FullZustandState>>(subscribeWithSelector(
  addDevToolsMiddleware((...args) => getInitialState({ shouldMinimize: isExtension }, ...args)),
));

const onClientDataChanged = async (lastState: FullZustandState, nextState: FullZustandState) => {
  if (isEqual(lastState.client, nextState.client)) {
    return;
  }
  await background.setCacheItem('clientData', nextState.client);
};

export const recentUserEventsWithDataUpdates: UserEventWithDataUpdate[] = [];

export const getRecentUserEventsWithDataUpdates = (): typeof recentUserEventsWithDataUpdates => recentUserEventsWithDataUpdates;

export const recentUndoneUserEvents: UserEventWithDataUpdate[] = [];

export const updateState = globalState.getState().update;

export type UpdateStateType = typeof updateState;

/**
 * Updates a property in the global Zustand state using the provided value and options.
 * Cancels the state update if the property value is already equal to the provided value.
 * Ensures this state update cannot cause a 'state unchanged' warning.
 */
export function updatePropertyInState<PropertyName extends keyof FullZustandState>(
  propertyName: PropertyName,
  value: FullZustandState[PropertyName],
  options: Parameters<typeof updateState>[1],
): ReturnType<typeof updateState> {
  return updateState((state) => {
    if (isEqual(state[propertyName], value)) {
      throw new CancelStateUpdate();
    }
    state[propertyName] = value;
  }, options);
}

/**
 * Same as above, but for state.client.
 */
export function updatePropertyInClientState<PropertyName extends keyof ClientState>(
  propertyName: PropertyName,
  value: ClientState[PropertyName],
  options: Parameters<typeof updateState>[1],
): ReturnType<typeof updateState> {
  return updateState((state) => {
    if (isEqual(state.client[propertyName], value)) {
      throw new CancelStateUpdate();
    }
    state.client[propertyName] = value;
  }, options);
}


export const updateFocusedHighlightIdState = focusedHighlightIdState.getState().update;
export const updateIsReadingState = isReadingState.getState().update;

export const isStaffProfile = (state: FullZustandState) => {
  const profile = state.client.profile;

  if (!profile) {
    return false;
  }

  return profile.is_staff;
};
// Don't use this to hide anything important. A user could potentially set their account to staff in the front-end.
export const useIsStaffProfile = (): boolean => {
  return globalState(useCallback((state) => isStaffProfile(state), []));
};

export const useDisablePersistentQueryCache = (): boolean => {
  const profile = globalState(useCallback((state) => state.client.profile, []));

  if (!profile || profile.disable_persistent_query_cache === undefined) {
    return true;
  }

  return profile.disable_persistent_query_cache;
};
