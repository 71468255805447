/* eslint-disable no-labels */

import { LogicalOperator, Token, TokenCloseParen, TokenKey, TokenLogicalOperator, TokenOpenParen, TokenType, TokenTypeOperator, TokenValue, TypeOperator } from './types';
import { isNextString } from './utils';

// TODO: change this to actually allow all letters (e.g ñ)
const letters = /[A-Za-z]+/;
const whitespace = ' ';
const typeOperators = Object.values(TypeOperator);
const logicalOperators = Object.values(LogicalOperator);

const isParenthesis = (char: string): boolean => Boolean(char) && (char === '(' || char === ')');
const isLetter = (char: string): boolean => Boolean(char) && letters.test(char);
const isLogicOperator = ({ input, currentIndex }: {input: string; currentIndex: number;}): boolean => {
  if (input.substring(currentIndex - 1, currentIndex) === ' ') {
    return logicalOperators.some((typeOperator) => isNextString({
      input,
      subString: typeOperator,
      currentIndex,
    }) || isNextString({ input, subString: typeOperator.toLocaleLowerCase(), currentIndex }));
  } else {
    return false;
  }
};
const isTypeOperator = ({ input, currentIndex }: {input: string; currentIndex: number;}): boolean => typeOperators.some((typeOperator) => isNextString({ input, subString: typeOperator, currentIndex }));
const isValidCharKey = ({ char, input, currentIndex }: {char: string; input: string; currentIndex: number;}): boolean => Boolean(char) && !isTypeOperator({ input, currentIndex }) && (isLetter(char) || char === '_');
const isValidCharValue = ({ char, input, currentIndex }: {char: string; input: string; currentIndex: number;}): boolean => Boolean(char) && !isLogicOperator({ input, currentIndex }) && char !== '"' && !isParenthesis(char);
const lowercaseFirstLetter = (string: string): string => string.charAt(0).toLocaleLowerCase() + string.slice(1);

export function tokenizer(_input: string): {tokens: Token[]; errorMessage: string;} {
  const input = _input.replaceAll('“', '"').replaceAll('”', '"');

  let currentIndex = 0;
  const tokens: Token[] = [];

  if (!input) {
    return { tokens, errorMessage: 'Please, enter a filter query' };
  }

  try {
    outerWhile:
  while (currentIndex < input.length) {
    let char = input[currentIndex];

    const openParen = '(';
    if (char === openParen) {
      tokens.push({
        type: TokenType.OpenParen,
        value: openParen,
      } as TokenOpenParen);

      currentIndex++;
      continue;
    }

    const closeParen = ')';
    if (char === closeParen) {
      tokens.push({
        type: TokenType.CloseParen,
        value: closeParen,
      } as TokenCloseParen);
      currentIndex++;
      continue;
    }

    if (char === whitespace) {
      currentIndex++;
      continue;
    }

    for (const typeOperator of typeOperators) {
      if (isNextString({ input, subString: typeOperator, currentIndex })) {
        tokens.push({
          type: TokenType.TypeOperator,
          value: typeOperator,
        } as TokenTypeOperator);

        currentIndex = currentIndex + typeOperator.length;

        char = input[currentIndex];

        // Handle case where value is without quotes. E.g: author:Rick Sanchez
        if (isValidCharValue({ char, input, currentIndex })) {
          let substring = '';

          while (isValidCharValue({ char, input, currentIndex })) {
            substring += char;
            char = input[++currentIndex];
          }

          tokens.push({
            type: TokenType.Value,
            value: substring.trim(),
          } as TokenValue);

          continue outerWhile;
        }

        continue outerWhile;
      }
    }

    for (const logicalOperator of logicalOperators) {
      if (isNextString({ input, subString: logicalOperator, currentIndex }) || isNextString({ input, subString: logicalOperator.toLocaleLowerCase(), currentIndex })) {
        const spaceCheck = input.substring(currentIndex - 1, currentIndex);
        if (spaceCheck === ' ') {
          tokens.push({
            type: TokenType.LogicOperator,
            value: logicalOperator,
          } as TokenLogicalOperator);

          currentIndex = currentIndex + logicalOperator.length;

          continue outerWhile;
        }
      }
    }

    if (isValidCharKey({ char, input, currentIndex })) {
      let substring = '';

      while (isValidCharKey({ char, input, currentIndex })) {
        substring += char;
        char = input[++currentIndex];
      }

      tokens.push({
        type: TokenType.Key,
        value: lowercaseFirstLetter(substring),
      } as TokenKey);

      continue;
    }

    if (char === '"') {
      let substring = '';

      // We'll skip the opening double quote in our token.
      char = input[++currentIndex];

      while (char !== '"') {
        substring += char;
        char = input[++currentIndex];
      }

      // Skip the closing double quote.
      char = input[++currentIndex];

      tokens.push({ type: 'value', value: substring } as TokenValue);
      continue;
    }

    // End of query
    if (!char) {
      break;
    }

    // If we have not matched a character by now show error
    throw new Error(`Unexpected character ${char} after ${input.slice(0, currentIndex)}`);
  }
    return { tokens, errorMessage: '' };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { tokens, errorMessage: error.message };
  }
}
