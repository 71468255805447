import type { RxCollection } from 'rxdb';

import type { DatabaseUpsertTransformer } from '../../../../types/database';
import logger from '../../logger';

export default function getUnknownRootKeysTransformer(collection: RxCollection): DatabaseUpsertTransformer {
  const knownRootKeys = Object.keys(collection.schema.jsonSchema.properties)
    .filter((key) => !key.startsWith('_'));

  logger.debug('[upsertTransformers] getUnknownRootKeysTransformer', {
    collectionName: collection.name,
    knownRootKeys,
  });

  return function unknownRootKeysTransformer(plainData: object) {
    for (const key of Object.keys(plainData)) {
      if (key.startsWith('_') || knownRootKeys.includes(key)) {
        continue;
      }
      delete plainData[key];
    }
  };
}
