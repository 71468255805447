import React from 'react';

import Icon from './Icon';

export default function InfoIcon({ text = 'information' }: { text?: string; } = {}): JSX.Element {
  return <Icon
    text={text}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <path
        fill="#fff"
        d="M26,0.654C12.002,0.654,0.654,12.002,0.654,26c0,14,11.349,25.346,25.347,25.346C40,51.346,51.346,40,51.346,26  C51.346,12.002,40,0.654,26,0.654z M27.072,10.68c1.84,0,3.331,1.492,3.331,3.332s-1.491,3.332-3.331,3.332  s-3.332-1.492-3.332-3.332S25.232,10.68,27.072,10.68z M30.059,40.16c0,0-2.625,2.201-5.932,0.541  c-0.734-0.367-1.354-1.004-1.75-1.705c-1.123-1.986-0.691-4.117-0.691-4.117l0.33-2.613l0.857-6.814l-2.334,0.051  c-1.093,0.025-1.995-0.842-2.019-1.934c-0.019-0.848,0.506-1.584,1.252-1.881l5.981-2.355c1.424-0.561,3.032,0.139,3.592,1.563  c0.214,0.541,0.245,1.105,0.123,1.631l-0.079,0.355l-2.339,10.271l-0.584,2.568c-0.086,0.387-0.099,0.475-0.124,0.678  c-0.063,1.156,1.581,0.289,1.581,0.289c0.987-0.545,2.229-0.184,2.771,0.803C31.205,38.418,30.909,39.572,30.059,40.16z"
      />
    </svg>
  </Icon>;
}
