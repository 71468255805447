import React from 'react';

import Icon from './Icon';

export default function ErrorIcon({ text = 'error' }: { text?: string; } = {}): JSX.Element {
  return <Icon
    text={text}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
      <path
        fill="white"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95 50C95 74.8528 74.8528 95 50 95C25.1472 95 5 74.8528 5 50C5 25.1472 25.1472 5 50 5C74.8528 5 95 25.1472 95 50ZM56 71C56 74.3137 53.3137 77 50 77C46.6863 77 44 74.3137 44 71C44 67.6863 46.6863 65 50 65C53.3137 65 56 67.6863 56 71ZM50 19C46.6863 19 44 21.6863 44 25V55C44 58.3137 46.6863 61 50 61C53.3137 61 56 58.3137 56 55V25C56 21.6863 53.3137 19 50 19Z"
      />
    </svg>
  </Icon>;
}
