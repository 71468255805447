/*
  This contains functions which update the state argument they're given.
  They are not actions which call updateState. Actions would call these functions.
  Convention: the exposed functions have `InState` / `FromState` / `ToState` in the name.
*/

import { ulid } from 'ulid';

import {
  AnyDocument,
  DocumentLocation,
  EditableKeys,
  FilteredView,
  FirstClassDocument,
  FullZustandState,
  ReadingPosition,
  SplitByKey,
} from '../types';
import { isDocumentWithAuthor, isDocumentWithLanguage, isDocumentWithPublishedDate, isDocumentWithSummary, isDocumentWithTitle } from '../typeValidators';
import nowTimestamp from '../utils/dates/nowTimestamp';
// eslint-disable-next-line import/no-cycle
import ttsController from './actions/ttsController.platform';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_DOCUMENT_SORT_RULES, globalState } from './models';
import { setCurrentlyReading } from './stateUpdaters/persistentStateUpdaters/other';

export const saveFilteredViewToState = (
  state: FullZustandState,
  filter: Omit<FilteredView, 'id' | 'created' | 'sortRules'>,
  pathname = '',
): void => {
  const id = ulid().toLowerCase();
  let splitBy: SplitByKey | undefined;

  if (pathname.includes('/split/')) {
    splitBy = pathname.split('/split/')[1].split('/')[0] as SplitByKey;
  }

  const currentSortRule = DEFAULT_DOCUMENT_SORT_RULES.filterView[0];

  state.persistent.filteredViews[id] = {
    ...filter,
    id,
    created: nowTimestamp(),
    order: Infinity,
    sortRules: [{
      id: ulid().toLowerCase(),
      key: currentSortRule.key,
      order: currentSortRule.order,
    }],
  };

  if (splitBy) {
    state.persistent.filteredViews[id].splitBy = splitBy;
  }
};

export const removeFilteredViewFromState = (
  state: FullZustandState,
  viewId: FilteredView['id'],
): void => {
  if (state.persistent.home?.views) {
    const indexInHomeViews = state.persistent.home.views.indexOf(viewId);
    if (indexInHomeViews >= 0) {
      state.persistent.home.views.splice(indexInHomeViews, 1);
    }
  }

  if (state.persistent.filteredViews) {
    delete state.persistent.filteredViews[viewId];
  }
};

export const updateFilteredViewInState = ({
  shouldErrorIfItDoesntExist = true,
  state,
  updates,
  viewId,
}: {
  shouldErrorIfItDoesntExist?: boolean;
  state: FullZustandState;
  updates: Partial<FilteredView>;
  viewId: FilteredView['id'];
}): void => {
  if (!state.persistent.filteredViews[viewId]) {
    if (shouldErrorIfItDoesntExist) {
      throw new Error('No view with that ID in state');
    }
    return;
  }

  state.persistent.filteredViews[viewId] = Object.assign(state.persistent.filteredViews[viewId], updates);
};

export const updateDocumentLocationOfDoc = (
  document: FirstClassDocument,
  newDocumentLocation: DocumentLocation,
  shouldUpdateLastStatusUpdate = true,
): void => {
  const now = nowTimestamp();

  if (document.triage_status === DocumentLocation.Feed && newDocumentLocation !== DocumentLocation.Feed) {
    document.saved_from_feed_at = now;
  }

  document.triage_status = newDocumentLocation;
  if (shouldUpdateLastStatusUpdate) {
    document.last_status_update = now;
  }

  // Stop playing tts on that doc
  if (newDocumentLocation === DocumentLocation.Archive && globalState.getState().tts?.playingDocId === document.id) {
    setTimeout(() => {
      ttsController.stop();
      setCurrentlyReading(null, { userInteraction: null });
    }, 50);
  }
};

export const resetKeyboardShortcutsFromState = (
  state: FullZustandState,
): void => {
  if (state.persistent.customKeyboardShortcuts) {
    state.persistent.customKeyboardShortcuts = {};
  }

  if (state.persistent.keyboardShortcutsBlackList) {
    state.persistent.keyboardShortcutsBlackList = [];
  }
};

export const updateFeedTitleFromState = ({
  state,
  feedId,
  title,
}: { state: FullZustandState; feedId: string; title: string; }): void => {
  if (!state.persistent.rssFeeds) {
    throw new Error('Feeds do not exist');
  }

  const feed = state.persistent.rssFeeds[feedId];

  if (!feed) {
    throw new Error('Feed does not exist');
  }

  feed.name = title;
};

export const updateFeedDescriptionFromState = ({
  state,
  feedId,
  description,
}: { state: FullZustandState; feedId: string; description: string; }): void => {
  if (!state.persistent.rssFeeds) {
    throw new Error('Feeds do not exist');
  }

  const feed = state.persistent.rssFeeds[feedId];

  if (!feed) {
    throw new Error('Feed does not exist');
  }

  feed.description = description;
};

export const overrideTitleInDocData = (doc: AnyDocument, title: EditableKeys['title']): void => {
  if (!isDocumentWithTitle(doc)) {
    throw new Error('Document is not a DocumentWithTitle');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.title = title;
};

export const overrideGeneratedSummaryInDocData = (doc: AnyDocument, generatedSummary: EditableKeys['generated_summary']): void => {
  if (!isDocumentWithSummary(doc)) {
    throw new Error('Document is not a DocumentWithSummary');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  if (generatedSummary === undefined) {
    delete doc.overrides.generated_summary;
  } else {
    doc.overrides.generated_summary = generatedSummary;
  }
};

export const overrideSummaryInDocData = (doc: AnyDocument, summary: EditableKeys['summary']): void => {
  if (!isDocumentWithSummary(doc)) {
    throw new Error('Document is not a DocumentWithSummary');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.summary = summary;
};

export const overrideCategoryInDocData = (doc: AnyDocument, category: EditableKeys['category']): void => {
  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.category = category;
};

export const overrideLanguageInDocData = (doc: AnyDocument, language: EditableKeys['language']): void => {
  if (!isDocumentWithLanguage(doc)) {
    throw new Error('Document is not a DocumentWithLanguage');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.language = language;
};

export const overrideAuthorInDocData = (doc: AnyDocument, author: EditableKeys['author']): void => {
  if (!isDocumentWithAuthor(doc)) {
    throw new Error('Document is not a DocumentWithAuthor');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.author = author;
};

export const overrideCoverImageInDocData = (doc: AnyDocument, coverImageUrl: EditableKeys['image_url']): void => {
  if (!isDocumentWithSummary(doc)) {
    throw new Error('Document is not a DocumentWithSummary');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.image_url = coverImageUrl;
};

export const overridePublishedDateInDocData = (doc: AnyDocument, publishedDate: EditableKeys['published_date']): void => {
  if (!isDocumentWithPublishedDate(doc)) {
    throw new Error('Document is not a DocumentWithPublishedDate');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.published_date = publishedDate;
};

export const updateReadingPositionInDocData = (doc: AnyDocument, position: ReadingPosition, options: {force: boolean;}) => {
  if (doc.readingPosition) {
    if ((doc.readingPosition.scrollDepth ?? 0) < position.scrollDepth || options.force) {
      doc.readingPosition = position;
    }
  } else {
    doc.readingPosition = position;
  }
};
