import type { RxCollection } from 'rxdb';

import type { DatabaseUpsertTransformer } from '../../../types/database';
import getBooleanTransformer from './upsertTransformers/getBooleanTransformer';
import getComputedForRxDBOnlyTransformer from './upsertTransformers/getComputedForRxDBOnlyTransformer';
import getUnknownRootKeysTransformer from './upsertTransformers/getUnknownRootKeysTransformer';

export default function prepareUpsertTransformers(
  collection: RxCollection,
): DatabaseUpsertTransformer {
  // The order matters
  const transformers: DatabaseUpsertTransformer[] = [
    getUnknownRootKeysTransformer,
    getComputedForRxDBOnlyTransformer,
    getBooleanTransformer,
  ].map((tranformersGetter) => tranformersGetter(collection));

  return function upsertTransformer(plainData) {
    for (const transformer of transformers) {
      transformer(plainData);
    }
    return plainData;
  };
}
