import { isDesktopApp } from '../utils/environment';
import { BrowserSqliteDatabase } from './sqliteDatabase.browser';
import { DesktopSqliteDatabase } from './sqliteDatabase.desktop';

export type SqliteValue = string | number | null;
export type GenericSqliteRow = { [column: string]: SqliteValue; };

export interface ISqliteDatabase {
  // Load database from disk if !forceNewDb
  init(filename: string, forceNewDb: boolean): Promise<void>;
  execute(query: string, params?: SqliteValue[]): Promise<void>;
  select<Row extends GenericSqliteRow = GenericSqliteRow>(query: string, params?: SqliteValue[]): Promise<Row[]>;
  // Persist database contents to disk
  save(): Promise<void>;
}

export function newSqliteDatabase(): ISqliteDatabase {
  if (isDesktopApp) {
    return new DesktopSqliteDatabase();
  } else {
    return new BrowserSqliteDatabase();
  }
}
