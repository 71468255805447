// eslint-disable-next-line import/no-cycle
import type { DocumentWithOptionalTransientData, FirstClassDocument } from '../../types';
import { ContentParsingStatus } from '../../types';

export function documentShouldForceContentLoad(
  doc: DocumentWithOptionalTransientData<FirstClassDocument>,
): boolean {
  return doc.transientData !== undefined &&
    [
      ContentParsingStatus.Pending,
      ContentParsingStatus.ServerTaskNotStartedYet,
    ].includes(doc.transientData.contentParsingStatus);
}
