import type { TransientDocumentData } from '../types';
import { ContentParsingStatus, ContentRequestLoadingStatus } from '../types';

export default function createInitialTransientDocumentData(
  incomingMetadata: Partial<TransientDocumentData> = {},
): TransientDocumentData {
  return {
    contentParsingStatus: ContentParsingStatus.ServerTaskNotStartedYet,
    contentRequestLoadingStatus: incomingMetadata.content ? ContentRequestLoadingStatus.Loaded : ContentRequestLoadingStatus.Unloaded,
    links: [],
    ttsParsingStatus: ContentParsingStatus.ServerTaskNotStartedYet,
    ...incomingMetadata,
  };
}
