/* eslint-disable @typescript-eslint/naming-convention */

// Generated by generate_language_jsons_for_rxdb_query.py
export const SuffixesForLangCode: { [langCode: string]: string[]; } = {
  a3: [
    'az',
    'az.koic',
  ],
  aa: [
    'dj',
    'er',
    'et',
  ],
  af: [
    'za',
  ],
  agr: [
    'pe',
  ],
  ak: [
    'gh',
  ],
  am: [
    'et',
  ],
  an: [
    'es',
  ],
  anp: [
    'in',
  ],
  ar: [
    'aa',
    'ae',
    'bh',
    'dz',
    'eg',
    'in',
    'iq',
    'jo',
    'kw',
    'lb',
    'ly',
    'ma',
    'om',
    'qa',
    'sa',
    'sd',
    'ss',
    'sy',
    'tn',
    'ye',
  ],
  as: [
    'in',
  ],
  ast: [
    'es',
  ],
  ayc: [
    'pe',
  ],
  az: [
    'az',
    'ir',
  ],
  be: [
    'by',
    'by@latin',
  ],
  bem: [
    'zm',
  ],
  ber: [
    'dz',
    'ma',
  ],
  bg: [
    'bg',
  ],
  bhb: [
  ],
  bho: [
    'in',
    'np',
  ],
  bi: [
    'vu',
  ],
  bn: [
    'bd',
    'in',
  ],
  bo: [
    'cn',
    'in',
  ],
  br: [
    'fr',
  ],
  brx: [
    'in',
  ],
  bs: [
    'ba',
  ],
  byn: [
    'er',
  ],
  c: [
    'c',
    'c.c',
  ],
  ca: [
    'ad',
    'es',
    'es@valencia',
    'fr',
    'it',
  ],
  ce: [
    'ru',
  ],
  chr: [
    'us',
  ],
  ckb: [
    'iq',
  ],
  cmn: [
    'tw',
  ],
  crh: [
    'ua',
  ],
  cs: [
    'cs',
    'cz',
  ],
  csb: [
    'pl',
  ],
  cv: [
    'ru',
  ],
  cy: [
    'gb',
  ],
  cz: [
    'cz',
  ],
  da: [
    'dk',
  ],
  de: [
    'at',
    'be',
    'ch',
    'de',
    'it',
    'lu',
  ],
  doi: [
    'in',
  ],
  dv: [
    'mv',
  ],
  dz: [
    'bt',
  ],
  ee: [
    'ee',
  ],
  el: [
    'cy',
    'gr',
    'gr@euro',
  ],
  en: [
    'ag',
    'au',
    'be',
    'bw',
    'ca',
    'dk',
    'gb',
    'hk',
    'ie',
    'il',
    'in',
    'ng',
    'nz',
    'ph',
    'sg',
    'uk',
    'us',
    'za',
    'zm',
    'zw',
  ],
  eng: [
    'gb',
  ],
  english: [
    'uk',
    'united-states',
    'united-states.437',
    'us',
  ],
  eo: [
    'eo',
    'xx',
  ],
  es: [
    'ar',
    'bo',
    'cl',
    'co',
    'cr',
    'cu',
    'do',
    'ec',
    'es',
    'gt',
    'hn',
    'mx',
    'ni',
    'pa',
    'pe',
    'pr',
    'py',
    'sv',
    'us',
    'uy',
    've',
  ],
  et: [
    'ee',
  ],
  eu: [
    'es',
    'fr',
  ],
  fa: [
    'ir',
    'ir.isiri3342',
  ],
  ff: [
    'sn',
  ],
  fi: [
    'fi',
  ],
  fil: [
    'ph',
  ],
  fo: [
    'fo',
  ],
  fr: [
    'be',
    'ca',
    'ch',
    'fr',
    'lu',
  ],
  fre: [
    'fr',
  ],
  french: [
    'france',
  ],
  fur: [
    'it',
  ],
  fy: [
    'de',
    'nl',
  ],
  ga: [
    'ie',
  ],
  gd: [
    'gb',
  ],
  ger: [
    'de',
  ],
  german: [
    'germany',
  ],
  gez: [
    'er',
    'et',
  ],
  gl: [
    'es',
  ],
  gu: [
    'in',
  ],
  gv: [
    'gb',
  ],
  ha: [
    'ng',
  ],
  hak: [
    'tw',
  ],
  he: [
    'il',
  ],
  hi: [
    'in',
    'in.isciidev',
  ],
  hif: [
    'fj',
  ],
  hne: [
    'in',
  ],
  hr: [
    'hr',
  ],
  hsb: [
    'de',
  ],
  ht: [
    'ht',
  ],
  hu: [
    'hu',
  ],
  hy: [
    'am',
    'am.armscii8',
  ],
  ia: [
    'fr',
  ],
  id: [
    'id',
  ],
  ig: [
    'ng',
  ],
  ik: [
    'ca',
  ],
  in: [
    'id',
  ],
  is: [
    'is',
  ],
  iso: [
    '8859',
    '8859',
  ],
  it: [
    'ch',
    'it',
  ],
  iu: [
    'ca',
    'ca.nunacom8',
  ],
  iw: [
    'il',
  ],
  ja: [
    'jp',
    'jp.euc',
    'jp.mscode',
    'jp.pck',
  ],
  jp: [
    'jp',
  ],
  ka: [
    'ge',
    'ge.georgianacademy',
    'ge.georgianps',
    'ge.georgianrs',
  ],
  kab: [
    'dz',
  ],
  kk: [
    'kz',
  ],
  kl: [
    'gl',
  ],
  km: [
    'kh',
  ],
  kn: [
    'in',
  ],
  ko: [
    'kr',
    'kr.euc',
  ],
  kok: [
    'in',
  ],
  ks: [
    'in',
  ],
  ku: [
    'tr',
  ],
  kw: [
    'gb',
  ],
  ky: [
    'kg',
  ],
  lb: [
    'lu',
  ],
  lg: [
    'ug',
  ],
  li: [
    'be',
    'nl',
  ],
  lij: [
    'it',
  ],
  ln: [
    'cd',
  ],
  lo: [
    'la',
    'la.cp1133',
    'la.ibmcp1133',
    'la.mulelao1',
  ],
  lt: [
    'lt',
  ],
  lv: [
    'lv',
  ],
  lzh: [
    'tw',
  ],
  mag: [
    'in',
  ],
  mai: [
    'in',
    'np',
  ],
  mfe: [
    'mu',
  ],
  mg: [
    'mg',
  ],
  mhr: [
    'ru',
  ],
  mi: [
    'nz',
  ],
  miq: [
    'ni',
  ],
  mjw: [
    'in',
  ],
  mk: [
    'mk',
  ],
  ml: [
    'in',
  ],
  mn: [
    'mn',
  ],
  mni: [
    'in',
  ],
  mr: [
    'in',
  ],
  ms: [
    'my',
  ],
  mt: [
    'mt',
  ],
  my: [
    'mm',
  ],
  nan: [
    'tw',
  ],
  nb: [
    'no',
  ],
  nds: [
    'de',
    'nl',
  ],
  ne: [
    'np',
  ],
  nhn: [
    'mx',
  ],
  niu: [
    'nu',
    'nz',
  ],
  nl: [
    'aw',
    'be',
    'nl',
  ],
  nn: [
    'no',
  ],
  no: [
    'no',
  ],
  nr: [
    'za',
  ],
  nso: [
    'za',
  ],
  ny: [
    'no',
  ],
  oc: [
    'fr',
  ],
  om: [
    'et',
    'ke',
  ],
  or: [
    'in',
  ],
  os: [
    'ru',
  ],
  pa: [
    'in',
    'pk',
  ],
  pap: [
    'an',
    'aw',
    'cw',
  ],
  pd: [
    'de',
    'us',
  ],
  ph: [
    'ph',
  ],
  pl: [
    'pl',
  ],
  portuguese: [
    'brazil',
  ],
  pp: [
    'an',
  ],
  ps: [
    'af',
  ],
  pt: [
    'br',
    'pt',
  ],
  quz: [
    'pe',
  ],
  raj: [
    'in',
  ],
  ro: [
    'ro',
  ],
  ru: [
    'ru',
    'ua',
  ],
  rw: [
    'rw',
  ],
  sa: [
    'in',
  ],
  sat: [
    'in',
  ],
  sc: [
    'it',
  ],
  sd: [
    'in',
    'pk',
  ],
  se: [
    'no',
  ],
  sgs: [
    'lt',
  ],
  sh: [
    'hr',
    'sp',
    'yu',
  ],
  shn: [
    'mm',
  ],
  shs: [
    'ca',
  ],
  si: [
    'lk',
  ],
  sid: [
    'et',
  ],
  sk: [
    'sk',
  ],
  sl: [
    'cs',
    'si',
  ],
  sm: [
    'ws',
  ],
  so: [
    'dj',
    'et',
    'ke',
    'so',
  ],
  sp: [
    'yu',
  ],
  spanish: [
    'spain',
  ],
  sq: [
    'al',
    'mk',
  ],
  sr: [
    'cs',
    'me',
    'rs',
    'sp',
    'yu',
  ],
  ss: [
    'za',
  ],
  st: [
    'za',
  ],
  sv: [
    'fi',
    'se',
  ],
  sw: [
    'ke',
    'tz',
  ],
  szl: [
    'pl',
  ],
  ta: [
    'in',
    'lk',
  ],
  tcy: [
  ],
  te: [
    'in',
  ],
  tg: [
    'tj',
  ],
  th: [
    'th',
    'th.tactis',
    'th.tis620',
  ],
  the: [
    'np',
  ],
  ti: [
    'er',
    'et',
  ],
  tig: [
    'er',
  ],
  tk: [
    'tm',
  ],
  tl: [
    'ph',
  ],
  tn: [
    'za',
  ],
  to: [
    'to',
  ],
  tpi: [
    'pg',
  ],
  tr: [
    'cy',
    'tr',
  ],
  ts: [
    'za',
  ],
  tt: [
    'ru',
    'ru.tatarcyr',
    'ru@iqtelif',
  ],
  ug: [
    'cn',
  ],
  uk: [
    'ua',
  ],
  unm: [
    'us',
  ],
  ur: [
    'in',
    'pk',
  ],
  uz: [
    'uz',
  ],
  ve: [
    'za',
  ],
  vi: [
    'vn',
    'vn.tcvn',
    'vn.tcvn5712',
    'vn.viscii',
    'vn.viscii111',
  ],
  wa: [
    'be',
  ],
  wae: [
    'ch',
  ],
  wal: [
    'et',
  ],
  wo: [
    'sn',
  ],
  xh: [
    'za',
  ],
  yi: [
    'us',
  ],
  yo: [
    'ng',
  ],
  yue: [
    'hk',
  ],
  yuw: [
    'pg',
  ],
  zh: [
    'cn',
    'cn.big5',
    'cn.euc',
    'hk',
    'hk.big5hk',
    'sg',
    'sg.gbk',
    'tw',
    'tw.euc',
    'tw.euctw',
    'yue',
  ],
  zu: [
    'za',
  ],
};

export const LangCodeForLanguageName: { [languageName: string]: string; } = {
  afrikaans: 'af',
  amharic: 'am',
  አማርኛ: 'am',
  arabic: 'ar',
  العربية: 'ar',
  assamese: 'as',
  অসমীয়া: 'as',
  azerbaijani: 'az',
  azərbaycan: 'az',
  belarusian: 'be',
  беларуская: 'be',
  bulgarian: 'bg',
  български: 'bg',
  breton: 'br',
  brezhoneg: 'br',
  bosnian: 'bs',
  bosanski: 'bs',
  catalan: 'ca',
  català: 'ca',
  czech: 'cs',
  čeština: 'cs',
  welsh: 'cy',
  cymraeg: 'cy',
  danish: 'da',
  dansk: 'da',
  german: 'de',
  deutsch: 'de',
  ewe: 'ee',
  eʋegbe: 'ee',
  greek: 'el',
  ελληνικά: 'el',
  english: 'en',
  esperanto: 'eo',
  spanish: 'es',
  español: 'es',
  estonian: 'et',
  eesti: 'et',
  basque: 'eu',
  euskara: 'eu',
  persian: 'fa',
  فارسی: 'fa',
  finnish: 'fi',
  suomi: 'fi',
  faroese: 'fo',
  føroyskt: 'fo',
  french: 'fr',
  français: 'fr',
  irish: 'ga',
  gaeilge: 'ga',
  'scottish gaelic': 'gd',
  gàidhlig: 'gd',
  galician: 'gl',
  galego: 'gl',
  manx: 'gv',
  gaelg: 'gv',
  hebrew: 'iw',
  עברית: 'he',
  hindi: 'hi',
  हिन्दी: 'hi',
  chhattisgarhi: 'hne',
  croatian: 'hr',
  hrvatski: 'hr',
  hungarian: 'hu',
  magyar: 'hu',
  interlingua: 'ia',
  indonesian: 'in',
  'bahasa indonesia': 'id',
  icelandic: 'is',
  íslenska: 'is',
  italian: 'it',
  italiano: 'it',
  inuktitut: 'iu',
  japanese: 'ja',
  日本語: 'ja',
  georgian: 'ka',
  ქართული: 'ka',
  kalaallisut: 'kl',
  kannada: 'kn',
  ಕನ್ನಡ: 'kn',
  korean: 'ko',
  한국어: 'ko',
  kashmiri: 'ks',
  کٲشُر: 'ks',
  cornish: 'kw',
  kernewek: 'kw',
  kyrgyz: 'ky',
  кыргызча: 'ky',
  lao: 'lo',
  ລາວ: 'lo',
  lithuanian: 'lt',
  lietuvių: 'lt',
  latvian: 'lv',
  latviešu: 'lv',
  maithili: 'mai',
  मैथिली: 'mai',
  māori: 'mi',
  'te reo m\u0101ori': 'mi',
  macedonian: 'mk',
  македонски: 'mk',
  malayalam: 'ml',
  മലയാളം: 'ml',
  marathi: 'mr',
  मराठी: 'mr',
  malay: 'ms',
  'bahasa malaysia': 'ms',
  maltese: 'mt',
  malti: 'mt',
  'norwegian bokm\u00e5l': 'nb',
  'norsk bokm\u00e5l': 'nb',
  dutch: 'nl',
  nederlands: 'nl',
  'norwegian nynorsk': 'nn',
  'norsk nynorsk': 'nn',
  norwegian: 'no',
  norsk: 'no',
  'south ndebele': 'nr',
  'northern sotho': 'nso',
  nyanja: 'ny',
  occitan: 'oc',
  odia: 'or',
  ଓଡ଼ିଆ: 'or',
  punjabi: 'pa',
  ਪੰਜਾਬੀ: 'pa',
  polish: 'pl',
  polski: 'pl',
  portuguese: 'pt',
  português: 'pt',
  romanian: 'ro',
  română: 'ro',
  russian: 'ru',
  русский: 'ru',
  kinyarwanda: 'rw',
  sindhi: 'sd',
  سنڌي: 'sd',
  'serbo-croatian': 'sh',
  srpskohrvatski: 'sh',
  sinhala: 'si',
  සිංහල: 'si',
  slovak: 'sk',
  slovenčina: 'sk',
  slovenian: 'sl',
  slovenščina: 'sl',
  albanian: 'sq',
  shqip: 'sq',
  serbian: 'sr',
  српски: 'sr',
  swati: 'ss',
  'southern sotho': 'st',
  swedish: 'sv',
  svenska: 'sv',
  tamil: 'ta',
  தமிழ்: 'ta',
  telugu: 'te',
  తెలుగు: 'te',
  tajik: 'tg',
  тоҷикӣ: 'tg',
  thai: 'th',
  ไทย: 'th',
  tagalog: 'tl',
  tswana: 'tn',
  turkish: 'tr',
  türkçe: 'tr',
  tsonga: 'ts',
  tatar: 'tt',
  татар: 'tt',
  ukrainian: 'uk',
  українська: 'uk',
  urdu: 'ur',
  اردو: 'ur',
  uzbek: 'uz',
  'o\u2018zbek': 'uz',
  venda: 've',
  tshivenḓa: 've',
  vietnamese: 'vi',
  'ti\u1ebfng vi\u1ec7t': 'vi',
  walloon: 'wa',
  xhosa: 'xh',
  isixhosa: 'xh',
  yiddish: 'yi',
  ייִדיש: 'yi',
  chinese: 'zh',
  中文: 'zh',
  zulu: 'zu',
  isizulu: 'zu',
};
