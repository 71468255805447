import {
  type TextToSpeechSettings,
  type TextToSpeechTrack,
  TrackPlayerState,
} from '../../types/tts';
import { DeferredPromise } from '../../utils/DeferredPromise';
import { isTest } from '../../utils/environment';
import makeLogger from '../../utils/makeLogger';
import { globalState } from '../models';
import { textToSpeechDefaultPlaybackRate, textToSpeechDefaultVolume } from '../utils/tts';
import AbstractTtsController from './AbstractTtsController';

const logger = makeLogger(__filename);

class WebTtsController extends AbstractTtsController {
  trackPlayerCreationPromise = new DeferredPromise<void>();

  _audioElement: HTMLAudioElement;
  _isBetweenLoadStartAndLoadedDataEvents = false;
  _pendingPlayPromise: Promise<void> | null = null;

  constructor() {
    super();

    this._audioElement = document.createElement('audio');
    if (isTest) {
      return;
    }

    this._audioElement.id = 'tts-player';
    this._audioElement.controls = false;
    this._audioElement.preload = 'none';
    this._applySettingsToTrackPlayer();

    this._audioElement.addEventListener('loadstart', () => {
      this._isBetweenLoadStartAndLoadedDataEvents = true;
    });
    this._audioElement.addEventListener('loadeddata', () => {
      this._isBetweenLoadStartAndLoadedDataEvents = false;
    });
    this._syncSettingsToTrackPlayer();

    document.body.appendChild(this._audioElement);

    logger.debug('audio element created');
    this.trackPlayerCreationPromise.resolve();
  }

  async _addTrackToTrackPlayer(track: TextToSpeechTrack) {
    logger.debug('_addTrackToTrackPlayer', { track });
    await this._pendingPlayPromise;
    this._audioElement.src = track.url;
    this._applySettingsToTrackPlayer();
  }

  _applySettingsToTrackPlayer(
    settingsArgument?: TextToSpeechSettings,
  ) {
    const ttsSettings = settingsArgument ?? this._getSettings();
    logger.debug('_applySettingsToTrackPlayer', ttsSettings);
    this._audioElement.playbackRate = ttsSettings?.playbackRate ?? textToSpeechDefaultPlaybackRate;
    this._audioElement.volume = ttsSettings?.volume ?? textToSpeechDefaultVolume;
  }

  async _getTrackPlayerPosition() {
    logger.debug('_getTrackPlayerPosition');
    return this._audioElement.currentTime;
  }

  async _getTrackPlayerState() {
    let state: TrackPlayerState;
    if (!this._audioElement.src) {
      state = TrackPlayerState.Off;
    } else if (this._audioElement.paused) {
      state = TrackPlayerState.Paused;
    } else if (this._isBetweenLoadStartAndLoadedDataEvents) {
      state = TrackPlayerState.Loading;
    } else {
      state = TrackPlayerState.Playing;
    }
    logger.debug('_getTrackPlayerState', { state });
    return state;
  }

  async _pauseTrackPlayer() {
    logger.debug('_pauseTrackPlayer');
    await this._pendingPlayPromise;
    this._audioElement.pause();
  }

  async _resetTrackPlayer() {
    logger.debug('_resetTrackPlayer');
    await this._pendingPlayPromise;
    await this._pauseTrackPlayer();
    this._audioElement.src = '';
    this._isBetweenLoadStartAndLoadedDataEvents = false;
    this._applySettingsToTrackPlayer();
  }

  async _seekTrackPlayer(position: number) {
    logger.debug('_seekTrackPlayer', { position });
    this._audioElement.currentTime = position;
  }

  async _startPlayingTrackPlayer() {
    logger.debug('_startPlayingTrackPlayer');
    if (!this._pendingPlayPromise) {
      this._pendingPlayPromise = this._audioElement.play()
        .then(() => {
          this._pendingPlayPromise = null;
        });
    }
    return this._pendingPlayPromise;
  }

  _syncSettingsToTrackPlayer() {
    globalState.subscribe(
      (fullState) => fullState.persistent.settings.tts_v2,
      (ttsSettings) => this._applySettingsToTrackPlayer(ttsSettings),
    );
  }
}

export default new WebTtsController();
