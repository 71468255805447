import pick from 'lodash/pick';

import type { DocumentTag, GlobalTag } from '../types/tags';
import { cleanAndValidateTagName } from './cleanAndValidateTagName';
import nowTimestamp from './dates/nowTimestamp';

// NOTE: the counts will just be set to 0
export function convertDocumentTagToGlobalTag(documentTag: DocumentTag): GlobalTag {
  const globalTag = makeGlobalTagData(documentTag.name);
  if (documentTag.created) {
    globalTag.lastAssignedAt = documentTag.created;
  }
  return globalTag;
}

export function convertGlobalTagToDocumentTag(globalTag: GlobalTag, type: DocumentTag['type']): DocumentTag {
  return {
    ...pick(globalTag, ['name']),
    created: globalTag.lastAssignedAt,
    type,
  };
}

export function makeGlobalTagData(name: GlobalTag['name']): GlobalTag {
  const { cleanTagName, validationError } = cleanAndValidateTagName(name);
  if (validationError) {
    throw validationError;
  }
  return {
    firstClassDocumentsCount: 0,
    highlightsCount: 0,
    id: cleanTagName.toLowerCase(),
    lastAssignedAt: nowTimestamp(),
    name: cleanTagName,
    totalCount: 0,
  };
}
