import makeLogger from '../utils/makeLogger';
import { allKeys, dateKeys } from './rxDBQueryConverterHelpers';
import { tokenizer } from './tokenizer';
import {
  AST,
  Expression,
  ExpressionLogical,
  ExpressionNode,
  NodeType,
  Token,
  TokenKey,
  TokenType,
  TokenTypeOperator,
  TokenValue,
} from './types';

const logger = makeLogger(__filename);

const shouldLog = false;
const debugLog = (...args: Parameters<typeof console.debug>): void => {
  if (shouldLog) {
    logger.debug(...args);
  }
};

const isValidTimeValue = (value: string): boolean => {
  const [amount, unit, ago, extras] = value.split(' ');

  const validTimeAmounts = ['today', 'yesterday', 'last'];
  const validTimeUnits = ['years', 'year', 'months', 'month', 'weeks', 'week', 'days', 'day', 'hours', 'hour'];

  if (amount.includes('-')) {
    return true;
  }

  if (validTimeAmounts.includes(amount)) {
    if (amount !== 'last') { // if amount is today or yesterday and anything else is written
      return !unit;
    } else { // this checks that after the unit of time, nothing else is written
      return validTimeUnits.includes(unit) && !ago;
    }
  }

  if (validTimeUnits.includes(unit)) {
    // if there is nothing after the unit, or just 'ago'
    return ago === 'ago' && !extras || !ago;
  }
  return false;
};

const validateNode = ({ key, typeOperator, value }: { key: TokenKey; typeOperator: TokenTypeOperator; value: TokenValue; }): void => {
  if (!key) {
    throw new Error('Missing key');
  }

  if (key.type !== TokenType.Key) {
    throw new Error(`Unexpected ${key.value} value`);
  }

  if (!allKeys.includes(key.value)) {
    throw new Error(`Key ${key.value} not supported`);
  }

  if (!typeOperator) {
    throw new Error(`Missing operator after ${key.value}`);
  }

  if (typeOperator.type !== TokenType.TypeOperator) {
    throw new Error(`Unexpected ${typeOperator.value} after ${key.value}`);
  }

  if (!value) {
    throw new Error(`Missing value after ${key.value}${typeOperator.value}`);
  }

  if (value.type !== TokenType.Value) {
    throw new Error(`Unexpected ${value.value} after ${key.value}${typeOperator.value}`);
  }

  if (dateKeys.includes(key.value) && !isValidTimeValue(value.value)) {
    throw new Error(`Missing value after "${key.value}${typeOperator.value}". Valid values: today, yesterday, or a number of hours, days, weeks, months, years, or a YYYY-MM-DD date.`);
  }
};

const createNode = ({ key, typeOperator, value }: { key: TokenKey; typeOperator: TokenTypeOperator; value: TokenValue; }): ExpressionNode => {
  debugLog('createNode');

  validateNode({ key, typeOperator, value });

  const node: ExpressionNode = {
    type: NodeType.Node,
    key: (key as TokenKey).value,
    operator: (typeOperator as TokenTypeOperator).value,
    value: (value as TokenValue).value,
  };

  debugLog('node', node);
  return node;
};

// After an expression (node or paren) we only can have
// - End of input (no more tokens) => done
// - A closing paren               => done with current expression
// - A logic operator              => parse right expression
const afterExpression = (
  node: Expression,
  tokens: Token[],
): [Expression, Token[]] => {
  debugLog('afterExpression');

  if (tokens.length === 0) {
    debugLog('no more tokens');
    return [node, []];
  }

  const [token, ...rest] = tokens;

  debugLog('token', token);

  switch (token.type) {
    case 'closeParen':
      debugLog('closeParen');
      return [node, tokens];

    // Given a left expression and a logic operator,
    // parse the expression on the right,
    // construct the logical expression and return the rest of the tokens
    case 'logicOperator': {
      debugLog('logicOperator');
      const left = node;
      const operator = token;

      if (rest.length === 0) {
        throw new Error(`Missing expression after ${token.value}`);
      }

      const [right, rest_] = parseExpression(rest);

      debugLog('right', right);

      const ast: ExpressionLogical = {
        type: NodeType.Logical,
        operator: operator.value,
        left,
        right,
      };

      debugLog('logicOperator ast', ast);

      return [ast, rest_];
    }

    default:
      throw new Error(`Unexpected token ${token.value}`);
  }
};

// After we parse the expression inside a parens
// we have to check for the closing parens
// If that's there, we continue with the next expression
const checkClosingParen = (
  ast: Expression,
  tokens: Token[],
): [Expression, Token[]] => {
  if (tokens.length === 0) {
    throw new Error('Missing closing paren');
  }

  const [token, ...rest] = tokens;

  switch (token.type) {
    case 'closeParen': {
      debugLog('closeParen');
      const [closeParenAst, closeParenRest] = afterExpression(ast, rest);
      return [closeParenAst, closeParenRest];
    }

    // After parsing the expression inside the parens
    // there should be a closing paren
    default:
      throw new Error(`Expected ), got ${token.value}`);
  }
};

// Parse a single valid expression
// Valid expressions start with either a field or a paren
// And can be combined with logical operators to form more complex expressions
const parseExpression = (tokens: Token[]): [Expression, Token[]] => {
  const [token, ...rest] = tokens;

  debugLog('parseExpression');

  switch (token.type) {
    case 'key': {
      debugLog('key');
      const [key, typeOperator, value, ...rest_] = tokens;

      const node = createNode({
        key: key as TokenKey,
        typeOperator: typeOperator as TokenTypeOperator,
        value: value as TokenValue,
      });

      const [keyAst, keyRest] = afterExpression(node, rest_);
      return [keyAst, keyRest];
    }
    case 'openParen': {
      debugLog('openParen');
      const [ast, rest_] = parseExpression(rest);
      const [openParenAst, openParenRest] = checkClosingParen(ast, rest_);
      return [openParenAst, openParenRest];
    }

    // And expression can only start with a field or a paren
    default:
      throw new Error(`Unexpected ${token.value}`);
  }
};


export const parseFromTokens = (tokens: Token[]): {ast: AST | undefined; errorMessage: string;} => {
  try {
    const [ast] = parseExpression(tokens);
    return { ast, errorMessage: '' };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { ast: undefined, errorMessage: error.message };
  }
};

export const parseFromQuery = (query: string): {ast: AST | undefined; errorMessage: string;} => {
  try {
    if (!query) {
      throw new Error('query is empty');
    }
    const { tokens, errorMessage } = tokenizer(query);

    if (errorMessage) {
      return { ast: undefined, errorMessage };
    }

    const [ast] = parseExpression(tokens);
    return { ast, errorMessage };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { ast: undefined, errorMessage: error.message };
  }
};
