import debounce from 'lodash/debounce';

import type { AnyDocument } from '../../types';
import type { RxDBInstance } from '../../types/database';
import makeLogger from '../../utils/makeLogger';
import { shouldIncludeDocumentInSearch } from '../../utils/shouldIncludeDocumentInSearch';
// eslint-disable-next-line import/no-cycle
import { upsertSearchDocumentMetadata } from '../methods';

const logger = makeLogger(__filename);

const documentsToUpsertIntoSearch: AnyDocument[] = [];

async function commitUpsertIntoSearchMetadata() {
  const documentBatch = documentsToUpsertIntoSearch.splice(0);
  logger.debug('commitUpsertIntoSearchMetadata()', { documentBatchCount: documentBatch.length });
  if (documentBatch.length === 0) {
    return;
  }
  logger.time('upsertSearchDocumentMetadata()');
  await upsertSearchDocumentMetadata(documentBatch);
  logger.timeEnd('upsertSearchDocumentMetadata()');
}

const debouncedCommitUpsertIntoSearchMetadata = debounce(commitUpsertIntoSearchMetadata, 2_000);

function queueDocumentForUpsertIntoMetadataSearch(doc: AnyDocument) {
  documentsToUpsertIntoSearch.push(doc);
  if (documentsToUpsertIntoSearch.length >= 100) {
    commitUpsertIntoSearchMetadata();
  } else {
    debouncedCommitUpsertIntoSearchMetadata();
  }
}

export function populateMetadataSearchMiddleware(database: RxDBInstance) {
  database.collections.documents.insert$.subscribe((event) => {
    const doc = event.documentData;
    if (!shouldIncludeDocumentInSearch(doc)) {
      return;
    }
    queueDocumentForUpsertIntoMetadataSearch(doc);
  });
  database.collections.documents.update$.subscribe((event) => {
    const { documentData: doc, previousDocumentData: prevDoc } = event;
    if (!shouldIncludeDocumentInSearch(doc)) {
      return;
    }
    if (shouldIncludeDocumentInSearch(prevDoc)) {
      // doc should already be in metadata search, so skip upsert
      return;
    }
    queueDocumentForUpsertIntoMetadataSearch(doc);
  });
}
