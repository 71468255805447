import 'virtual:fonts.css';
import './wdyr';
import '../../shared/foreground/utils/scrollingPolyfill';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { Suspense } from '_react';
import ReactDOM from 'react-dom';

import clearLocalRxdbData from '../../shared/database/clearLocalRxdbData.platform';
import onLoggedOut from '../../shared/foreground/onLoggedOut.platform';
import background from '../../shared/foreground/portalGates/toBackground/singleProcess';
import setUpStateTree from '../../shared/foreground/setUpStateTree';
import { init as initUserEvents, onAppFocusChange } from '../../shared/foreground/userEvents';
import type { LenientWindow } from '../../shared/types/LenientWindow';
import { isDesktopApp } from '../../shared/utils/environment';
import exceptionHandler from '../../shared/utils/exceptionHandler.platform';
import { handleDesktopAuthCallback } from './utils/auth.desktop';
// import App from './App';
import { reactLazy } from './utils/dynamicImport';

declare let window: LenientWindow;

const App = reactLazy(() => import('./App'));

window.clearRxDbStorageAndReload = async () => {
  await clearLocalRxdbData();
  window.location.reload();
};

try {
  exceptionHandler.init();
} catch (e) {
  // Ignore
}

if (isDesktopApp) {
  // We need wait until we store the Desktop session ID or else we get stuck in an infinite loop and crash.
  handleDesktopAuthCallback()
    .then(setupApp)
    // eslint-disable-next-line no-console
    .catch(console.error);
} else {
  setupApp();
}

function setupApp() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  background.init({ onLoggedOut });
  initUserEvents();
  const onFocusChange = async (event: FocusEvent) => onAppFocusChange(event.type === 'focus');
  window.addEventListener('blur', onFocusChange);
  window.addEventListener('focus', onFocusChange);

  ReactDOM.render(
    // <React.StrictMode>
      <Suspense fallback={null}><App /></Suspense>
    // </React.StrictMode> // strict mode makes components render twice often, which can drive you insane
    , document.getElementById('readwise-reader-root'),
  );

  setUpStateTree();
}
