import type { DocumentWithLanguage, FirstClassDocument } from '../../types';
import type {
  PlaybackRate,
  TextToSpeechGender,
  TextToSpeechSettings,
  TextToSpeechTrack,
  TTSLanguage,
} from '../../types/tts';
import {
  PlaybackRates,
  TextToSpeechGenderByVoice,
  TextToSpeechVoice,
  TextToSpeechVoicesByLanguage,
  TextToSpeechVoiceToApiVersion,
  TrackPlayerState,
} from '../../types/tts';
import delay from '../../utils/delay';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import getDocumentLanguage from '../../utils/getDocumentLanguage';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
// eslint-disable-next-line import/no-cycle
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import database from '../database';
import { CancelStateUpdate, globalState, updateState } from '../models';
import { overrideLanguage } from '../stateUpdaters/persistentStateUpdaters/documents/overrides';
import { createToast } from '../toasts.platform';
import type { StateUpdateOptions, StateUpdateOptionsWithoutEventName } from '../types';
import {
  textToSpeechDefaultGender,
  textToSpeechDefaultLanguage,
  textToSpeechDefaultPlaybackRate,
  textToSpeechDefaultVolume,
} from '../utils/tts';

/*
  Public functions are idempotent e.g. calling `.stop()` when the track is already stopped is a no-op.
  "track player" = the underlying API/element/thing that actually plays the audio.
*/
abstract class AbstractTtsController<TTrack extends TextToSpeechTrack = TextToSpeechTrack> {
  trackPlayerCreationPromise = Promise.resolve();

  async decreasePlaybackRatePreference(options: StateUpdateOptionsWithoutEventName): Promise<void> {
    const oldRate = this._getSettings()?.playbackRate;
    const possibleNewRate = typeof oldRate === 'undefined'
      ? textToSpeechDefaultPlaybackRate
      : Array.from(PlaybackRates).reverse().find((rate) => rate < oldRate);
    if (typeof possibleNewRate !== 'number') {
      return;
    }
    return this.setPlaybackRatePreference(possibleNewRate, {
      ...options,
      eventName: 'settings-tts-playback-rate-decreased',
    });
  }

  async fetchTimestampForElement(docId: string, elementIndex: number): Promise<number | undefined> {
    const doc = await database.collections.documents.findOne<FirstClassDocument>(docId);
    if (!doc) {
      throw new Error(`_buildTrack: can't find document with id ${docId}`);
    }

    await updateState((state) => {
      if (state.ttsFetchingTimestamp) {
        throw new CancelStateUpdate();
      }
      state.ttsFetchingTimestamp = true;
    }, { eventName: 'tts-timestamp-fetch-started', userInteraction: null, shouldCreateUserEvent: false });

    const voice = this.getVoiceForDocument(doc);
    const versionEndpoint = TextToSpeechVoiceToApiVersion[voice];
    if (versionEndpoint !== 'v3') {
      return;
    }
    const url = `${getServerBaseUrl()}/reader/api/documents/${docId}/tts_stream_${versionEndpoint}_element_to_timestamp?voice=${voice}&elementIndex=${elementIndex}`;
    const resp = await requestWithAuth(
      url,
      {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
      },
    );
    // After 10 seconds, remove the loading state in case it didnt get removed otherwise
    setTimeout(() => {
      updateState((state) => {
        if (!state.ttsFetchingTimestamp) {
          throw new CancelStateUpdate();
        }
        state.ttsFetchingTimestamp = false;
      }, { eventName: 'tts-timestamp-fetch-ended', userInteraction: null, shouldCreateUserEvent: false });
    }, 10000);

    const text = await resp.json();
    return text.start_timestamp;
  }

  getCurrentTTSLanguageForDoc(doc: FirstClassDocument | null): TTSLanguage {
    if (!doc) {
      return textToSpeechDefaultLanguage;
    }

    const language = this._convertLanguageToTTSLanguage(getDocumentLanguage(doc) || textToSpeechDefaultLanguage);

    if (!language || !TextToSpeechVoicesByLanguage[language]) {
      return textToSpeechDefaultLanguage;
    }
    return language;
  }

  getVoiceForDocument(doc: FirstClassDocument | null): TextToSpeechVoice {
    const ttsSettings = this._getSettings();
    if (!ttsSettings || !ttsSettings.defaultVoiceSettings || !doc) {
      return this._getDefaultVoiceForLanguageAndGender(textToSpeechDefaultLanguage, textToSpeechDefaultGender);
    }
    const currentTTSLanguageForDoc = this.getCurrentTTSLanguageForDoc(doc);
    // If a user has set a voice for this language, return that voice
    const userChosenVoiceForLanguage = ttsSettings.chosenVoiceForLanguage[currentTTSLanguageForDoc];
    if (userChosenVoiceForLanguage) {
      return userChosenVoiceForLanguage;
    }
    const defaultVoiceSettings = ttsSettings.defaultVoiceSettings;
    const defaultGender = defaultVoiceSettings.defaultGender;
    const defaultLanguage = defaultVoiceSettings.defaultLanguage;

    // Return either the default voice for language, or user default language and gender,
    // or if all else fails, the true default (female english)
    return this._getDefaultVoiceForLanguageAndGender(currentTTSLanguageForDoc, defaultGender) ??
      // if there is no chosen voice, default to whichever voice is default for this language based on defaultVoiceSettings
      this._getDefaultVoiceForLanguageAndGender(defaultLanguage, defaultGender) ??
      // if not existent, select the voice that matches the default preferences for all TTS
      this._getDefaultVoiceForLanguageAndGender(textToSpeechDefaultLanguage, textToSpeechDefaultGender);
  }

  async increasePlaybackRatePreference(options: StateUpdateOptionsWithoutEventName): Promise<void> {
    const oldRate = this._getSettings()?.playbackRate;
    const possibleNewRate = typeof oldRate === 'undefined'
      ? textToSpeechDefaultPlaybackRate
      : PlaybackRates.find((rate) => rate > oldRate);
    if (typeof possibleNewRate !== 'number') {
      return;
    }
    return this.setPlaybackRatePreference(possibleNewRate, {
      ...options,
      eventName: 'settings-tts-playback-rate-increased',
    });
  }

  async jumpBackward(): Promise<void> {
    await this._jump(-15);
  }

  async jumpForward(): Promise<void> {
    await this._jump(15);
  }

  async modifyVolumePreference(
    action: 'decrease' | 'increase',
    options: StateUpdateOptionsWithoutEventName,
  ): Promise<void> {
    const oldVolume = this._getSettings()?.volume;
    let newVolume: number;
    if (typeof oldVolume === 'number') {
      let delta = 0.1;
      if (action === 'decrease') {
        delta *= -1;
      }
      newVolume = oldVolume + delta;
      if (newVolume < 0.05) {
        // Round down
        newVolume = 0;
      }
    } else {
      newVolume = textToSpeechDefaultVolume;
    }

    return this.setVolumePreference(newVolume, {
      ...options,
      eventName: `settings-tts-volume-${action}d`,
    });
  }

  async pause(): Promise<void> {
    await this._setIsPlaying(false);
  }

  async play(): Promise<void> {
    await this._setIsPlaying(true);
  }

  async playDocument(docId: string, startPosition?: number): Promise<void> {
    await this._ensureDefaultPreferences();

    const track = await this._buildTrack(docId, startPosition);
    if (track === undefined) {
      return;
    }
    await this._playTrack(track, startPosition, docId);
  }

  async playOrPauseDocument(docId: string) {
    const playingDocId = globalState.getState().tts?.playingDocId;
    if (docId !== playingDocId) {
      await this.playDocument(docId);
      return;
    }
    switch (await this._getTrackPlayerState()) {
      case TrackPlayerState.Loading:
      case TrackPlayerState.Playing:
        await this.pause();
        break;
      case TrackPlayerState.Off:
        await this.playDocument(docId);
        break;
      case TrackPlayerState.Paused:
        await this.play();
        break;
    }
  }

  async playOrPauseCurrentlyPlayingDocumentOrPlayNewDocument(
    newDocId?: FirstClassDocument['id'] | null,
  ): Promise<void> {
    if (globalState.getState().tts?.playingDocId) {
      await this.resumeOrPauseCurrentlyPlayingDocument();
    } else if (newDocId) {
      await this.playDocument(newDocId);
    }
  }

  async playOrStopDocument(docId: string, shouldPlayIfPaused?: boolean) {
    const playingDocId = globalState.getState().tts?.playingDocId;
    if (docId !== playingDocId) {
      await this.playDocument(docId);
      return;
    }
    switch (await this._getTrackPlayerState()) {
      case TrackPlayerState.Loading:
      case TrackPlayerState.Playing:
        await this.stop();
        break;
      case TrackPlayerState.Off:
        await this.playDocument(docId);
        break;
      case TrackPlayerState.Paused:
        if (shouldPlayIfPaused) {
          await this.play();
        } else {
          await this.stop();
        }
        break;
    }
  }

  async resumeOrPauseCurrentlyPlayingDocument(): Promise<void> {
    const playingDocId = globalState.getState().tts?.playingDocId;
    if (!playingDocId) {
      throw new Error('There is no playing document');
    }
    return this.playOrPauseDocument(playingDocId);
  }

  async seek(position: number) {
    await this._seekTrackPlayer(Math.max(position, 0));
  }

  async setIsAutoScrollingEnabled(isEnabled: boolean) {
    await updateState(({ tts }) => {
      if (!tts || tts.autoScrolling === isEnabled) {
        throw new CancelStateUpdate();
      }
      tts.autoScrolling = isEnabled;
    }, {
      eventName: 'tts-auto-scrolling-set',
      userInteraction: null,
    });
  }

  async setPlaybackRatePreference(
    rate: PlaybackRate,
    options: StateUpdateOptionsWithoutEventName & Partial<Pick<StateUpdateOptions, 'eventName'>>,
  ): Promise<void> {
    const prevRate = this._getSettings()?.playbackRate;
    if (rate === prevRate) {
      return;
    }
    await updateState(({ persistent: { settings } }) => {
      if (!settings.tts_v2) {
        throw new Error('TtsController.setPlaybackRatePreference: tts preferences do not exist');
      }
      settings.tts_v2.playbackRate = rate;
    }, {
      eventName: 'settings-tts-playback-rate-set',
      isUndoable: false,
      ...options,
    });
  }

  async setVolumePreference(
    volume: number,
    options: StateUpdateOptionsWithoutEventName & Partial<Pick<StateUpdateOptions, 'eventName'>>,
  ): Promise<void> {
    const previousVolume = this._getSettings()?.volume;
    const fixedVolume = Math.max(0, Math.min(volume, 1));
    if (fixedVolume !== previousVolume) {
      await updateState(({ persistent: { settings } }) => {
        if (!settings.tts_v2) {
          throw new Error('TtsController.setPlaybackRatePreference: tts preferences do not exist');
        }
        settings.tts_v2.volume = fixedVolume;
      }, {
        eventName: 'settings-tts-volume-set',
        isUndoable: false,
        ...options,
      });
    }

    // If it becomes muted, pause (to save money)
    if (!fixedVolume) {
      this.pause();
    }
  }

  async setVoicePreferenceAndLanguage({
    documentId,
    language,
    userInteraction,
    voice,
  }: {
    documentId: FirstClassDocument['id'];
    language: TTSLanguage;
    userInteraction: string | null;
    voice: TextToSpeechVoice;
  }) {
    await this._ensureDefaultPreferences();
    await updateState((state) => {
      if (!state.persistent.settings.tts_v2) {
        throw new Error('no tts in persistentState???'); // should never happen
      }
      if (state.persistent.settings.tts_v2.chosenVoiceForLanguage[language] === voice) {
        throw new CancelStateUpdate();
      }
      state.persistent.settings.tts_v2.chosenVoiceForLanguage[language] = voice;
    }, {
      eventName: 'tts-voice-updated-for-language',
      userInteraction: null,
    });

    await overrideLanguage(documentId, { language }, { userInteraction });
    await this._reloadTrack();
  }

  async stop(): Promise<void> {
    await this._resetTrackPlayer();
    await updateState((state) => {
      if (!state.tts) {
        throw new CancelStateUpdate();
      }
      state.tts = null;
    }, {
      eventName: 'tts-stopped',
      userInteraction: null,
    });
  }

  async switchVoiceToV2(docParam: FirstClassDocument | null, userInteraction: string | null) {
    const docId = globalState.getState().tts?.playingDocId;
    const doc = docParam ?? await database.collections.documents.findOne<FirstClassDocument>(docId);
    if (!doc) {
      return;
    }
    const voice = this.getVoiceForDocument(doc);
    if (TextToSpeechVoiceToApiVersion[voice] === 'v2') {
      return;
    }
    const gender = TextToSpeechGenderByVoice[voice];
    const newVoice = gender === 'male' ? TextToSpeechVoice.Guy : TextToSpeechVoice.Aria;
    await this.setVoicePreferenceAndLanguage({
      documentId: doc.id,
      language: textToSpeechDefaultLanguage,
      voice: newVoice,
      userInteraction,
    });
    createToast({
      content: 'Voice updated',
      category: 'success',
    });
  }

  async switchVoiceToV3(doc: FirstClassDocument, userInteraction: string | null) {
    if (!doc) {
      return;
    }
    const voice = this.getVoiceForDocument(doc);
    if (TextToSpeechVoiceToApiVersion[voice] === 'v3') {
      return;
    }
    const gender = TextToSpeechGenderByVoice[voice];
    const newVoice = this._getDefaultVoiceForLanguageAndGender(textToSpeechDefaultLanguage, gender);
    await this.setVoicePreferenceAndLanguage({
      documentId: doc.id,
      language: textToSpeechDefaultLanguage,
      voice: newVoice,
      userInteraction: 'todo', // TODO
    });
    createToast({
      content: 'Voice updated',
      category: 'success',
    });
  }

  async toggleIsPlaying(): Promise<boolean> {
    return this._setIsPlaying(!await this._isTrackPlayerPlaying());
  }

  /*
    TrackPlayer (on react-native at least) does not always successfully seekTo a position we request, (perhaps it tries
    its best and fails).
    This helps us ensure that after a certain delay we are at the right position
  */
  async waitUntilTrackPlayerPositionMatchesTarget(targetPosition: number, numberOfRetries = 0) {
    if (numberOfRetries > 500) {
      return;
    }
    await this._seekTrackPlayer(targetPosition);
    const newPosition = await this._getTrackPlayerPosition();
    if (Math.abs(newPosition - targetPosition) < 2) {
      return;
    }
    await delay(20);
    await this.waitUntilTrackPlayerPositionMatchesTarget(targetPosition, numberOfRetries + 1);
  }

  abstract _addTrackToTrackPlayer(track: TTrack): Promise<void>;

  async _buildTrack(docIdArgument: string | undefined, startPosition?: number): Promise<TTrack | undefined> {
    const docId = docIdArgument ?? globalState.getState().tts?.playingDocId;
    if (!docId) {
      throw new Error('_buildTrack: need a docId');
    }
    const doc = await database.collections.documents.findOne<FirstClassDocument>(docId);
    if (!doc) {
      throw new Error(`_buildTrack: can't find document with id ${docId}`);
    }

    const url = this._buildTrackUrl(doc, this.getVoiceForDocument(doc), startPosition);
    if (!url) {
      return;
    }

    return {
      artist: doc.author || '',
      artwork: doc.image_url || '',
      title: doc.title,
      url,
    } as TTrack;
  }

  _buildTrackUrl(doc: FirstClassDocument, voice: TextToSpeechVoice, startPosition?: number): string | undefined {
    const versionEndpoint = TextToSpeechVoiceToApiVersion[voice];
    return `${getServerBaseUrl()}/reader/api/documents/${doc.id}/tts_stream_${versionEndpoint}.mp3?voice=${voice}`;
  }

  _convertLanguageToTTSLanguage(languageCode: DocumentWithLanguage['language']): TTSLanguage | undefined {
    if (!languageCode) {
      return undefined;
    }
    const convertedLanguageCode = languageCode.split('-')[0];
    if (TextToSpeechVoicesByLanguage[convertedLanguageCode]) {
      return convertedLanguageCode as TTSLanguage;
    }
    return undefined;
  }

  async _enableAutoScrolling() {
    return this.setIsAutoScrollingEnabled(true);
  }

  async _ensureDefaultPreferences(): Promise<void> {
    if (this._getSettings()) {
      return;
    }
    await updateState(({ persistent: { settings } }) => {
      settings.tts_v2 = {
        chosenVoiceForLanguage: {},
        defaultVoiceSettings: {
          defaultGender: textToSpeechDefaultGender,
          defaultLanguage: textToSpeechDefaultLanguage,
        },
        playbackRate: textToSpeechDefaultPlaybackRate,
      };
    }, { userInteraction: null, eventName: 'tts-default-settings-set', isUndoable: false });
  }

  _getDefaultVoiceForLanguageAndGender(language: TTSLanguage, gender: TextToSpeechGender): TextToSpeechVoice {
    const allVoicesForLanguage = TextToSpeechVoicesByLanguage[language];
    return allVoicesForLanguage.filter((voice) => TextToSpeechGenderByVoice[voice] === gender)[0];
  }

  _getSettings(): TextToSpeechSettings | undefined {
    return globalState.getState().persistent.settings.tts_v2;
  }

  abstract _getTrackPlayerPosition(): Promise<number>;
  abstract _getTrackPlayerState(): Promise<TrackPlayerState>;

  async _isTrackPlayerPlaying(): Promise<boolean> {
    return await this._getTrackPlayerState() === TrackPlayerState.Playing;
  }

  async _jump(seconds: number) {
    const position = await Promise.resolve(this._getTrackPlayerPosition());
    await this.seek(position + seconds);
    await this._enableAutoScrolling();
  }

  async _onLanguageUpdated(documentId: FirstClassDocument['id'], language: TTSLanguage) {
    // Do nothing
  }

  abstract _pauseTrackPlayer(): Promise<void>;

  async _playTrack(track: TTrack, startPosition: number | undefined, docId?: string) {
    await this._resetTrackPlayer();
    await this._addTrackToTrackPlayer(track);

    await updateState((state) => {
      const playingDocId = docId ?? state.tts?.playingDocId;
      if (!playingDocId) {
        exceptionHandler.captureException(new Error('TtsControl: playingDocId is undefined'), {
          extra: {
            tts: state.tts,
            ttsSettings: state.persistent.settings.tts_v2,
            track,
            startPosition,
          },
        });
        return;
      }
      state.ttsFetchingTimestamp = false;
      state.tts = {
        autoScrolling: true,
        startingPos: startPosition ?? 0,
        playingDocId,
        currentlyPlayingListQuery: state.focusedDocumentListQuery,
      };
    }, {
      eventName: 'tts-document-playback-started',
      userInteraction: null,
    });

    if (startPosition) {
      await this.waitUntilTrackPlayerPositionMatchesTarget(startPosition);
    }
    await this.play();
  }

  async _reloadTrack(newPosition?: number) {
    const position = newPosition ?? await this._getTrackPlayerPosition();
    const track = await this._buildTrack(undefined, position);
    if (!track) {
      return;
    }
    await this._playTrack(track, position);
  }

  abstract _resetTrackPlayer(): Promise<void>;

  abstract _seekTrackPlayer(position: number): Promise<void>;

  async _setIsPlaying(isPlaying: boolean): Promise<boolean> {
    const state = await this._getTrackPlayerState();
    if (isPlaying) {
      // If it's muted, turn it up
      if (this._getSettings()?.volume === 0) {
        await this.setVolumePreference(0.3, { userInteraction: null });
      }

      if (state !== TrackPlayerState.Playing) {
        await this._startPlayingTrackPlayer();
        await this._enableAutoScrolling();
      }
      return true;
    } else {
      if (state !== TrackPlayerState.Paused) {
        await this._pauseTrackPlayer();
      }
      return false;
    }
  }

  abstract _startPlayingTrackPlayer(): Promise<void>;
}

export default AbstractTtsController;
