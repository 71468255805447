import keyBy from 'lodash/keyBy';

import batchifyDatabaseUpdate from './database/batchify';
import type Database from './database/Database';
import type { GlobalTag, GlobalTagChangeInfo, GlobalTagChangeInfosById, GlobalTagsObject } from './types/tags';
import { cleanAndValidateTagName } from './utils/cleanAndValidateTagName';
import nowTimestamp from './utils/dates/nowTimestamp';
import { makeGlobalTagData } from './utils/globalTags';
import makeLogger from './utils/makeLogger';

const logger = makeLogger(__filename);

function processTagChangeInfo({
  firstClassDocumentCountDelta,
  highlightCountDelta,
  lastAssignedAt,
  name,
  oldGlobalTagsObject,
  totalCountDelta,
}: GlobalTagChangeInfo & {
  oldGlobalTagsObject: GlobalTagsObject;
}): {
  id: GlobalTag['id'];
  operation: 'delete';
} | {
  data: GlobalTag;
  operation: 'upsert';
} | {
  operation: 'none';
} {
  if (!name) {
    return { operation: 'none' };
  }

  const { cleanTagName, validationError } = cleanAndValidateTagName(name);
  if (validationError) {
    logger.warn('Invalid tag name', { validationError });
    return { operation: 'none' };
  }
  const id = cleanTagName.toLowerCase();
  const oldTag = oldGlobalTagsObject[id];

  if (!oldTag && totalCountDelta < 0) {
    return { operation: 'none' };
  }

  let tag: GlobalTag;
  if (oldTag) {
    tag = oldTag;
  } else {
    tag = makeGlobalTagData(cleanTagName);
    if (lastAssignedAt) {
      tag.lastAssignedAt = lastAssignedAt;
    }
  }

  tag.totalCount += totalCountDelta;
  if (tag.totalCount <= 0) {
    return {
      id,
      operation: 'delete',
    };
  }

  tag.firstClassDocumentsCount += firstClassDocumentCountDelta;
  tag.highlightsCount += highlightCountDelta;
  tag.name = cleanTagName;

  if (totalCountDelta > 0) {
    tag.lastAssignedAt = nowTimestamp();
  }

  return {
    data: tag,
    operation: 'upsert',
  };
}

export const saveGlobalTagsChanges = async (
  allGlobalTagChangeInfo: GlobalTagChangeInfosById,
  database: Database,
) => {
  const oldGlobalTagsObject = keyBy(await database.collections.global_tags.findAll(), 'id');

  const idsToDelete: GlobalTag['id'][] = [];
  const itemsToUpsert: GlobalTag[] = [];

  for (const tagChangeDetails of Object.values(allGlobalTagChangeInfo)) {
    const processResult = processTagChangeInfo({
      ...tagChangeDetails,
      oldGlobalTagsObject,
    });

    if (processResult.operation === 'delete') {
      idsToDelete.push(processResult.id);
    } else if (processResult.operation === 'upsert') {
      itemsToUpsert.push(processResult.data);
    }
  }

  await Promise.all([
    batchifyDatabaseUpdate({
      args: [idsToDelete, {}],
      collectionName: 'global_tags',
      func: database.collections.global_tags.deleteByIds,
    }),
    batchifyDatabaseUpdate({
      args: [itemsToUpsert, {}],
      collectionName: 'global_tags',
      func: database.collections.global_tags.bulkUpsert,
    }),
  ]);
};
